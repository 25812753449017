import React from 'react';
import { injectIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import '../css/mobileBox.css';
import '../css/mobileShopping.css';
import '../css/gallery.css';
import '../css/instructions.css';
import '../css/tc.css';
import '../css/main.css';

class FooterStaticMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mcemail:""
    };
  }

  render() {
    const { intl } = this.props;
    let aboutustitle = intl.formatMessage({ id: 'aboutustitle' });

    let shopping2018 = intl.formatMessage({ id: 'shopping2018' });
    let shoppingterms = intl.formatMessage({ id: 'shoppingterms' });
    let privacyPolicy = intl.formatMessage({ id: 'privacyPolicy' });
    let useOfWebsite = intl.formatMessage({ id: 'useOfWebsite' });

    let footernewslettersloganA = intl.formatMessage({ id: 'footernewslettersloganA' });
    let footernewslettersloganB = intl.formatMessage({ id: 'footernewslettersloganB' });
    let footernewslettersloganm = intl.formatMessage({ id: 'footernewslettersloganm' });
    let footernewslettersloganm1 = intl.formatMessage({ id: 'footernewslettersloganm1' });
    let footernewsletterslogan2 = intl.formatMessage({ id: 'footernewsletterslogan2' });
    let footernewsletteremail = intl.formatMessage({ id: 'footernewsletteremail' });
    let footernewsletterbutton = intl.formatMessage({ id: 'footernewsletterbutton' });
    let footernewsletterfineprint = intl.formatMessage({ id: 'footernewsletterfineprint' });
    let footerquestions = intl.formatMessage({ id: 'footerquestions' });
    let footercontactphone = intl.formatMessage({ id: 'footercontactphone' });
    let footercontactphone2 = intl.formatMessage({ id: 'footercontactphone2' });
    let footercontactemail = intl.formatMessage({ id: 'footercontactemail' });
    let footercontactwhatsapp = intl.formatMessage({ id: 'footercontactwhatsapp' });
    let footercontactonline = intl.formatMessage({ id: 'footercontactonline' });
    let downloadapp = intl.formatMessage({ id: 'downloadapp' });
    let emf1 = intl.formatMessage({ id: 'emf1' });
    let emf2 = intl.formatMessage({ id: 'emf2' });
    let emf3 = intl.formatMessage({ id: 'emf3' });
    let emf4 = intl.formatMessage({ id: 'emf4' });

    return (
        <div className="mobile-footer" style={{ marginBottom: 30 }}>
          <div className="footer-questions">
            <div className="footer-questions-block">
              <p className="footer-questions-heading">{footerquestions}</p>
              <p className="footer-questions-content">{footercontactphone}</p>
              <p className="footer-questions-content">{footercontactphone2}</p>
              <p className="footer-questions-content">{footercontactemail}</p>
              <p className="footer-questions-content">{footercontactwhatsapp}</p>
              <p className="footer-questions-content">
                {emf1}<br />
                {emf2}<br />
                {emf3}<br />
                {emf4}<br />
              </p>
              <p className="footer-questions-content" style={{marginTop:'15px'}}>{downloadapp}</p>
                <a
                href="https://apps.apple.com/tw/app/btmf-control/id1514326227"
                target="_blank"
                rel="noopener noreferrer"
                >
                <img style={{height: '40px'}} src={require('../image/brand/appstore.png')} alt="BTMF App" />
                </a>
                <a
                href="https://play.google.com/store/apps/details?id=com.mllennium.eagle"
                target="_blank"
                rel="noopener noreferrer"
                >
                <img style={{height: '40px'}} src={require('../image/brand/googleplay.png')} alt="BTMF App" />
                </a>
            </div>
            <div className="footer-questions-block">
              <p className="footer-questions-heading">{footercontactonline}</p>
              <div className="footer-social">
                <a
                  href="https://www.facebook.com/stkworkshoptw/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require('../image/brand/fb@3x.png')} alt="STK Workshop Facebook" />
                </a>
                <a href="https://www.instagram.com/stkworkshop_taiwan/" target="_blank" rel="noopener noreferrer">
                  <img src={require('../image/brand/ig@3x.png')} alt="STK Workshop Instagram" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UClwt44Lndl-mgx8ZwoWSdhQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require('../image/brand/youtube@3x.png')} alt="STK Workshop YouTube" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-newsletter-sign-up">
            <p className="footer-newsletter-heading">
              {footernewslettersloganm}
              <br />
              {footernewslettersloganm1}
            </p>
            <p className="footer-newsletter-heading2">{footernewsletterslogan2}</p>
            <div className="footer-newsletter-sign-up-box">
              <form action="https://stk-workshop.us19.list-manage.com/subscribe/post" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                <input type="hidden" name="u" value="dbb97ed597bf7f400423d3a6e"/>
                <input type="hidden" name="id" value="809bc77220"/>

                <input type="email" placeholder={footernewsletteremail} value={this.state.mcemail} onChange={(e)=>{this.setState({mcemail:e.target.value});}} name="EMAIL" className="required email" id="mce-EMAIL" />
                <input type="submit" value={footernewsletterbutton} name="subscribe" id="mc-embedded-subscribe" />
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_dbb97ed597bf7f400423d3a6e_809bc77220" tabindex="-1" value="" /></div>
              </form>
            </div>
            <p className="footer-newsletter-fineprint">{footernewsletterfineprint}</p>
          </div>
          <div className="mobile-stk-copyright">
            <p>{shopping2018}</p>
            <p>
              <NavLink to="/btmf/about">{aboutustitle}</NavLink>
              <span>|</span>
              <NavLink to="/btmf/terms-and-conditions">{shoppingterms}</NavLink>
            </p>
            <p>
              <NavLink to="/btmf/privacy-policy">{privacyPolicy}</NavLink>
              <span>|</span>
              <NavLink to="/btmf/use-of-website">{useOfWebsite}</NavLink>
            </p>
          </div>
        </div>
    );
  }
}
FooterStaticMobile = withRouter(injectIntl(FooterStaticMobile));
export default FooterStaticMobile;
