import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

class YoutubeEmbed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            youtubeId: this.props.youtubeId
        };
    }
    render() {
        let { youtubeId } = this.state;
        return (
            <div
            className="youtube-embed"
            style={{
                position: "relative",
                //paddingBottom: "56.25%" /* 16:9 */,
                //paddingTop: 25,
                height: 0
            }}
            >
            <iframe
                style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
                }}
                src={`https://www.youtube.com/embed/${youtubeId}`}
                frameBorder="0"
                allowFullScreen
            />
            </div>
        );
    }

}

export default withRouter(injectIntl(YoutubeEmbed));