import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter, NavLink } from 'react-router-dom';
import HeaderNav from './../components/HeaderNav';
import * as request from '../apiClient/request';
import YoutubeEmbed from './../components/YoutubeEmbed';
import '../css/main.css';
import '../css/productDetails.css';

var webWidthScale = document.documentElement.clientWidth / 1440;
var thiz;

var PLAQUE_LIST = [
  {normal_plaque:'btmf-technical-plaque', custom_plaque:'btmf-personalized-technical-plaque'},
  {normal_plaque:'btmf-plaque-bundle', custom_plaque:'btmf-personalized-plaque-bundle'},
];

var NON_PLAQUE = -1;
var NORMAL_PLAQUE = 0;
var CUSTOM_PLAQUE = 1;

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webWidthScale: document.documentElement.clientWidth / 1440,
      webHeight: document.documentElement.clientHeight,
      webWight: document.documentElement.webWight,
      mcemail:"",
      data: {
          images: []
      },
      displayImage: '',
      displayVideo: false,
      showpop: false,
      text1: '',
      text2: '',
      text3: '',
      custom_plaque_name:'',
      custom_plaque_creditline:'',
      plaque_credit_line_dropdown: false,
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.resize);
  }
  resize() {
    thiz.setState({
      webWidthScale: document.documentElement.clientWidth / 1440,
      webHeight: document.documentElement.clientHeight,
      webWight: document.documentElement.webWight
    });
    webWidthScale = document.documentElement.clientWidth / 1440;
  }
  componentDidMount() {
    this.fetchData(this.props.match.params.name);
  }

  htmlWrapper(text) { return {__html: text}; };

  fetchData(slug_key){
    console.log("ProductDetails slug_key >> " + slug_key);
    this.resetData();

    document.getElementById('preloader-container').style.display = 'block';

    localStorage.setItem('productdetails', true);
  
    var lang = localStorage.lang;
    if (!lang) { lang = 'en'}
    if (lang == 'hk') { lang = 'zh-hk' }
    if (lang == 'tw') { lang = 'zh-tw' }
    if (lang == 'cn') { lang = 'zh-cn' }
    
    let slug = slug_key;
    request
      .get(`api/v1/products/get_product_by_slug?slug=${slug}`, 1, 23)
      .then(res => {
        console.log('res');
        console.log(res);
        res.response.regular_price = Math.floor(res.response.regular_price);
        res.response.price = Math.floor(res.response.price);
        this.setState({
          data: res.response,
          displayImage: res.response.image
        });
        document.getElementById('preloader-container').style.display = 'none';
      })
      .catch(err => {
        let str = JSON.stringify(err);
        console.log('err2');
        console.log(err);
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          this.push('/btmf/');
        }
      });
  }

  resetData(){
    console.log("resetData");
    this.setState({custom_plaque_creditline:'', custom_plaque_name:''});
  }

  getPlaqueType(){
    console.log("getPlaqueType key >> " + this.state.data.slug);
    var key = this.state.data.slug;
    for(var i = 0; i < PLAQUE_LIST.length; i++){
      if(PLAQUE_LIST[i].normal_plaque == key){
        return NORMAL_PLAQUE; //normal plaque
      }

      if(PLAQUE_LIST[i].custom_plaque == key){
        return CUSTOM_PLAQUE; //custom plaque
      }
    }

    return NON_PLAQUE;//non plaque
  }

  switchPlaque(){
    console.log("switchPlaque key >> " + this.state.data.slug);

    // return;
    var url = '';
    if(window.location.host.includes("localhost")){
      url = "http://" + window.location.host + "/btmf/product/";
    }
    else{
      url = "https://" + window.location.host + "/btmf/product/";
    }

    var key = this.state.data.slug;
    for(var i = 0; i < PLAQUE_LIST.length; i++){
      if(PLAQUE_LIST[i].normal_plaque == key){
        console.log("switchPlaque key >> 0");
        url += PLAQUE_LIST[i].custom_plaque;
        console.log("switchPlaque key >> 0 " + url);
        window.location.href = url;
        // this.fetchData(PLAQUE_LIST[i].custom_plaque);
        return;
      }

      if(PLAQUE_LIST[i].custom_plaque == key){
        console.log("switchPlaque key >> 1");
        url += PLAQUE_LIST[i].normal_plaque;
        console.log("switchPlaque key >> 1 " + url);
        window.location.href = url;
        // this.fetchData(PLAQUE_LIST[i].normal_plaque);
        return;
      }
    }
  }

  learnmorePlaque() {
    let url = '';
    if(window.location.host.includes("localhost")){
      url = "http://" + window.location.host + "/btmf/product/btmf-plaque-bundle";
    }
    else{
      url = "https://" + window.location.host + "/btmf/product/btmf-plaque-bundle";
    }
    window.location.href = url;
  }

  changeImage(image) {
    this.setState({
        displayImage: image,
        displayVideo: false
    });
  }

  changeVideo() {
    this.setState({
        displayVideo: true
    });
  }

  formatReceiveDate(start, end) {
    // console.log('start >> ' + start + ' :: end >> ' + end);
    if (start < 0 || end < 0) {
      return null;
    }
    var startDate = new Date();
    var endDate = new Date();
    startDate.setDate(startDate.getDate() + start);
    endDate.setDate(endDate.getDate() + end);

    var dd = startDate.getDate();
    var mm = startDate.getMonth() + 1;
    var yyyy = startDate.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    var dd1 = endDate.getDate();
    var mm1 = endDate.getMonth() + 1;
    var yyyy1 = endDate.getFullYear();
    if (dd1 < 10) {
      dd1 = '0' + dd1;
    }

    if (mm1 < 10) {
      mm1 = '0' + mm1;
    }
    // console.log('date >>' + yyyy + '-' + mm + '-' + dd + ' to ' + yyyy1 + '-' + mm1 + '-' + dd1);
    const { intl } = this.props;
    let receive_date = intl.formatMessage({ id: 'cart.receive_date' });
    let receive_date_1 = intl.formatMessage({ id: 'cart.receive_date_1' });
    if (start === end) {
      return receive_date + yyyy + '-' + mm + '-' + dd;
    } else {
      return receive_date + yyyy + '-' + mm + '-' + dd + receive_date_1 + yyyy1 + '-' + mm1 + '-' + dd1;
    }
    // console.log(today);
  }

  addCart(key) {
    const { intl } = this.props;
    let subscriptionmorethanone1 = intl.formatMessage({ id: 'subscriptionmorethanone1' });
    let subscriptionmorethanone2 = intl.formatMessage({ id: 'subscriptionmorethanone2' });
    let subscriptionmorethanone3 = intl.formatMessage({ id: 'subscriptionmorethanone3' });
    let alert3 = intl.formatMessage({ id: 'alert3' });
    let alert4 = intl.formatMessage({ id: 'alert4' });
    let alert5 = intl.formatMessage({ id: 'alert5' });
    let alert6 = intl.formatMessage({ id: 'alert6' });
    let orderconflictnote = intl.formatMessage({ id: 'orderconflictnote' });
    let orderconflictnote2 = intl.formatMessage({ id: 'orderconflictnote2' });
    let alert7 = intl.formatMessage({ id: 'alert7' });
    let miss_credit_name = intl.formatMessage({ id: 'accessoriessection20' });
    let miss_credit_line = intl.formatMessage({ id: 'accessoriessection21' });
    let data = {
      shopping_cart_key: localStorage.cartKey,
      product_key: key
    };

    var plaque_type = this.getPlaqueType();
    if(plaque_type == CUSTOM_PLAQUE){
      if(this.state.custom_plaque_creditline == ''){
        //show popup
        this.showpop(miss_credit_line);
        return;
      }
      else if(this.state.custom_plaque_name == ''){
        //show popup
        this.showpop(miss_credit_name);
        return;
      }
      else{
        data.custom_plaque_name = this.state.custom_plaque_name;
        data.custom_plaque_creditline = this.state.custom_plaque_creditline;
      }
    }

    // return;

    request
      .post('api/v1/shopping_carts/add_product_to_shopping_cart', data, 1, 22)
      .then(res => {
        //console.log(res);
        localStorage.setItem('cartItem', JSON.stringify(res.data.response.shopping_cart_items));
        // alert(alert7);
        if (this.props.history.location.pathname !== '/btmf/cart1') this.props.history.push('/btmf/cart1');
        var cartItem = JSON.parse(localStorage.cartItem);
        this.props.cartNumber(cartItem.length);
      })
      .catch(err => {
        console.log('err');
        console.log(err);
        let str = JSON.stringify(err);
        console.log('err2');

        console.log(err);
        console.log(err.response);

        if (err.response && err.response.status === 403) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.showpop(alert3, alert4, orderconflictnote, orderconflictnote2);
          } else {
            this.showpop(alert5, alert6, orderconflictnote, orderconflictnote2);
          }
          // alert('111')
        }
        if (err.response && err.response.status === 400) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.showpop(
              subscriptionmorethanone1,
              subscriptionmorethanone2,
              subscriptionmorethanone3,
              orderconflictnote2
            );
          }
          // alert('111')
        }
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          this.push('/btmf/');
          // alert('111')
        }
      });
  }

  showpop = (text1, text2, text3) => {
    this.setState({ showpop: true, text1, text2, text3});
  };

  hidepop = () => {
    this.setState({ showpop: false });
  };

  isMobile() {
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
  }
  tc() {
    if (this.props.history.location.pathname !== '/btmf/terms-and-conditions') this.props.history.push('/btmf/terms-and-conditions');
    localStorage.setItem('tc', true);
  }
  about() {
    if (this.props.history.location.pathname !== '/btmf/about') this.props.history.push('/btmf/about');
    localStorage.setItem('about', true);
  }
  privacypolicy() {
    if (this.props.history.location.pathname !== '/btmf/privacy-policy') this.props.history.push('/btmf/privacy-policy');
    localStorage.setItem('privacypolicy', true);
  }
  useofwebsite() {
    if (this.props.history.location.pathname !== '/btmf/use-of-website') this.props.history.push('/btmf/use-of-website');
    localStorage.setItem('useofwebsite', true);
  }

  onInput(e) {
    let val = e.target.value;
    this.setState({
      custom_plaque_name: val
    });
  }

  chooseCreditLine() {
    console.log("ProductDetails :: chooseCreditLine");
    if (!this.state.plaque_credit_line_dropdown) {
      this.refs.plaqueCreditLineDropdown.style.display = 'block';
      this.state.plaque_credit_line_dropdown = true;
    } else {
      this.refs.plaqueCreditLineDropdown.style.display = 'none';
      this.state.plaque_credit_line_dropdown = false;
    }
  }

  selectCreditLine(index) {
    console.log("ProductDetails :: selectCreditLine index >> " + index);
    this.state.plaque_credit_line_dropdown = false;
    this.refs.plaqueCreditLineDropdown.style.display = 'none';
    const { intl } = this.props;
    let credit_option_0 = intl.formatMessage({ id: 'accessoriessection11' });
    let credit_option_1 = intl.formatMessage({ id: 'accessoriessection12' });
    let credit_option_2 = intl.formatMessage({ id: 'accessoriessection13' });
    let credit_option_3 = intl.formatMessage({ id: 'accessoriessection14' });
    let credit_option_4 = intl.formatMessage({ id: 'accessoriessection15' });

    var str = '';
    switch(index){
      case 0:
        str = credit_option_0;
        break;
        case 1:
        str = credit_option_1;
        break;
        case 2:
        str = credit_option_2;
        break;
        case 3:
        str = credit_option_3;
        break;
        case 4:
        str = credit_option_4;
        break;
    }
    this.setState({custom_plaque_creditline:str});
    this.refs.inputCreditLine.value = str;
    // localStorage.setItem("termduration",val);
  }

  closeCreditLine() {
    console.log("ProductDetails :: closeCreditLine");
    this.state.plaque_credit_line_dropdown = false;
    this.refs.plaqueCreditLineDropdown.style.display = 'none';
  }


  renderPlaque(){
    console.log("ProductList :: renderPlaque");
    const { intl } = this.props;
    let normal_plaque = intl.formatMessage({ id: 'accessoriessection08' });
    let custom_plaque = intl.formatMessage({ id: 'accessoriessection09' });
    let enter_name_plaque = intl.formatMessage({ id: 'accessoriessection16' });
    let enter_name_plaque_hint_0 = intl.formatMessage({ id: 'accessoriessection17' });
    let enter_name_plaque_hint_1 = intl.formatMessage({ id: 'accessoriessection18' });
    let select_credit_line = intl.formatMessage({ id: 'accessoriessection10' });
    let credit_option_0 = intl.formatMessage({ id: 'accessoriessection11' });
    let credit_option_1 = intl.formatMessage({ id: 'accessoriessection12' });
    let credit_option_2 = intl.formatMessage({ id: 'accessoriessection13' });
    let credit_option_3 = intl.formatMessage({ id: 'accessoriessection14' });
    let credit_option_4 = intl.formatMessage({ id: 'accessoriessection15' });
    let please_select = intl.formatMessage({ id: 'accessoriessection19' });

    var plaque_type = this.getPlaqueType();
    if(plaque_type == NORMAL_PLAQUE){
      return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
        <div className="content-plaque-container">
          <div className="btn-switch-plaque" onClick={() => this.switchPlaque()}>
              {normal_plaque}
          </div>
        </div>
      )
      :
      (
        <div className="content-plaque-container">
          <div className="btn-switch-plaque" onClick={() => this.switchPlaque()}>
              {normal_plaque}
          </div>
        </div>
      );
    }
    else if(plaque_type == CUSTOM_PLAQUE){
      return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
        <div className="content-plaque-container">

          <div className="btn-switch-plaque" onClick={() => this.switchPlaque()}>
              {custom_plaque}
          </div>
          
          <h2 className="plaque-heading-1">{select_credit_line}</h2>
          <div className="custom-credit-line-container">
            <input
              id="credit-line"
              type="text"
              readOnly
              placeholder={please_select}
              // onInput={e => this.onInput(e, "country")}
              value={this.state.custom_plaque_creditline}
              ref="inputCreditLine"
              style={{ cursor: 'pointer' }}
              onClick={() => this.chooseCreditLine()}
            />
            <div
              onClick={() => this.chooseCreditLine()}
              style={{ width: 12, height: 8, position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}
            >
              <img
                style={{ width: '100%', height: '100%' }}
                src={require('../image/common/Rectangle.png')}
                alt="Dropdown Icon"
              />
            </div>
            <div
              className="choose-credit-line"
              ref="plaqueCreditLineDropdown"
              style={{ display: 'none', bottom: -49, cursor: 'pointer' }}
            >
              <span
                onClick={() => this.closeCreditLine()}
                style={{ cursor: 'pointer', display: 'block', height: '21px', width: '21px', position: 'absolute', right: '-9px', top: '-9px', border: '1px solid #000', borderRadius: '50%', backgroundColor: '#000', color: '#FFF', zIndex: '100', textAlign: 'center', fontSize: '16px', lineHeight: '16px', fontWeight: 'bold' }}>x</span>
              <div
                onClick={() => this.selectCreditLine(0)}
                style={{ cursor: 'pointer' }}>{credit_option_0}</div>
              <div
                onClick={() => this.selectCreditLine(1)}
                style={{ cursor: 'pointer' }}>{credit_option_1}</div>
              <div
                onClick={() => this.selectCreditLine(2)}
                style={{ cursor: 'pointer' }}>{credit_option_2}</div>
              <div
                onClick={() => this.selectCreditLine(3)}
                style={{ cursor: 'pointer' }}>{credit_option_3}</div>
              <div
                onClick={() => this.selectCreditLine(4)}
                style={{ cursor: 'pointer' }}>{credit_option_4}</div>
            </div>
          </div>

          <h2 className="plaque-heading">{enter_name_plaque}</h2>
          <div className="plaque-name-container">
              <input
                id="plaque-name"
                type="text"
                // placeholder={cartinputdiscount}
                onInput={e => this.onInput(e)}
                value={this.state.custom_plaque_name}
                maxLength="20"
              />
          </div>
          <p className="plaque-note">{enter_name_plaque_hint_0}</p>
          <p className="plaque-note">{enter_name_plaque_hint_1}</p>
          
        </div>
      )
      :
      (
        <div className="content-plaque-container">

        <div className="btn-switch-plaque" onClick={() => this.switchPlaque()}>
            {custom_plaque}
        </div>

        <h2 className="plaque-heading">{select_credit_line}</h2>
        <div className="custom-credit-line-container">
          <input
            id="credit-line"
            type="text"
            readOnly
            placeholder={please_select}
            // onInput={e => this.onInput(e, "country")}
            value={this.state.custom_plaque_creditline}
            ref="inputCreditLine"
            style={{ cursor: 'pointer' }}
            onClick={() => this.chooseCreditLine()}
          />
          <div
            onClick={() => this.chooseCreditLine()}
            style={{ width: 12, height: 8, position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}
          >
            <img
              style={{ width: '100%', height: '100%' }}
              src={require('../image/common/Rectangle.png')}
              alt="Dropdown Icon"
            />
          </div>
          <div
            className="choose-credit-line"
            ref="plaqueCreditLineDropdown"
            style={{ display: 'none', bottom: -49, cursor: 'pointer' }}
          >
            <span
              onClick={() => this.closeCreditLine()}
              style={{ cursor: 'pointer', display: 'block', height: '21px', width: '21px', position: 'absolute', right: '-9px', top: '-9px', border: '1px solid #000', borderRadius: '50%', backgroundColor: '#000', color: '#FFF', zIndex: '100', textAlign: 'center', fontSize: '16px', lineHeight: '16px', fontWeight: 'bold' }}>x</span>
            <div
              onClick={() => this.selectCreditLine(0)}
              style={{ cursor: 'pointer' }}>{credit_option_0}</div>
            <div
              onClick={() => this.selectCreditLine(1)}
              style={{ cursor: 'pointer' }}>{credit_option_1}</div>
            <div
              onClick={() => this.selectCreditLine(2)}
              style={{ cursor: 'pointer' }}>{credit_option_2}</div>
            <div
              onClick={() => this.selectCreditLine(3)}
              style={{ cursor: 'pointer' }}>{credit_option_3}</div>
            <div
              onClick={() => this.selectCreditLine(4)}
              style={{ cursor: 'pointer' }}>{credit_option_4}</div>
          </div>
        </div>

        <h2 className="plaque-heading">{enter_name_plaque}</h2>
        <div className="plaque-name-container">
            <input
              id="plaque-name"
              type="text"
              // placeholder={cartinputdiscount}
              onInput={e => this.onInput(e)}
              value={this.state.custom_plaque_name}
              maxLength="20"
            />
        </div>
        <p className="plaque-note">{enter_name_plaque_hint_0}</p>
        <p className="plaque-note">{enter_name_plaque_hint_1}</p>

        </div>
      );
    }
  }

  // add function like above for this page for all other static pages

  render() {
    thiz = this;
    const { intl } = this.props;
    let aboutustitle = intl.formatMessage({ id: 'aboutustitle' });

    let shoppingregular = intl.formatMessage({ id: 'shoppingregular' });
    let saleprice = intl.formatMessage({ id: 'saleprice' });
    let originalprice = intl.formatMessage({ id: 'originalprice' });
    let addtocart = intl.formatMessage({ id: 'addtocart' });
    let breadcrumbproduct = intl.formatMessage({ id: 'breadcrumbproduct' });

    let plaquetxtbanner01 = intl.formatMessage({ id: 'plaquetxtbanner01' });
    let plaquetxtbanner02 = intl.formatMessage({ id: 'plaquetxtbanner02' });
    let learnmore = intl.formatMessage({ id: 'learnmore' });

    let cart64 = intl.formatMessage({ id: 'cart.cart64' });
    let cart64tc = intl.formatMessage({ id: 'cart.cart64tc' });

    let shopping2018 = intl.formatMessage({ id: 'shopping2018' });
    let shoppingterms = intl.formatMessage({ id: 'shoppingterms' });
    let privacyPolicy = intl.formatMessage({ id: 'privacyPolicy' });
    let useOfWebsite = intl.formatMessage({ id: 'useOfWebsite' });

    let footernewslettersloganA = intl.formatMessage({ id: 'footernewslettersloganA' });
    let footernewslettersloganB = intl.formatMessage({ id: 'footernewslettersloganB' });
    let footernewslettersloganm = intl.formatMessage({ id: 'footernewslettersloganm' });
    let footernewslettersloganm1 = intl.formatMessage({ id: 'footernewslettersloganm1' });
    let footernewsletterslogan2 = intl.formatMessage({ id: 'footernewsletterslogan2' });
    let footernewsletteremail = intl.formatMessage({ id: 'footernewsletteremail' });
    let footernewsletterbutton = intl.formatMessage({ id: 'footernewsletterbutton' });
    let footernewsletterfineprint = intl.formatMessage({ id: 'footernewsletterfineprint' });
    let footerquestions = intl.formatMessage({ id: 'footerquestions' });
    let footercontactphone = intl.formatMessage({ id: 'footercontactphone' });
    let footercontactphone2 = intl.formatMessage({ id: 'footercontactphone2' });
    let footercontactemail = intl.formatMessage({ id: 'footercontactemail' });
    let footercontactwhatsapp = intl.formatMessage({ id: 'footercontactwhatsapp' });
    let footercontactonline = intl.formatMessage({ id: 'footercontactonline' });

    let { data, displayImage, displayVideo, showpop, text1, text2, text3} = this.state;

    // let nav = <HeaderNav/>;
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile-ma-shopping max-home">
        <HeaderNav />
        {/* exact same code as desktop */}
        <div className="product-details bg-white">
            <div
                className="null"
                id="null"
                style={{ minHeight: this.isMobile() ? 60 : 104, height: 139 * webWidthScale, maxHeight: 150 }}
            />
            <div className="content-wrapper">
                {/* Pre-loader */}
                <div id="preloader-container" className="preloader-container">
                    <div className="preloader">
                        <img src={require('../image/common/loading.gif')} alt="Loading" />
                    </div>
                </div>
                {/* Content */}
                {/*data.product_key === 'btmf_classic_plaque' || data.product_key === 'btmf_technical_plaque' || data.product_key === 'btmf_personalized_technical_plaque' ? (
                  <div className="product-detail-banner">{plaquetxtbanner01}<a href="#" onClick={() => this.learnmorePlaque()}>{learnmore}</a></div>
                ) : null*/}
                {data.product_key === 'btmf_acrylic_floor_stand' || data.product_key === 'btmf_acrylic_table_top_stand' || data.product_key === 'btmf_metal_floor_stand' || data.product_key === 'btmf_metal_table_top_stand' || data.product_key === 'btmf_wall_mount' ? (
                  <div className="product-detail-banner">{plaquetxtbanner02}<NavLink to="/btmf/products">{learnmore}</NavLink></div>
                ) : null}
                <div className="content">
                    <div className="content-left">
                        <div className="breadcrumb">
                            <span>{breadcrumbproduct} > </span>
                            {localStorage.lang === 'en' ? (
                                data.name
                            ) : null}
                            {!localStorage.lang || localStorage.lang === 'tw' || localStorage.lang === '' ? (
                                data.zhtw_product_item_name
                            ) : null}
                        </div>
                        <div className="content-media-container">
                            <div className="content-media-display">
                                {data.video_url && displayVideo ? (
                                  <YoutubeEmbed youtubeId={data.video_url} />
                                ) : (
                                  <img src={displayImage} alt="" />
                                )}
                            </div>
                            <div className="content-media-thumbnails">
                                <div onClick={() => this.changeImage(data.image)}><img src={data.image} alt="" /></div>
                                {data.images.map((item, index) => (
                                    <div onClick={() => this.changeImage(item.image)}><img src={item.image} alt="" /></div>
                                ))}
                                {data.video_url ? (
                                  <div className="video-play-thumb" onClick={() => this.changeVideo()}><div className="video-play-icon"></div><img src={require('../image/home/video-thumb.png')} alt="" /></div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="content-right">
                        {/* product series */}
                        <h2>
                            {localStorage.lang === 'en' ? (
                                data.product_series
                            ) : null}
                            {!localStorage.lang || localStorage.lang === 'tw' || localStorage.lang === '' ? (
                                data.zhtw_product_series
                            ) : null}
                        </h2>
                        {/* product name */}
                        <h1>
                            {localStorage.lang === 'en' ? (
                                data.name
                            ) : null}
                            {!localStorage.lang || localStorage.lang === 'tw' || localStorage.lang === '' ? (
                                data.zhtw_product_item_name
                            ) : null}
                        </h1>
                        {/* product variation */}
                        <h3>
                            {localStorage.lang === 'en' ? (
                                data.product_variation
                            ) : null}
                            {!localStorage.lang || localStorage.lang === 'tw' || localStorage.lang === '' ? (
                                data.zhtw_product_variation
                            ) : null}
                        </h3>
                        {/* product price */}
                        {data.is_installment ? (
                          <React.Fragment>
                            {data.regular_price !== data.initial_payment ? (
                              <React.Fragment>
                                <p className="regular-price">{shoppingregular} {data.regular_price}</p>
                                <p className="sale-price">{saleprice} {data.initial_payment}</p>
                              </React.Fragment>
                            ) : (
                              <p className="sale-price" style={{color: '#000'}}>{originalprice} {data.initial_payment}</p>
                            )}
                            <p>
                              ({localStorage.lang !== 'en' ? cart64tc : null}NT$ {Math.floor(data.price)}
                              {localStorage.lang === 'en' ? cart64 : null})
                            </p>
                            <br />
                            <br />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {data.regular_price !== data.price ? (
                              <React.Fragment>
                                <p className="regular-price">{shoppingregular} {data.regular_price}</p>
                                <p className="sale-price">{saleprice} {data.price}</p>
                              </React.Fragment>
                            ) : (
                              <p className="sale-price" style={{color: '#000'}}>{originalprice} {data.price}</p>
                            )}
                          </React.Fragment>
                        )}
                        {/* delivery date */}
                        {this.formatReceiveDate(data.least_days_to_ship, data.most_days_to_ship) != null ? (
                            <p className="estimated-delivery-date">
                                {this.formatReceiveDate(data.least_days_to_ship, data.most_days_to_ship)}
                            </p>
                        ) : null}
                        {data.shipping_notice != '' ? (
                            <p className="delivery-notice">
                                {localStorage.lang === 'en' ? <span>{data.shipping_notice}</span> : null}
                                {!localStorage.lang || localStorage.lang === 'tw' || localStorage.lang === '' ? (
                                <span>{data.zhtw_shipping_notice}</span>
                                ) : null}
                            </p>
                        ) : null}
                        {this.renderPlaque()}
                        <div className="btn-add-cart" onClick={() => this.addCart(data.product_key)}>
                            {addtocart}
                        </div>
                        <br />
                        <br />
                        <hr />
                        <br />
                        <br />
                        {localStorage.lang === 'en' ? (
                            <div dangerouslySetInnerHTML={this.htmlWrapper(data.description)} />
                        ) : null}
                        {!localStorage.lang || localStorage.lang === 'tw' || localStorage.lang === '' ? (
                            <div dangerouslySetInnerHTML={this.htmlWrapper(data.description_zh)} />
                        ) : null}
                    </div>
                </div>

            </div>
        </div>
        <div className={showpop ? 'pop' : 'hidden'}>
          <p>{text1}</p>
          <p>{text2}</p>
          <p className="footnote">{text3}</p>
          <div className="btn" onClick={this.hidepop}>
            OK
          </div>
          <img src={require('../image/common/close.png')} className="close" onClick={this.hidepop} alt="Close Dialog" />
        </div>
        {/* end exact same code as desktop */}
        {/*<div className="mobile-footer" style={{ marginBottom: 30 }}>
          <div className="footer-questions">
            <div className="footer-questions-block">
              <p className="footer-questions-heading">{footerquestions}</p>
              <p className="footer-questions-content">{footercontactphone}</p>
              <p className="footer-questions-content">{footercontactphone2}</p>
              <p className="footer-questions-content">{footercontactemail}</p>
              <p className="footer-questions-content">{footercontactwhatsapp}</p>
            </div>
            <div className="footer-questions-block">
              <p className="footer-questions-heading">{footercontactonline}</p>
              <div className="footer-social">
                <a
                  href="https://www.facebook.com/STK-Workshop-2238828629510959/?modal=admin_todo_tour"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require('../image/brand/fb@3x.png')} alt="STK Workshop Facebook" />
                </a>
                <a href="https://www.instagram.com/stkworkshop/" target="_blank" rel="noopener noreferrer">
                  <img src={require('../image/brand/ig@3x.png')} alt="STK Workshop Instagram" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UClwt44Lndl-mgx8ZwoWSdhQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require('../image/brand/youtube@3x.png')} alt="STK Workshop YouTube" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-newsletter-sign-up">
            <p className="footer-newsletter-heading">
              {footernewslettersloganm}
              <br />
              {footernewslettersloganm1}
            </p>
            <p className="footer-newsletter-heading2">{footernewsletterslogan2}</p>
            <div className="footer-newsletter-sign-up-box">
              <form action="https://stk-workshop.us20.list-manage.com/subscribe/post" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                <input type="hidden" name="u" value="bebd4fe24965a043e1d8ac757"/>
                <input type="hidden" name="id" value="4edc74d001"/>

                <input type="email" placeholder={footernewsletteremail} value={this.state.mcemail} onChange={(e)=>{this.setState({mcemail:e.target.value});}} name="EMAIL" className="required email" id="mce-EMAIL" />
                <input type="submit" value={footernewsletterbutton} name="subscribe" id="mc-embedded-subscribe" />
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_bebd4fe24965a043e1d8ac757_4edc74d001" tabindex="-1" value="" /></div>
              </form>
            </div>
            <p className="footer-newsletter-fineprint">{footernewsletterfineprint}</p>
          </div>
          <div className="mobile-stk-copyright">
            <p>{shopping2018}</p>
            <p>
              <a onClick={() => this.about()}>{aboutustitle}</a>
              <span>|</span>
              <a onClick={() => this.tc()}>{shoppingterms}</a>
            </p>
            <p>
              <a onClick={() => this.privacypolicy()}>{privacyPolicy}</a>
              <span>|</span>
              <a onClick={() => this.useofwebsite()}>{useOfWebsite}</a>
            </p>
          </div>
        </div>*/}
      </div>
    ) : (
      <div className="max-home bg-white-wrapper" style={{height: '100%'}}>
        <HeaderNav />
        {/* exact same code as mobile */}
        <div className="product-details bg-white">
            <div
                className="null"
                id="null"
                style={{ minHeight: this.isMobile() ? 60 : 104, height: 139 * webWidthScale, maxHeight: 150 }}
            />
            <div className="content-wrapper">
                {/* Pre-loader */}
                <div id="preloader-container" className="preloader-container">
                    <div className="preloader">
                        <img src={require('../image/common/loading.gif')} alt="Loading" />
                    </div>
                </div>
                {/* Content */}
                {/*data.product_key === 'btmf_classic_plaque' || data.product_key === 'btmf_technical_plaque' || data.product_key === 'btmf_personalized_technical_plaque' ? (
                  <div className="product-detail-banner">{plaquetxtbanner01}<a href="#" onClick={() => this.learnmorePlaque()}>{learnmore}</a></div>
                ) : null*/}
                {data.product_key === 'btmf_acrylic_floor_stand' || data.product_key === 'btmf_acrylic_table_top_stand' || data.product_key === 'btmf_metal_floor_stand' || data.product_key === 'btmf_metal_table_top_stand' || data.product_key === 'btmf_wall_mount' ? (
                  <div className="product-detail-banner">{plaquetxtbanner02}<NavLink to="/btmf/products">{learnmore}</NavLink></div>
                ) : null}
                <div className="content">
                    <div className="content-left">
                        <div className="breadcrumb">
                            <span>{breadcrumbproduct} > </span>
                            {localStorage.lang === 'en' ? (
                                data.name
                            ) : null}
                            {!localStorage.lang || localStorage.lang === 'tw' || localStorage.lang === '' ? (
                                data.zhtw_product_item_name
                            ) : null}
                        </div>
                        <div className="content-media-container">
                            <div className="content-media-display">
                                {data.video_url && displayVideo ? (
                                  <YoutubeEmbed youtubeId={data.video_url} />
                                ) : (
                                  <img src={displayImage} alt="" />
                                )}
                            </div>
                            <div className="content-media-thumbnails">
                                <div onClick={() => this.changeImage(data.image)}><img src={data.image} alt="" /></div>
                                {data.images.map((item, index) => (
                                    <div onClick={() => this.changeImage(item.image)}><img src={item.image} alt="" /></div>
                                ))}
                                {data.video_url ? (
                                  <div className="video-play-thumb" onClick={() => this.changeVideo()}><div className="video-play-icon"></div><img src={require('../image/home/video-thumb.png')} alt="" /></div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="content-right">
                        {/* product series */}
                        <h2>
                            {localStorage.lang === 'en' ? (
                                data.product_series
                            ) : null}
                            {!localStorage.lang || localStorage.lang === 'tw' || localStorage.lang === '' ? (
                                data.zhtw_product_series
                            ) : null}
                        </h2>
                        {/* product name */}
                        <h1>
                            {localStorage.lang === 'en' ? (
                                data.name
                            ) : null}
                            {!localStorage.lang || localStorage.lang === 'tw' || localStorage.lang === '' ? (
                                data.zhtw_product_item_name
                            ) : null}
                        </h1>
                        {/* product variation */}
                        <h3>
                            {localStorage.lang === 'en' ? (
                                data.product_variation
                            ) : null}
                            {!localStorage.lang || localStorage.lang === 'tw' || localStorage.lang === '' ? (
                                data.zhtw_product_variation
                            ) : null}
                        </h3>
                        {/* product price */}
                        {data.is_installment ? (
                          <React.Fragment>
                            {data.regular_price !== data.initial_payment ? (
                              <React.Fragment>
                                <p className="regular-price">{shoppingregular} {data.regular_price}</p>
                                <p className="sale-price">{saleprice} {data.initial_payment}</p>
                              </React.Fragment>
                            ) : (
                              <p className="sale-price" style={{color: '#000'}}>{originalprice} {data.initial_payment}</p>
                            )}
                            <p>
                              ({localStorage.lang !== 'en' ? cart64tc : null}NT$ {Math.floor(data.price)}
                              {localStorage.lang === 'en' ? cart64 : null})
                            </p>
                            <br />
                            <br />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {data.regular_price !== data.price ? (
                              <React.Fragment>
                                <p className="regular-price">{shoppingregular} {data.regular_price}</p>
                                <p className="sale-price">{saleprice} {data.price}</p>
                              </React.Fragment>
                            ) : (
                              <p className="sale-price" style={{color: '#000'}}>{originalprice} {data.price}</p>
                            )}
                          </React.Fragment>
                        )}
                        {/* delivery date */}
                        {this.formatReceiveDate(data.least_days_to_ship, data.most_days_to_ship) != null ? (
                            <p className="estimated-delivery-date">
                                {this.formatReceiveDate(data.least_days_to_ship, data.most_days_to_ship)}
                            </p>
                        ) : null}
                        {data.shipping_notice != '' ? (
                            <p className="delivery-notice">
                                {localStorage.lang === 'en' ? <span>{data.shipping_notice}</span> : null}
                                {!localStorage.lang || localStorage.lang === 'tw' || localStorage.lang === '' ? (
                                <span>{data.zhtw_shipping_notice}</span>
                                ) : null}
                            </p>
                        ) : null}
                        <div className="btn-add-cart" onClick={() => this.addCart(data.product_key)}>
                            {addtocart}
                        </div>
                        {this.renderPlaque()}
                        <br />
                        <br />
                        <hr />
                        <br />
                        <br />
                        {localStorage.lang === 'en' ? (
                            <div dangerouslySetInnerHTML={this.htmlWrapper(data.description)} />
                        ) : null}
                        {!localStorage.lang || localStorage.lang === 'tw' || localStorage.lang === '' ? (
                            <div dangerouslySetInnerHTML={this.htmlWrapper(data.description_zh)} />
                        ) : null}
                    </div>
                </div>

                {/*<div className="forter-info" style={{ marginTop: 120, marginBottom: 90 }}>
                <div className="footer-newsletter-sign-up">
                    <p className="footer-newsletter-heading">
                    {footernewslettersloganA}
                    <br />
                    {footernewslettersloganB}
                    </p>
                    <p className="footer-newsletter-heading2">{footernewsletterslogan2}</p>
                    <div className="footer-newsletter-sign-up-box">
                    <form action="https://stk-workshop.us20.list-manage.com/subscribe/post" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                        <input type="hidden" name="u" value="bebd4fe24965a043e1d8ac757"/>
                        <input type="hidden" name="id" value="4edc74d001"/>

                        <input type="email" placeholder={footernewsletteremail} value={this.state.mcemail} onChange={(e)=>{this.setState({mcemail:e.target.value});}} name="EMAIL" className="required email" id="mce-EMAIL" />
                        <input type="submit" value={footernewsletterbutton} name="subscribe" id="mc-embedded-subscribe" />
                        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_bebd4fe24965a043e1d8ac757_4edc74d001" tabindex="-1" value="" /></div>
                    </form>
                    </div>
                    <p className="footer-newsletter-fineprint">{footernewsletterfineprint}</p>
                </div>
                <div className="stk-copyright">
                    <p>{shopping2018}</p>
                    <p>
                    <a onClick={() => this.about()}>{aboutustitle}</a>
                    <span>|</span>
                    <a onClick={() => this.tc()}>{shoppingterms}</a>
                    <span>|</span>
                    <a onClick={() => this.privacypolicy()}>{privacyPolicy}</a>
                    <span>|</span>
                    <a onClick={() => this.useofwebsite()}>{useOfWebsite}</a>
                    </p>
                </div>
                <div className="footer-questions">
                    <div className="footer-questions-block">
                    <p className="footer-questions-heading">{footerquestions}</p>
                    <p className="footer-questions-content">{footercontactphone}</p>
                    <p className="footer-questions-content">{footercontactphone2}</p>
                    <p className="footer-questions-content">{footercontactemail}</p>
                    <p className="footer-questions-content">{footercontactwhatsapp}</p>
                    </div>
                    <div className="footer-questions-block">
                    <p className="footer-questions-heading">{footercontactonline}</p>
                    <div className="footer-social">
                        <a
                        href="https://www.facebook.com/STK-Workshop-2238828629510959/?modal=admin_todo_tour"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        <img src={require('../image/brand/fb@3x.png')} alt="STK Workshop Facebook" />
                        </a>
                        <a href="https://www.instagram.com/stkworkshop/" target="_blank" rel="noopener noreferrer">
                        <img src={require('../image/brand/ig@3x.png')} alt="STK Workshop Instagram" />
                        </a>
                        <a
                        href="https://www.youtube.com/channel/UClwt44Lndl-mgx8ZwoWSdhQ"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        <img src={require('../image/brand/youtube@3x.png')} alt="STK Workshop YouTube" />
                        </a>
                    </div>
                    </div>
                </div>
                </div>*/}

            </div>
        </div>
        <div className={showpop ? 'pop' : 'hidden'}>
          <p>{text1}</p>
          <p>{text2}</p>
          <p className="footnote">{text3}</p>
          <div className="btn" onClick={this.hidepop}>
            OK
          </div>
          <img src={require('../image/common/close.png')} className="close" onClick={this.hidepop} alt="Close Dialog" />
        </div>
        {/* end exact same code as mobile */}
      </div>
    );
  }
}
ProductDetails = withRouter(injectIntl(ProductDetails));
export default ProductDetails;
