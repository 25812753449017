import React from 'react';
import { injectIntl } from 'react-intl';
import '../css/mobileVideo.css';
import '../css/mobilehome.css';
var play = false;

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: true
    };
  }
  componentDidMount = () => {
    var video = document.getElementById('video');
    video.addEventListener('timeupdate', this.timeupdate);
  };
  componentWillUnMount = () => {
    var video = document.getElementById('video');
    video.removeEventListener('timeupdate', this.timeupdate);
  };
  timeupdate = () => {
    var video = document.getElementById('video');
    if (video && video.currentTime === video.duration) {
      play = false;
      var img = document.getElementById('videoButton').getElementsByTagName('img');
      img[0].style.display = play ? 'none' : 'block';
      // img[1].style.display = play?"block":"none";
    }
  };
  videoButton = () => {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      //play?this.props.hideNav(false):this.props.hideNav(true);

      play = !play;
      var video = document.getElementById('video');
      document.getElementById('videoButton').style.display = play ? 'none' : 'block';
      play ? video.play() : video.pause();
      video.style.objectFit = play ? 'contain' : 'cover';
      var progressTimeMobile = document.getElementById('progressTimeMobile');
      progressTimeMobile.style.display = play ? 'block' : 'none';

      //var img = document.getElementById("videoButton").getElementsByTagName("img");
      //img[0].style.display = play?"none":"block";
      // img[1].style.display = play?"block":"none";
    } else {
      play = !play;
      var video = document.getElementById('video');
      play ? video.play() : video.pause();
      video.style.objectFit = play ? 'contain' : 'cover';
      var progressTime = document.getElementById('progressTime');
      progressTime.style.display = play ? 'block' : 'none';

      var img = document.getElementById('videoButton').getElementsByTagName('img');
      img[0].style.display = play ? 'none' : 'block';
      // img[1].style.display = play?"block":"none";
    }
  };
  x = () => {
    document.getElementsByClassName('video-hide')[0].style.display = 'none';
    var video = document.getElementById('video');
    video.pause();
    video.currentTime = 0;
    play = false;
    var img = document.getElementById('videoButton').getElementsByTagName('img');
    img[0].style.display = play ? 'none' : 'block';
    // img[1].style.display = play?"block":"none";
    this.props.hideNav(true);
  };

  updateProgress = () => {
    var video = document.getElementById('video');
    var currentPercent = Math.round((Math.floor(video.currentTime) / Math.floor(video.duration)) * 100, 0); //计算当前播放时长与视频总时长之比
    var progress = document.getElementById('progress');
    progress.style.width = currentPercent + '%';
    if (video.currentTime === video.duration) {
      this.setState({
        player: false
      });
    }
  };
  videoPlay = () => {
    var video = document.getElementById('video');
    play = !play;
    play ? video.play() : video.pause();
    if (play) {
      this.setState(
        {
          player: true
        },
        () => console.log(this.state.player)
      );
    } else {
      this.setState({
        player: false
      });
    }
  };
  back = () => {
    var video = document.getElementById('video');
    if (video.currentTime < 4) {
      video.currentTime = 0;
    } else {
      video.currentTime = video.currentTime - 4;
    }
  };
  forward = () => {
    var video = document.getElementById('video');
    if (video.currentTime > video.duration - 4) {
      video.currentTime = video.duration;
    } else {
      video.currentTime = video.currentTime + 4;
    }
  };
  render() {
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) || this.state.width < 1280 ? (
      <div className="mobile">
        <div className="mobile-video" id="mobileVideo" style={{}}>
          <div className="home-video" id="playVideo">
            {!localStorage.lang || localStorage.lang !== 'en' ? (
              <video
                controls
                id="video"
                src={require('../video/videotcm.mp4')}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                type="video/mp4"
              />
            ) : null}
            {localStorage.lang === 'en' ? (
              <video
                controls
                id="video"
                src={require('../video/videom.mp4')}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                type="video/mp4"
              />
            ) : null}
          </div>
          <div id="progressTimeMobile" className="progressTimeMobile">
            <div className="progress">
              <div id="progress" />
            </div>
            {/*<div className="console">
                      <img src={require("../image/play/ic_back.png")} alt="Rewind Video" onClick={this.back}/>	 
                      <div className="videoPlay" onClick={this.videoButton}>                   
                        <img src={require("../image/play/pause.png")} alt="Pause Video" style={{display: this.state.player?'inline-block':'inline-block',}}/>	
                      </div>
                      <img src={require("../image/play/ic_fast_forward.png")} alt="Fast Forward Video" onClick={this.forward}/>	
									</div>*/}
          </div>

          <div className="video-button" id="videoButton" onClick={this.videoButton}>
            <img src={require('../image/play/play@3x.png')} alt="Play Video" />
            {/* <img src={require("../image/play/pause.png")} alt="Pause Video"/> */}
            <div className="pause-button" />
          </div>
        </div>
        {/*<div className="home-bottom" style={{position:'absolute',margin: 0,bottom:12,}}>
						<div className="home-bottom-arrow">
							<span></span>
							<i></i>
						</div>
					</div>*/}
      </div>
    ) : (
      <div className="component first-component">
        <div className="home-video" id="playVideo">
          {!localStorage.lang || localStorage.lang !== 'en' ? (
            <video
              id="video"
              src={require('../video/videotc.mp4')}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              type="video/mp4"
            />
          ) : null}
          {localStorage.lang === 'en' ? (
            <video
              id="video"
              src={require('../video/video.mp4')}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              type="video/mp4"
            />
          ) : null}
        </div>
        <div id="progressTime" className="progressTime">
          <div className="progress">
            <div id="progress" />
          </div>
          <div className="console console-desktop">
            <img src={require('../image/play/ic_back.png')} alt="Rewind Video" onClick={this.back} />
            <div className="videoPlay" onClick={this.videoButton}>
              <img
                src={require('../image/play/pause@3x.png')}
                alt="Pause Video"
                style={{ display: this.state.player ? 'inline-block' : 'inline-block' }}
              />
            </div>
            <img src={require('../image/play/ic_fast_forward.png')} alt="Fast Forward Video" onClick={this.forward} />
          </div>
        </div>
        <div className="video-button" id="videoButton" onClick={this.videoButton}>
          <img src={require('../image/play/play@3x.png')} alt="Play Video" />
          {/* <img src={require("../image/play/pause.png")} alt="Pause Video"/> */}
          <div className="pause-button" />
        </div>
      </div>
    );
  }
}
Video = injectIntl(Video);
export default Video;
