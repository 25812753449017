import React from 'react';
import { injectIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import HeaderNav from './../components/HeaderNav';
import FooterStaticDesktop from './FooterStaticDesktop';
import FooterStaticMobile from './FooterStaticMobile';
import isMobile from '../utils/isMobile';
import * as request from '../apiClient/request';
import '../css/mobileBox.css';
import '../css/mobileShopping.css';
import '../css/gallery.css';
import '../css/instructions.css';
import '../css/faq.css';
import '../css/main.css';

var webWidthScale = document.documentElement.clientWidth / 1440;
var thiz;

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webWidthScale: document.documentElement.clientWidth / 1440,
      webHeight: document.documentElement.clientHeight,
      webWight: document.documentElement.webWight,
      play: false,
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.resize);
  }
  resize() {
    thiz.setState({
      webWidthScale: document.documentElement.clientWidth / 1440,
      webHeight: document.documentElement.clientHeight,
      webWight: document.documentElement.webWight
    });
    webWidthScale = document.documentElement.clientWidth / 1440;
  }

  componentDidMount() {
    var lang = localStorage.lang;
    if (!lang) { lang = 'en'}
    if (lang == 'hk') { lang = 'zh-hk' }
    if (lang == 'tw') { lang = 'zh-tw' }
    if (lang == 'cn') { lang = 'zh-cn' }

    request
      .get('page/content?page=faq&language=' + lang )
      .then(res => {
        console.log('res');
        console.log(res);
        this.setState({
          data: res
        });
      })
      .catch(err => {
        let str = JSON.stringify(err);
        console.log('err2');
        console.log(err);
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          this.push('/btmf/');
        }
      });
  }

  htmlWrapper(text) { return {__html: text}; };

  render() {
    thiz = this;
    const { intl } = this.props;
    let faq = intl.formatMessage({ id: 'faq' });

    return isMobile() ? (
      <div className="mobile-ma-shopping max-home">
        <HeaderNav selectedItem="faq" />
        <div className="faq" style={{ paddingTop: 80, paddingLeft: 20, paddingRight: 20 }}>
          <h2 className="title">{faq}</h2>   
          <div className="content">
            <div dangerouslySetInnerHTML={this.htmlWrapper(this.state.data)} />
          </div>
        </div>
        <FooterStaticMobile />
      </div>
    ) : (
      <div className="max-home">
        <HeaderNav selectedItem="faq" />
        <div style={{ paddingTop: 139 * webWidthScale }}>
          <div className="shopping-content" id="shopping-content">
            <div className="faq" style={{ width: 940 * this.state.webWidthScale }}>
              <h2 className="title">{faq}</h2>  
              <div className="content">
                <div dangerouslySetInnerHTML={this.htmlWrapper(this.state.data)} />
              </div>
            </div>
            <FooterStaticDesktop />
          </div>
        </div>
      </div>
    );
  }
}
Faq = withRouter(injectIntl(Faq));
export default Faq;
