import React from 'react';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import isMobile from '../utils/isMobile';
import '../css/mobileModelThree.css';
import '../css/mobileModelFive.css';
import '../css/mobileVideo.css';

var pcWidthScale = document.documentElement.clientWidth / 1440;
var mobileWidthScale = document.documentElement.clientWidth / 375;
var mobileHeightScale = document.documentElement.clientHeight / 667;
class ModelFour1 extends React.Component {
  state = {
    clientHeight: document.documentElement.clientHeight
  };

  componentWillMount = () => {
    window.addEventListener('resize', this.resize);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.engaged !== this.props.engaged && this.video) {
      if (this.props.engaged) {
        this.video.play();
      } else {
        this.video.currenTime = 0;
      }
    }
  }

  resize = () => {
    pcWidthScale = document.documentElement.clientWidth / 1440;
    this.setState({
      width: document.documentElement.clientWidth
    });
  };

  render() {
    const { intl } = this.props;
    let modelfourdynamic = intl.formatMessage({ id: 'modelfourdynamic' });
    let modelfour1programmed = intl.formatMessage({ id: 'modelfour1programmed' });
    let modelfourprogrammed = intl.formatMessage({ id: 'modelfourprogrammed' });
    let modelfoursequence = intl.formatMessage({ id: 'modelfoursequence' });
    return isMobile() ? (
      <div className="test" style={{ height: '100%' }}>
        <div className="mobile-ma">
          <div className="mobile-model3">
            <div className="mobile-model3-video">
              <div
                id="mobile-model41-canvas-virtual"
                className={cx({
                  'mobile-canvas-virtual': true,
                  engaged: this.props.engaged
                })}
              >
                <div className="line t-l" style={{ top: 98 * mobileWidthScale }} />
                <div className="line t-r" style={{ top: 98 * mobileWidthScale }} />
                <div className="line v-l" id="mobile-model41-canvas3" style={{ top: 98 * mobileWidthScale }} />
                <div className="line v-r" id="mobile-model41-canvas4" style={{ top: 98 * mobileWidthScale }} />
                {/*<div className="line b-l" style={{top:98*mobileWidthScale+574*mobileHeightScale-98*(mobileWidthScale-1)}}></div>
              <div className="line b-r" style={{top:98*mobileWidthScale+574*mobileHeightScale-98*(mobileWidthScale-1)}} ></div>*/}
                <div className="line b-l" style={{ top: 600 }} />
                <div className="line b-r" style={{ top: 600 }} />
              </div>
              <div className="mobile-model3-video-title">{modelfourdynamic}</div>
              <div className="mobile-model3-video-info" style={{ top: 600, marginTop: -60 }}>
                <div>
                  <img src={require('../image/home/engine copy@3x.png')} alt="Engine Icon" />
                </div>
                <p>{modelfour1programmed}</p>
              </div>
              <div className="mobile-model5-pic mobile-model41-video">
                <video
                  ref={ref => (this.video = ref)}
                  autoPlay="true"
                  muted="true"
                  playsInline="true"
                  id="mobileModelFour1Video"
                  //src={require('../video/model4-video2m.mp4')}
                  src="https://stk-resource.s3-ap-northeast-1.amazonaws.com/videos/model4-video2m.mp4"
                  style={{ width: 'auto', height: '100%' }}
                  type="video/mp4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="component first-component">
        <div className="model3">
          <div
            id="pc-model41-canvas-virtual"
            className={cx({
              'canvas-virtual': true,
              engaged: this.props.engaged
            })}
          >
            <div className="line t-l" />
            <div className="line t-r" />
            <div className="line v-l" />
            <div className="line v-r" />
            <div className="line b-l" />
            <div className="line b-r" />
          </div>
          <div style={{ width: '100%' }} id="webModelFour1">
            <div className="null" />
            <div className="model3-details">
              <span
                style={{ backgroundColor: '#000', position: 'relative', zIndex: 1, paddingLeft: 30, paddingRight: 30 }}
              >
                {modelfourdynamic}
              </span>
            </div>
            <div className="model3-video2" style={{ height: 530 * pcWidthScale }}>
              <div
                className="model3-video2-info model3-video1-info model4-video2-info model4-video-info"
                id="model4-video-info2"
                style={{
                  right: 155 * pcWidthScale,
                  opacity: this.props.engaged ? 1 : 0,
                  visibility: this.props.engaged ? 'visible' : 'none'
                }}
              >
                <div className="icon" style={{ float: 'right' }}>
                  <img src={require('../image/home/engine copy@3x.png')} alt="Engine Icon" />
                </div>
                <p>
                  {modelfourprogrammed}
                  <br />
                  {modelfoursequence}
                </p>
                <div
                  className="canvas-line canvas-line-right"
                  style={{ right: 0, width: 428 + document.documentElement.clientWidth - 1200 }}
                >
                  <div style={{ left: 0 }} />
                </div>
              </div>
              <div className="model3-video2-1">
                <video
                  ref={ref => (this.video = ref)}
                  autoPlay="true"
                  muted="true"
                  src={require('../video/model4-video2.mp4')}
                  style={{ width: pcWidthScale * 932, height: pcWidthScale * 530 }}
                  type="video/mp4"
                  id="modelFour1Video"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ModelFour1 = injectIntl(ModelFour1);
export default ModelFour1;
