import React from 'react';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import isMobile from '../utils/isMobile';
import '../css/mobileModelThree.css';
import '../css/mobileModelFive.css';
import '../css/mobileVideo.css';

var mobileWidthScale = document.documentElement.clientWidth / 375;

class ModelFive2 extends React.Component {
  render() {
    const { intl } = this.props;
    let modelfiveinterior = intl.formatMessage({ id: 'modelfiveinterior' });
    let modelfiveworking = intl.formatMessage({ id: 'modelfiveworking' });
    let modelfivehold = intl.formatMessage({ id: 'modelfivehold' });
    return isMobile() ? (
      <div className="test" style={{ height: '100%' }}>
        <div className="mobile-ma">
          <div className="mobile-model3">
            <div className="mobile-model3-video">
              <div
                id="mobile-model52-canvas-virtual"
                className={cx({
                  'mobile-canvas-virtual': true,
                  engaged: this.props.engaged
                })}
              >
                <div className="line t-l" style={{ top: 98 * mobileWidthScale }} />
                <div className="line t-r" style={{ top: 98 * mobileWidthScale }} />
                <div className="line v-l" id="mobile-model52-canvas3" style={{ top: 98 * mobileWidthScale }} />
                <div className="line v-r" id="mobile-model52-canvas4" style={{ top: 98 * mobileWidthScale }} />
                {/*<div className="line b-l" style={{top:98*mobileWidthScale+574*mobileHeightScale-98*(mobileWidthScale-1)}}></div>
              <div className="line b-r" style={{top:98*mobileWidthScale+574*mobileHeightScale-98*(mobileWidthScale-1)}} ></div>*/}
                <div className="line b-l" style={{ top: 600 }} />
                <div className="line b-r" style={{ top: 600 }} />
              </div>
              <div className="mobile-model3-video-title">{modelfiveinterior}</div>
              <div
                className="mobile-model3-video-info"
                style={{
                  top: 600,
                  marginTop: -60,
                  opacity: this.props.engaged ? 1 : 0,
                  visibility: this.props.engaged ? 'visible' : 'none'
                }}
              >
                <div>
                  <img src={require('../image/home/corridor@2x.png')} alt="Corridor Icon" />
                </div>
                <p>
                  {modelfiveworking}
                  {modelfivehold}
                </p>
              </div>
              <div className="mobile-model5-pic">
                <img src={require('../video/model5-video3.jpg')} alt="Corridor" />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}
ModelFive2 = injectIntl(ModelFive2);
export default ModelFive2;
