import React from 'react';

import ReactFullpage from '@fullpage/react-fullpage';
import { Element, scroller } from 'react-scroll';
import VisibilitySensor from 'react-visibility-sensor';

import Lead from './Lead';
import Video from './Video';
import ModelOne from './ModelOne';
import ModelTwo from './ModelTwo';
import ModelTree from './ModelTree';
import ModelTree1 from './ModelTree1';
import ModelFour from './ModelFour';
import ModelFour1 from './ModelFour1';
import ModelFive from './ModelFive';
import ModelFive1 from './ModelFive1';
import ModelFive2 from './ModelFive2';
import ModelFive3 from './ModelFive3';
import ModelSix from './ModelSix';
import Box from './Box';
import Guide from './Guide';
import Service from './Service';
import Shopping from './Shopping';
import Shopping1 from './Shopping1';
import Accessories from './Accessories';
import Gallery from './Gallery';
import Footer from './Footer';
import isMobile from '../utils/isMobile';
import '../css/main.css';

class HomePageContent extends React.Component {
  state = {
    showpop: false,
    showpop1: false,
    text1: '',
    text2: '',
    text3: '',
    cartNumber: null,
    modelTree1: false,
    modelFour: false,
    modelFour1: false,
    modelFive: false,
    modelFive1: false,
    modelFive2: false,
    modelFive3: false
  };

  componentDidMount() {
    if (this.props.scrollToSection) {
      this.checkScroll();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.scrollToSection !== prevProps.scrollToSection) {
      this.checkScroll();
    }
  }

  checkScroll() {
    if (!this.props.scrollToSection) {
      return;
    }
    if (isMobile()) {
      this.goToSection(this.props.scrollToSection);
    } else {
      setTimeout(this.goToSection.bind(this, this.props.scrollToSection), 10);
    }
  }

  goToSection = section => {
    if (isMobile()) {
      scroller.scrollTo(`section-${section}`, {
        duration: 0,
        delay: 1,
        smooth: true,
        offset: -60
      });
    } else {
      if (this.props.fullpageApi) {
        this.props.fullpageApi.silentMoveTo(section);
      }
    }
  };

  onChangeVisibility = (section, isVisible) => {
    if (isVisible) {
      this.props.onChangeCurrentSection(section);
    }
  };

  renderDesktop() {
    return (
      <div className="backgroup">
        <ReactFullpage.Wrapper>
          <div className="component fullpage-section">
            <Element name="section-1" className="page1 page-section" id="page1" ref="page1">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 1)}>
                <Lead
                  engaged={this.props.currentSection === 1}
                  goToSection={res => this.goToSection(res)}
                />
              </VisibilitySensor>
            </Element>
          </div>

          <div className="component fullpage-section">
            <Element name="section-2" className="page2 page-section" id="page2" ref="page2">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 2)}
                partialVisibility={true}
                offset={{ top: 50, bottom: 50 }}
              >
                <Video hideNav={val => this.hideNav(val)} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-3" className="page-section">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 3)}
                partialVisibility={true}
                offset={{ top: 50, bottom: 50 }}
              >
                <ModelOne engaged={this.props.currentSection === 3} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-4" className="page-section">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 4)}
                partialVisibility={true}
                offset={{ top: 50, bottom: 50 }}
              >
                <ModelTwo engaged={this.props.currentSection === 4} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-5" className="page-section">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 5)}
                partialVisibility={true}
                offset={{ top: 50, bottom: 50 }}
              >
                <ModelTree engaged={this.props.currentSection === 5} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-6" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 6)}>
                <ModelTree1 engaged={this.props.currentSection === 6} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-7" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 7)}>
                <ModelFour engaged={this.props.currentSection === 7} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-8" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 8)}>
                <ModelFour1 engaged={this.props.currentSection === 8} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-9" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 9)}>
                <ModelFive engaged={this.props.currentSection === 9} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-10" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 10)}>
                <ModelFive1 engaged={this.props.currentSection === 10} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-11" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 11)}>
                <ModelSix
                  engaged={this.props.currentSection === 11}
                  goToSection={res => this.goToSection(res)}
                  showpop={(text1, text2, text3) => this.props.showpop(text1, text2, text3)}
                  showpop1={(text1, text2, text3) => this.props.showpop1(text1, text2, text3)}
                  cartNumber={this.cartNumber}
                />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-12" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 12)}>
                <Box
                  goToSection={res => this.goToSection(res)}
                  showpop={(text1, text2, text3) => this.props.showpop(text1, text2, text3)}
                  showpop1={(text1, text2, text3) => this.props.showpop1(text1, text2, text3)}
                  cartNumber={this.cartNumber}
                />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-13" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 13)}>
                <Guide />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-14" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 14)}>
                <Service
                  engaged={this.props.currentSection === 14}
                  goToSection={res => this.goToSection(res)}
                />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-15" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 15)}>
                <Shopping
                  showpop={(text1, text2, text3) => this.props.showpop(text1, text2, text3)}
                  showpop1={(text1, text2, text3) => this.props.showpop1(text1, text2, text3)}
                  cartNumber={this.cartNumber}
                />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-16" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 16)}>
                <Shopping1
                  showpop={(text1, text2, text3) => this.props.showpop(text1, text2, text3)}
                  showpop1={(text1, text2, text3) => this.props.showpop1(text1, text2, text3)}
                  cartNumber={this.cartNumber}
                />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-17" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 17)}>
                <Accessories
                  goToSection={res => this.goToSection(res)}
                />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-18" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 18)}>
                <Gallery goToSection={res => this.goToSection(res)} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component fullpage-section">
            <Element name="section-19" className="page-section">
              <VisibilitySensor onChange={this.onChangeVisibility.bind(this, 19)}>
                <Footer />
              </VisibilitySensor>
            </Element>
          </div>
        </ReactFullpage.Wrapper>
      </div>
    );
  }

  renderMobile() {
    return (
      <div className="backgroup">
        <div className="fullpage-section">
          <div className="component component-m" style={{ minHeight: '100vh' }}>
            <Element name="section-1" className="page1 page-section page-section-m" id="page1" ref="page1">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 1)}
                partialVisibility={true}
                offset={{ top: 500, bottom: 500 }}
              >
                <Lead goToSection={res => this.goToSection(res)} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 630 }}>
            <Element name="section-2" className="page2 page-section page-section-m" id="page2" ref="page2">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 2)}
                partialVisibility={true}
                offset={{ top: 50, bottom: 50 }}
              >
                <Video hideNav={val => this.hideNav(val)} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 660 }}>
            <Element name="section-3" className="page-section page-section-m" id="page3">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 3)}
                partialVisibility={true}
                offset={{ top: 50, bottom: 50 }}
              >
                <ModelOne engaged={this.props.currentSection === 3} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 570 }}>
            <Element name="section-4" className="page-section page-section-m" id="page4">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 4)}
                partialVisibility={true}
                offset={{ top: 300, bottom: 300 }}
              >
                <ModelTwo engaged={this.props.currentSection === 4} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 630 }}>
            <Element name="section-5" className="page-section page-section-m" id="page5">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 5)}
                partialVisibility={true}
                offset={{ top: 200, bottom: 200 }}
              >
                <ModelTree engaged={this.props.currentSection === 5} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 630 }}>
            <Element name="section-6" className="page-section page-section-m" id="page6">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 6)}
                partialVisibility={true}
                offset={{ top: 200, bottom: 200 }}
              >
                <ModelTree1 engaged={this.props.currentSection === 6} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 630 }}>
            <Element name="section-7" className="page-section page-section-m" id="page7">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 7)}
                partialVisibility={true}
                offset={{ top: 200, bottom: 200 }}
              >
                <ModelFour engaged={this.props.currentSection === 7} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 630 }}>
            <Element name="section-8" className="page-section page-section-m" id="page8">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 8)}
                partialVisibility={true}
                offset={{ top: 200, bottom: 200 }}
              >
                <ModelFour1 engaged={this.props.currentSection === 8} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 630 }}>
            <Element name="section-9" className="page-section page-section-m" id="page9">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 9)}
                partialVisibility={true}
                offset={{ top: 200, bottom: 200 }}
              >
                <ModelFive engaged={this.props.currentSection === 9} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 630 }}>
            <Element name="section-10" className="page-section page-section-m" id="page10">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 10)}
                partialVisibility={true}
                offset={{ top: 200, bottom: 200 }}
              >
                <ModelFive1 engaged={this.props.currentSection === 10} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 630 }}>
            <Element name="section-11" className="page-section page-section-m" id="page11">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 11)}
                partialVisibility={true}
                offset={{ top: 200, bottom: 200 }}
              >
                <ModelFive2 engaged={this.props.currentSection === 11} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 630 }}>
            <Element name="section-12" className="page-section page-section-m" id="page12">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 12)}
                partialVisibility={true}
                offset={{ top: 200, bottom: 200 }}
              >
                <ModelFive3 engaged={this.props.currentSection === 12} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 630 }}>
            <Element name="section-13" className="page-section page-section-m" id="page13">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 13)}
                partialVisibility={true}
                offset={{ top: 100, bottom: 100 }}
              >
                <ModelSix
                  goToSection={res => this.goToSection(res)}
                  showpop={(text1, text2, text3) => this.props.showpop(text1, text2, text3)}
                  showpop1={(text1, text2, text3) => this.props.showpop1(text1, text2, text3)}
                  cartNumber={this.cartNumber.bind(this)}
                />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 630 }}>
            <Element name="section-14" className="page-section page-section-m" id="page14">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 14)}
                partialVisibility={true}
                offset={{ top: 100, bottom: 100 }}
              >
                <Box
                  goToSection={res => this.goToSection(res)}
                  showpop={(text1, text2, text3) => this.props.showpop(text1, text2, text3)}
                  showpop1={(text1, text2, text3) => this.props.showpop1(text1, text2, text3)}
                  cartNumber={this.cartNumber.bind(this)}
                />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 630 }}>
            <Element name="section-15" className="page-section page-section-m" id="page15">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 15)}
                partialVisibility={true}
                offset={{ top: 100, bottom: 100 }}
              >
                <Guide />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 630 }}>
            <Element name="section-16" className="page-section page-section-m" id="page16">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 16)}
                partialVisibility={true}
                offset={{ top: 100, bottom: 100 }}
              >
                <Service goToSection={res => this.goToSection(res)} />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 1160 }}>
            <Element name="section-17" className="page-section page-section-m" id="page17">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 17)}
                partialVisibility={true}
                offset={{ top: 50, bottom: 50 }}
              >
                <Shopping
                  showpop={(text1, text2, text3) => this.props.showpop(text1, text2, text3)}
                  showpop1={(text1, text2, text3) => this.props.showpop1(text1, text2, text3)}
                  cartNumber={this.cartNumber.bind(this)}
                />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 1380 }}>
            <Element name="section-18" className="page-section page-section-m" id="page18">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 18)}
                partialVisibility={true}
                offset={{ top: 50, bottom: 50 }}
              >
                <Shopping1
                  showpop={(text1, text2, text3) => this.props.showpop(text1, text2, text3)}
                  showpop1={(text1, text2, text3) => this.props.showpop1(text1, text2, text3)}
                  cartNumber={this.cartNumber.bind(this)}
                />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 1020 }}>
            <Element name="section-19" className="page-section page-section-m" id="page19">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 19)}
                partialVisibility={true}
                offset={{ top: 50, bottom: 50 }}
              >
                <Accessories />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 2420 }}>
            <Element name="section-20" className="page-section page-section-m" id="page20">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 20)}
                partialVisibility={true}
                offset={{ top: 50, bottom: 50 }}
              >
                <Gallery />
              </VisibilitySensor>
            </Element>
          </div>
          <div className="component component-m" style={{ height: 1060 }}>
            <Element name="section-21" className="page-section page-section-m" id="page21">
              <VisibilitySensor
                onChange={this.onChangeVisibility.bind(this, 21)}
                partialVisibility={true}
                offset={{ top: 50, bottom: 50 }}
              >
                <Footer />
              </VisibilitySensor>
            </Element>
          </div>

          <div className="turnDeviceNotification" />
        </div>
      </div>
    );
  }

  render() {
    return isMobile() ? this.renderMobile() : this.renderDesktop();
  }

  cartNumber(cartNumber) {
    this.setState({
      cartNumber: cartNumber
    });
  }

  showpop(text1, text2, text3) {
    this.setState({
      showpop: true,
      text1,
      text2,
      text3
    });
  }

  showpop1(text1, text2, text3) {
    this.setState({
      showpop1: true,
      text1,
      text2,
      text3
    });
  }
}

const injectScroll = WrappedComponent => props =>
  isMobile() ? (
    <WrappedComponent {...props} />
  ) : (
    <ReactFullpage
      navigation
      sectionSelector=".fullpage-section"
      render={({ fullpageApi }) => <WrappedComponent {...props} fullpageApi={fullpageApi} />}
    />
  );
export default injectScroll(HomePageContent);
