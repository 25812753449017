import React from 'react';
import { injectIntl } from 'react-intl';
import '../css/mobileService.css';
import '../css/slide.css';

class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: document.documentElement.clientWidth
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.resize);
  }
  resize = () => {
    this.setState({
      width: document.documentElement.clientWidth
    });
  };
  //		this.ban();
  componentDidMount = () => {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      //      this.autoPlay()
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.engaged !== this.props.engaged && this.video) {
      if (this.props.engaged) {
        this.video.play()
      } else {
        //this.video.currenTime = 0;
      }
    }
  }
  autoPlay = () => {
    document.getElementById('mobileServiceVideo').play();
  };

  render() {
    const { intl } = this.props;
    let servicelorem = intl.formatMessage({ id: 'servicelorem' });
    let serviceall = intl.formatMessage({ id: 'serviceall' });
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile-ma">
        <div className="mobile-service" id="mobileService" style={{ paddingBottom: 37 }}>
          <div className="mobile-service-icon">
            <img src={require('../image/home/stk_logo_build_service_button@3x@3x.png')} alt="Build Service Logo" />
          </div>
          <div className="mobile-service2-slide">
            <div className="mobile-service2-slide-inner">
              <video
                autoPlay="true"
                loop="true"
                muted="true"
                playsInline="true"
                id="mobileService1Video"
                //src={require('../video/servicem.mp4')}
                src="https://stk-resource.s3-ap-northeast-1.amazonaws.com/videos/servicem.mp4"
                style={{ width: '100%', height: '100%' }}
                type="video/mp4"
              />
            </div>
          </div>
          <div className="mobile-service2-info">
            <p>{servicelorem}</p>
          </div>
          <a onClick={() => this.props.goToSection(17)} className="mobile-service2-click">
            <img src={require('../image/home/hand@3x.png')} alt="Click for Build Service" />
            <span>{serviceall}</span>
          </a>
        </div>
      </div>
    ) : (
      <div className="component first-component">
        <div className="service" id="webService">
          <div className="service-content">
            <div
              className="service-inner"
              style={{
                width:
                  this.state.width > 1439
                    ? this.state.width - 176
                    : this.state.width > 1250
                    ? this.state.width - 176
                    : this.state.width > 800
                    ? this.state.width - 176
                    : '100%',
                marginLeft:
                  this.state.width > 1439
                    ? -(this.state.width - 176) / 2
                    : this.state.width > 1250
                    ? -(this.state.width - 176) / 2
                    : this.state.width > 800
                    ? -(this.state.width - 176) / 2
                    : 0,
                height: this.state.width > 1500 ? 630 : this.state.width > 800 ? 542 : 'auto'
              }}
            >
              <div className="sider">
                <div className="sider-inner">
                  <video
                    ref={ref => this.video = ref}
                    autoPlay="true"
                    loop="true"
                    muted="true"
                    src={require('../video/service.mp4')}
                    style={{ width: this.state.width < 1250 ? '100%' : '100%', height: '100%' }}
                    type="video/mp4"
                  />
                </div>
              </div>
              <div className="service-info">
                <div className="service-info-inner">
                  <div>
                    <img
                      className="service-logo-icon"
                      src={require('../image/home/stk_logo_build_service_button@3x@3x.png')}
                      alt="Build Service Logo"
                    />
                  </div>
                  <p>{servicelorem}</p>
                  <button onClick={() => this.props.goToSection(15)}>
                    <img src={require('../image/home/hand@3x.png')} alt="Click for Build Service" />
                    <span>{serviceall}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Service = injectIntl(Service);
export default Service;
