import React from 'react';
import { injectIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import HeaderNav from './../components/HeaderNav';
import FooterStaticDesktop from './FooterStaticDesktop';
import FooterStaticMobile from './FooterStaticMobile';
import YoutubeEmbed from './../components/YoutubeEmbed';
import '../css/mobileBox.css';
import '../css/mobileShopping.css';
import '../css/gallery.css';
import '../css/instructions.css';
import '../css/main.css';
var webWidthScale = document.documentElement.clientWidth / 1440;
var thiz;
class Instructions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webWidthScale: document.documentElement.clientWidth / 1440,
      webHeight: document.documentElement.clientHeight,
      webWight: document.documentElement.webWight,
      play: false,
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.resize);
  }
  resize() {
    thiz.setState({
      webWidthScale: document.documentElement.clientWidth / 1440,
      webHeight: document.documentElement.clientHeight,
      webWight: document.documentElement.webWight
    });
    webWidthScale = document.documentElement.clientWidth / 1440;
  }

  render() {
    thiz = this;
    const { intl } = this.props;
    let instructionstitle1a = intl.formatMessage({ id: 'instructions.title1a' });
    let instructionstitle1 = intl.formatMessage({ id: 'instructions.title1' });
    let instructionstitle2 = intl.formatMessage({ id: 'instructions.title2' });
    let instructionstitle3 = intl.formatMessage({ id: 'instructions.title3' });
    let instructionstitle4 = intl.formatMessage({ id: 'instructions.title4' });
    let instructionstitle5 = intl.formatMessage({ id: 'instructions.title5' });
    let instructionstitle6 = intl.formatMessage({ id: 'instructions.title6' });
    let instructionstitle7 = intl.formatMessage({ id: 'instructions.title7' });
    let instructionstitle8 = intl.formatMessage({ id: 'instructions.title8' });
    let instructionstitle9 = intl.formatMessage({ id: 'instructions.title9' });
    let instructionstitle10 = intl.formatMessage({ id: 'instructions.title10' });
    let instructionsweathering = intl.formatMessage({ id: 'instructions.weathering' });
    let downloadinstructions = intl.formatMessage({ id: 'downloadinstructions' });

    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile-ma-shopping max-home">
        <HeaderNav selectedItem="instructions" />
        <div className="mobile-shopping" id="mobileShopping">
          <div className="molide-inst-video" ref="molideInstVideo" style={{ width: '100%' }}>
            <div className="mobile-instructions-pack" style={{}}>
              <div style={{ borderWidth: 1, borderColor: '#777', borderStyle: 'solid', position: 'relative' }}>
                <YoutubeEmbed youtubeId="72KYG4PeNIg" />
              </div>
              <h3 style={{}}>{instructionstitle1a}</h3>
              {localStorage.lang == 'en' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Starter_Pack_A1-B2_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : localStorage.lang == 'zh' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Starter_Pack_A1-B2_SC_c.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Starter_Pack_A1-B2_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              )}
            </div>
            <div className="mobile-instructions-pack" style={{}}>
              <div style={{ borderWidth: 1, borderColor: '#777', borderStyle: 'solid', position: 'relative' }}>
                <YoutubeEmbed youtubeId="Y7EkOhAJ6zI" />
              </div>
              <h3 style={{}}>{instructionstitle1}</h3>
              {localStorage.lang == 'en' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_1_Assembly_Instruction_A-J_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : localStorage.lang == 'zh' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_1_Assembly_Instruction_A-J_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_1_Assembly_Instruction_A-J_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              )}
            </div>
            <div className="mobile-instructions-pack" style={{}}>
              <div style={{ borderWidth: 1, borderColor: '#777', borderStyle: 'solid', position: 'relative' }}>
                <YoutubeEmbed youtubeId="CifM7Ejbs9U" />
              </div>
              <h3 style={{}}>{instructionstitle2}</h3>
              {localStorage.lang == 'en' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_2_Assembly_Instruction_A-G_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : localStorage.lang == 'zh' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_2_Assembly_Instruction_A-G_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_2_Assembly_Instruction_A-G_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              )}
            </div>
            <div className="mobile-instructions-pack" style={{}}>
              <div style={{ borderWidth: 1, borderColor: '#777', borderStyle: 'solid', position: 'relative' }}>
                <YoutubeEmbed youtubeId="NGbv6rrdxBc" />
              </div>
              <h3 style={{}}>{instructionstitle3}</h3>
              {localStorage.lang == 'en' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_3_Assembly_Instruction_A-G_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : localStorage.lang == 'zh' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_3_Assembly_Instruction_A-G_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_3_Assembly_Instruction_A-G_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              )}
            </div>
            <div className="mobile-instructions-pack" style={{}}>
              <div style={{ borderWidth: 1, borderColor: '#777', borderStyle: 'solid', position: 'relative' }}>
                <YoutubeEmbed youtubeId="dljr6MUpKqw" />
              </div>
              <h3 style={{}}>{instructionstitle4}</h3>
              {localStorage.lang == 'en' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_4_Assembly_Instruction_A-F_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : localStorage.lang == 'zh' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_4_Assembly_Instruction_A-F_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_4_Assembly_Instruction_A-F_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              )}
            </div>
            <div className="mobile-instructions-pack" style={{}}>
              <div style={{ borderWidth: 1, borderColor: '#777', borderStyle: 'solid', position: 'relative' }}>
                <YoutubeEmbed youtubeId="_1Q37IIkO6Q" />
              </div>
              <h3 style={{}}>{instructionstitle5}</h3>
              {localStorage.lang == 'en' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_5_Assembly_Instruction_A-E_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : localStorage.lang == 'zh' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_5_Assembly_Instruction_A-E_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_5_Assembly_Instruction_A-E_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              )}
            </div>
            <div className="mobile-instructions-pack" style={{}}>
              <div style={{ borderWidth: 1, borderColor: '#777', borderStyle: 'solid', position: 'relative' }}>
                <YoutubeEmbed youtubeId="FLqkM98VOdw" />
              </div>
              <h3 style={{}}>{instructionstitle6}</h3>
              {localStorage.lang == 'en' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_6_Assembly_Instruction_A-E_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : localStorage.lang == 'zh' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_6_Assembly_Instruction_A-E_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_6_Assembly_Instruction_A-E_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              )}
            </div>
            <div className="mobile-instructions-pack" style={{}}>
              <div style={{ borderWidth: 1, borderColor: '#777', borderStyle: 'solid', position: 'relative' }}>
                <YoutubeEmbed youtubeId="wA3U888KrkI" />
              </div>
              <h3 style={{}}>{instructionstitle7}</h3>
              {localStorage.lang == 'en' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_7_Assembly_Instruction_A-F_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : localStorage.lang == 'zh' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_7_Assembly_Instruction_A-F_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_7_Assembly_Instruction_A-F_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              )}
            </div>
            <div className="mobile-instructions-pack" style={{}}>
              <div style={{ borderWidth: 1, borderColor: '#777', borderStyle: 'solid', position: 'relative' }}>
                <YoutubeEmbed youtubeId="iM8jDAODpFw" />
              </div>
              <h3 style={{}}>{instructionstitle8}</h3>
              {localStorage.lang == 'en' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_8_Assembly_Instruction_1-5_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : localStorage.lang == 'zh' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_8_Assembly_Instruction_1-5_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_8_Assembly_Instruction_1-5_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              )}
            </div>
            <div className="mobile-instructions-pack" style={{}}>
              <div style={{ borderWidth: 1, borderColor: '#777', borderStyle: 'solid', position: 'relative' }}>
                <YoutubeEmbed youtubeId="g0Zhpgh4KNg" />
              </div>
              <h3 style={{}}>{instructionstitle9}</h3>
              {localStorage.lang == 'en' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_9_Assembly_Instruction_1-3_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : localStorage.lang == 'zh' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_9_Assembly_Instruction_1-3_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_9_Assembly_Instruction_1-3_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              )}
            </div>
            <div className="mobile-instructions-pack" style={{}}>
              <div style={{ borderWidth: 1, borderColor: '#777', borderStyle: 'solid', position: 'relative' }}>
                <YoutubeEmbed youtubeId="JLY06lDN7Qc" />
              </div>
              <h3 style={{}}>{instructionstitle10}</h3>
              {localStorage.lang == 'en' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_10_Assembly_Instruction_1-6_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : localStorage.lang == 'zh' ? (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_10_Assembly_Instruction_1-6_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              ) : (
                <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_10_Assembly_Instruction_1-6_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
              )}
            </div>
            {localStorage.lang == 'en' ? null : (
            <div className="mobile-instructions-pack" style={{}}>
              <div style={{ borderWidth: 1, borderColor: '#777', borderStyle: 'solid', position: 'relative' }}>
                <YoutubeEmbed youtubeId="g8RolSO_qeM" />
              </div>
              <h3 style={{}}>{instructionsweathering}</h3>
            </div>
            )}
          </div>
          <FooterStaticMobile />
        </div>
      </div>
    ) : (
      <div className="max-home">
        <HeaderNav selectedItem="instructions" />

        <div style={{ paddingTop: 139 * webWidthScale }}>
          <div className="shopping-content" id="shopping-content">
            <div className="inst-video">
              <div className="inst-video-row">
                <div className="inst-video-item">
                  <div className="inst-video-holder inst-video-holder-filled">
                    <YoutubeEmbed youtubeId="72KYG4PeNIg" />
                  </div>
                  <h3>{instructionstitle1a}</h3>
                  {localStorage.lang == 'en' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Starter_Pack_A1-B2_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : localStorage.lang == 'zh' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Starter_Pack_A1-B2_SC_c.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Starter_Pack_A1-B2_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  )}
                </div>
                <div className="inst-video-item">
                  <div className="inst-video-holder inst-video-holder-filled">
                    <YoutubeEmbed youtubeId="Y7EkOhAJ6zI" />
                  </div>
                  <h3>{instructionstitle1}</h3>
                  {localStorage.lang == 'en' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_1_Assembly_Instruction_A-J_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : localStorage.lang == 'zh' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_1_Assembly_Instruction_A-J_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_1_Assembly_Instruction_A-J_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  )}
                </div>
                <div className="inst-video-item">
                  <div className="inst-video-holder inst-video-holder-filled">
                    <YoutubeEmbed youtubeId="CifM7Ejbs9U" />
                  </div>
                  <h3>{instructionstitle2}</h3>
                  {localStorage.lang == 'en' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_2_Assembly_Instruction_A-G_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : localStorage.lang == 'zh' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_2_Assembly_Instruction_A-G_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_2_Assembly_Instruction_A-G_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  )}
                </div>
              </div>
              <div className="inst-video-row">
                <div className="inst-video-item">
                  <div className="inst-video-holder inst-video-holder-filled">
                    <YoutubeEmbed youtubeId="NGbv6rrdxBc" />
                  </div>
                  <h3>{instructionstitle3}</h3>
                  {localStorage.lang == 'en' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_3_Assembly_Instruction_A-G_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : localStorage.lang == 'zh' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_3_Assembly_Instruction_A-G_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_3_Assembly_Instruction_A-G_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  )}
                </div>
                <div className="inst-video-item">
                  <div className="inst-video-holder inst-video-holder-filled">
                    <YoutubeEmbed youtubeId="dljr6MUpKqw" />
                  </div>
                  <h3>{instructionstitle4}</h3>
                  {localStorage.lang == 'en' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_4_Assembly_Instruction_A-F_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : localStorage.lang == 'zh' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_4_Assembly_Instruction_A-F_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_4_Assembly_Instruction_A-F_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  )}
                </div>
                <div className="inst-video-item">
                  <div className="inst-video-holder inst-video-holder-filled">
                    <YoutubeEmbed youtubeId="_1Q37IIkO6Q" />
                  </div>
                  <h3>{instructionstitle5}</h3>
                  {localStorage.lang == 'en' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_5_Assembly_Instruction_A-E_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : localStorage.lang == 'zh' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_5_Assembly_Instruction_A-E_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_5_Assembly_Instruction_A-E_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  )}
                </div>
              </div>
              <div className="inst-video-row">
                <div className="inst-video-item">
                  <div className="inst-video-holder inst-video-holder-filled">
                    <YoutubeEmbed youtubeId="FLqkM98VOdw" />
                  </div>
                  <h3>{instructionstitle6}</h3>
                  {localStorage.lang == 'en' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_6_Assembly_Instruction_A-E_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : localStorage.lang == 'zh' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_6_Assembly_Instruction_A-E_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_6_Assembly_Instruction_A-E_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  )}
                </div>
                <div className="inst-video-item">
                  <div className="inst-video-holder inst-video-holder-filled">
                    <YoutubeEmbed youtubeId="wA3U888KrkI" />
                  </div>
                  <h3>{instructionstitle7}</h3>
                  {localStorage.lang == 'en' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_7_Assembly_Instruction_A-F_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : localStorage.lang == 'zh' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_7_Assembly_Instruction_A-F_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_7_Assembly_Instruction_A-F_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  )}
                </div>
                <div className="inst-video-item">
                  <div className="inst-video-holder inst-video-holder-filled">
                    <YoutubeEmbed youtubeId="iM8jDAODpFw" />
                  </div>
                  <h3>{instructionstitle8}</h3>
                  {localStorage.lang == 'en' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_8_Assembly_Instruction_1-5_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : localStorage.lang == 'zh' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_8_Assembly_Instruction_1-5_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_8_Assembly_Instruction_1-5_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  )}
                </div>
              </div>

              <div className="inst-video-row">
                <div className="inst-video-item">
                  <div className="inst-video-holder inst-video-holder-filled">
                    <YoutubeEmbed youtubeId="g0Zhpgh4KNg" />
                  </div>
                  <h3>{instructionstitle9}</h3>
                  {localStorage.lang == 'en' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_9_Assembly_Instruction_1-3_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : localStorage.lang == 'zh' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_9_Assembly_Instruction_1-3_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_9_Assembly_Instruction_1-3_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  )}
                </div>
                <div className="inst-video-item">
                  <div className="inst-video-holder inst-video-holder-filled">
                    <YoutubeEmbed youtubeId="JLY06lDN7Qc" />
                  </div>
                  <h3>{instructionstitle10}</h3>
                  {localStorage.lang == 'en' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_10_Assembly_Instruction_1-6_EN.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : localStorage.lang == 'zh' ? (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_10_Assembly_Instruction_1-6_SC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  ) : (
                    <a href="https://stk-resource.s3-ap-northeast-1.amazonaws.com/instructions/Pack_10_Assembly_Instruction_1-6_TC.pdf" target="_blank" rel="noopener noreferrer">{downloadinstructions}</a>
                  )}
                </div>
                {localStorage.lang == 'en' ? null : (
                <div className="inst-video-item">
                  <div className="inst-video-holder inst-video-holder-filled">
                    <YoutubeEmbed youtubeId="g8RolSO_qeM" />
                  </div>
                  <h3>{instructionsweathering}</h3>
                </div>
                )}
              </div>
            </div>

            <FooterStaticDesktop />
          </div>
        </div>
      </div>
    );
  }
}
Instructions = withRouter(injectIntl(Instructions));
export default Instructions;
