import React from 'react';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import isMobile from '../utils/isMobile';
import '../css/mobileModelThree.css';
import '../css/mobileModelFive.css';
import '../css/mobileVideo.css';

var pcWidthScale = document.documentElement.clientWidth / 1440;
var mobileWidthScale = document.documentElement.clientWidth / 375;
var mobileHeightScale = document.documentElement.clientHeight / 667;

class ModelThree extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    window.addEventListener('resize', this.resize);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.engaged !== this.props.engaged && this.video) {
      if (this.props.engaged) {
        this.video.play();
      } else {
        this.video.currenTime = 0;
      }
    }
  }

  resize = () => {
    pcWidthScale = document.documentElement.clientWidth / 1440;
    this.setState({
      width: document.documentElement.clientWidth
    });
  };

  render() {
    const { intl } = this.props;
    let modelthreethe = intl.formatMessage({ id: 'modelthreethe' });
    let modelthreeincludes = intl.formatMessage({ id: 'modelthreeincludes' });
    let modelthreejust = intl.formatMessage({ id: 'modelthreejust' });
    return isMobile() ? (
      <div className="mobile-ma">
        <div className="mobile-model3" id="mobileModelTree" style={{ paddingBottom: 37 }}>
          <div className="mobile-model3-video">
            <canvas id="mobile-model3-canvas" />
            <div
              id="mobile-model3-canvas-virtual"
              className={cx({
                'mobile-canvas-virtual': true,
                engaged: this.props.engaged
              })}
            >
              <div className="line t-l" style={{ top: 98 * mobileWidthScale }} />
              <div className="line t-r" style={{ top: 98 * mobileWidthScale }} />
              <div className="line v-l" id="mobile-model3-canvas3" style={{ top: 98 * mobileWidthScale }} />
              <div className="line v-r" id="mobile-model3-canvas4" style={{ top: 98 * mobileWidthScale }} />
              <div className="line b-l" style={{ top: 600 }} />
              <div className="line b-r" style={{ top: 600 }} />
            </div>
            <div className="mobile-model3-video-title">{modelthreethe}</div>
            <div className="mobile-model3-video-info" style={{ top: 600, marginTop: -60 }}>
              <div>
                <img src={require('../image/home/millennium_falcon@3x.png')} alt="External Details Icon" />
              </div>
              <p>
                {modelthreeincludes}
                <br />
                {modelthreejust}
              </p>
            </div>
            <div className="mobile-model5-pic mobile-model31-pic">
              <div className="video-hold">
                <video
                  ref={ref => (this.video = ref)}
                  autoPlay="true"
                  muted="true"
                  playsInline="true"
                  id="mobileModelTreeVideo"
                  //src={require('../video/video4m.mp4')}
                  src="https://stk-resource.s3-ap-northeast-1.amazonaws.com/videos/video4m.mp4"
                  style={{ width: 'auto', height: '100%' }}
                  type="video/mp4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="component first-component">
        <div className="model3">
          <div
            id="pc-model3-canvas-virtual"
            className={cx({
              'canvas-virtual': true,
              engaged: this.props.engaged
            })}
          >
            <div className="line t-l" />
            <div className="line t-r" />
            <div className="line v-l" />
            <div className="line v-r" />
            <div className="line b-l" />
            <div className="line b-r" />
          </div>
          <div style={{ width: '100%', marginBottom: 78 * pcWidthScale }} id="webModelTree">
            <div className="null" />
            <div className="model3-details">
              <span
                style={{ backgroundColor: '#000', position: 'relative', zIndex: 1, paddingLeft: 30, paddingRight: 30 }}
              >
                {modelthreethe}
              </span>
            </div>
            <div className="model3-video1" style={{ height: 530 * pcWidthScale }}>
              <div
                className="model3-video1-info model3-video-info"
                id="model3-video-info1"
                style={{
                  left: 155 * pcWidthScale,
                  opacity: this.props.engaged ? 1 : 0,
                  visibility: this.props.engaged ? 'visible' : 'none'
                }}
              >
                <div className="icon">
                  <img src={require('../image/home/millennium_falcon@3x.png')} alt="External Details Icon" />
                </div>
                <p style={{ width: '50%' }}>
                  {modelthreeincludes}
                  {modelthreejust}
                </p>
                <div
                  className="canvas-line"
                  style={{ left: 0, width: 428 + document.documentElement.clientWidth - 1200 }}
                >
                  <div style={{ right: 0 }} />
                </div>
              </div>
              <div className="model3-video1-1" style={{ width: pcWidthScale * 944, height: pcWidthScale * 528 }}>
                <video
                  ref={ref => (this.video = ref)}
                  autoPlay="true"
                  muted="true"
                  src={require('../video/video4.mp4')}
                  style={{ width: pcWidthScale * 944, height: pcWidthScale * 528 }}
                  type="video/mp4"
                  id="modelThreeVideo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ModelThree = injectIntl(ModelThree);
export default ModelThree;
