import React from 'react';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import isMobile from '../utils/isMobile';
import '../css/mobileModelThree.css';
import '../css/mobileModelFive.css';
import '../css/mobileVideo.css';

var pcWidthScale = document.documentElement.clientWidth / 1440;
var mobileWidthScale = document.documentElement.clientWidth / 375;
var mobileHeightScale = document.documentElement.clientHeight / 667;

class ModelFour extends React.Component {
  componentWillMount = () => {
    window.addEventListener('resize', this.resize);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.engaged !== this.props.engaged && this.video) {
      if (this.props.engaged) {
        this.video.play();
      } else {
        this.video.currenTime = 0;
      }
    }
  }

  resize = () => {
    pcWidthScale = document.documentElement.clientWidth / 1466;
    this.setState({
      width: document.documentElement.clientWidth
    });
  };

  render() {
    const { intl } = this.props;
    let modelfourdynamic = intl.formatMessage({ id: 'modelfourdynamic' });
    let modelfourlanding = intl.formatMessage({ id: 'modelfourlanding' });
    return isMobile() ? (
      <div className="mobile-ma">
        <div className="mobile-model3">
          <div className="mobile-model3-video">
            <div
              id="mobile-model4-canvas-virtual"
              className={cx({
                'mobile-canvas-virtual': true,
                engaged: this.props.engaged
              })}
            >
              <div className="line t-l" style={{ top: 98 * mobileWidthScale }} />
              <div className="line t-r" style={{ top: 98 * mobileWidthScale }} />
              <div className="line v-l" id="mobile-model4-canvas3" style={{ top: 98 * mobileWidthScale }} />
              <div className="line v-r" id="mobile-model4-canvas4" style={{ top: 98 * mobileWidthScale }} />
              {/*<div className="line b-l" style={{top:98*mobileWidthScale+574*mobileHeightScale-98*(mobileWidthScale-1)}}></div>
              <div className="line b-r" style={{top:98*mobileWidthScale+574*mobileHeightScale-98*(mobileWidthScale-1)}} ></div>*/}
              <div className="line b-l" style={{ top: 600 }} />
              <div className="line b-r" style={{ top: 600 }} />
            </div>
            <div className="mobile-model3-video-title">{modelfourdynamic}</div>
            <div className="mobile-model3-video-info mobile-model4-video-info" style={{ top: 600, marginTop: -60 }}>
              <div>
                <img src={require('../image/home/lighting_icon copy 3@3x.png')} alt="Ramp Icon" />
              </div>
              <p style={{ width: '50%' }}>{modelfourlanding}</p>
            </div>
            <div className="mobile-model5-pic">
              <video
                ref={ref => (this.video = ref)}
                autoPlay="true"
                muted="true"
                playsInline="true"
                id="mobileModelFourVideo"
                //src={require('../video/model4-video1m.mp4')}
                src="https://stk-resource.s3-ap-northeast-1.amazonaws.com/videos/model4-video1m.mp4"
                style={{ width: 'auto', height: '100%' }}
                type="video/mp4"
              />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="component first-component">
        <div className="model3">
          <div
            id="pc-model4-canvas-virtual"
            className={cx({
              'canvas-virtual': true,
              engaged: this.props.engaged
            })}
          >
            <div className="line t-l" />
            <div className="line t-r" />
            <div className="line v-l" />
            <div className="line v-r" />
            <div className="line b-l" />
            <div className="line b-r" />
          </div>
          <div style={{ width: '100%' }} id="webModelFour">
            <div className="null" />
            <div className="model3-details">
              <span
                style={{ backgroundColor: '#000', position: 'relative', zIndex: 1, paddingLeft: 30, paddingRight: 30 }}
              >
                {modelfourdynamic}
              </span>
            </div>
            <div className="model3-video1" style={{ height: 530 * pcWidthScale }}>
              <div
                className="model3-video1-info model4-video1-info model4-video-info model4-video-adjust"
                id="model4-video-info1"
                style={{
                  left: 155 * pcWidthScale,
                  opacity: this.props.engaged ? 1 : 0,
                  visibility: this.props.engaged ? 'visible' : 'none'
                }}
              >
                <div className="icon">
                  <img src={require('../image/home/lighting_icon copy 3@3x.png')} alt="Ramp Icon" />
                </div>
                <p style={{ width: '50%' }}>{modelfourlanding}</p>
                <div
                  className="canvas-line"
                  style={{ left: 0, width: 428 + document.documentElement.clientWidth - 1200 }}
                >
                  <div style={{ right: 0 }} />
                </div>
              </div>
              <div className="model3-video1-1">
                <video
                  ref={ref => (this.video = ref)}
                  autoPlay="true"
                  muted="true"
                  src={require('../video/model4-video1.mp4')}
                  style={{ width: pcWidthScale * 944, height: pcWidthScale * 470 }}
                  type="video/mp4"
                  id="modelFourVideo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ModelFour = injectIntl(ModelFour);
export default ModelFour;
