import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import HeaderNav from './../components/HeaderNav';
import FooterStaticDesktop from './FooterStaticDesktop';
import FooterStaticMobile from './FooterStaticMobile';
import * as request from '../apiClient/request';
import '../css/mobileBox.css';
import '../css/mobileShopping.css';
import '../css/gallery.css';
import '../css/instructions.css';
import '../css/tc.css';
import '../css/main.css';
var webWidthScale = document.documentElement.clientWidth / 1440;
var thiz;

class UseOfWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webWidthScale: document.documentElement.clientWidth / 1440,
      webHeight: document.documentElement.clientHeight,
      webWight: document.documentElement.webWight,
      play: false,
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.resize);
  }
  resize() {
    thiz.setState({
      webWidthScale: document.documentElement.clientWidth / 1440,
      webHeight: document.documentElement.clientHeight,
      webWight: document.documentElement.webWight
    });
    webWidthScale = document.documentElement.clientWidth / 1440;
  }
  componentDidMount() {
    localStorage.setItem('useofwebsite', true);
  
    var lang = localStorage.lang;
    if (!lang) { lang = 'en'}
    if (lang == 'hk') { lang = 'zh-hk' }
    if (lang == 'tw') { lang = 'zh-tw' }
    if (lang == 'cn') { lang = 'zh-cn' }

    request
      .get('page/content?page=notice_on_use_of_website&language=' + lang )
      .then(res => {
        console.log('res');
        console.log(res);
        this.setState({
          data: res
        });
      })
      .catch(err => {
        let str = JSON.stringify(err);
        console.log('err2');
        console.log(err);
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          this.push('/btmf/');
        }
      });
  }

  htmlWrapper(text) { return {__html: text}; };

  isMobile() {
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
  }
  
  render() {
    thiz = this;
    const { intl } = this.props;

    let useOfWebsite = intl.formatMessage({ id: 'useOfWebsite' });

    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile-ma-shopping max-home">
        <HeaderNav />
        <div className="tc" style={{ paddingTop: 80, paddingLeft: 20, paddingRight: 20 }}>
          <h2 className="title">{useOfWebsite}</h2>
          <div className="content">
            <div dangerouslySetInnerHTML={this.htmlWrapper(this.state.data)} />
          </div>
         </div>

        <FooterStaticMobile />
      </div>
    ) : (
      <div className="max-home">
        <HeaderNav />

        <div className="" style={{ height: this.state.webHeight, overflow: 'scroll' }}>
          <div className="instructions" id="webGallery">
            <div
              className="null"
              id="null"
              style={{ minHeight: this.isMobile() ? 60 : 104, height: 139 * webWidthScale, maxHeight: 150 }}
            />
            <div className="shopping-content" id="shopping-content">
              <div className="tc" style={{ width: 940 * this.state.webWidthScale }}>
                <h2 className="title">{useOfWebsite}</h2>
                <div className="content">
                  <div dangerouslySetInnerHTML={this.htmlWrapper(this.state.data)} />
                </div>
              </div>
              <FooterStaticDesktop />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
UseOfWebsite = withRouter(injectIntl(UseOfWebsite));
export default UseOfWebsite;
