import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import '../css/mobileBox.css';
import * as request from '../apiClient/request';
var time_end = new Date('2018/12/26 00:00:00').getTime();
var time_now = new Date().getTime();
var pcWidthScale = document.documentElement.clientWidth / 1466;
const offer1regprice = 4480;
//const offer1price = 3980;
const offer1price = 4480;
class Box extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: document.documentElement.clientWidth,
      time_distance: time_end - time_now,
      int_day: 0,
      int_hour: 0,
      int_minute: 0,
      int_second: 0,
      onSale: false
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.resize);
  }
  resize = () => {
    pcWidthScale = document.documentElement.clientWidth / 1466;
    this.setState({
      width: document.documentElement.clientWidth
    });
  };
  componentDidMount() {
    this.show_time();
  }
  addCart(key) {
    const { intl } = this.props;
    let subscriptionmorethanone1 = intl.formatMessage({ id: 'subscriptionmorethanone1' });
    let subscriptionmorethanone2 = intl.formatMessage({ id: 'subscriptionmorethanone2' });
    let subscriptionmorethanone3 = intl.formatMessage({ id: 'subscriptionmorethanone3' });

    let alert3 = intl.formatMessage({ id: 'alert3' });
    let alert4 = intl.formatMessage({ id: 'alert4' });
    let alert5 = intl.formatMessage({ id: 'alert5' });
    let alert6 = intl.formatMessage({ id: 'alert6' });
    let orderconflictnote = intl.formatMessage({ id: 'orderconflictnote' });
    let orderconflictnote2 = intl.formatMessage({ id: 'orderconflictnote2' });
    let alert7 = intl.formatMessage({ id: 'alert7' });
    let data = {
      shopping_cart_key: localStorage.cartKey,
      product_key: key
    };
    request
      .post('api/v1/shopping_carts/add_product_to_shopping_cart', data, 1, 22)
      .then(res => {
        //console.log(res);
        localStorage.setItem('cartItem', JSON.stringify(res.data.response.shopping_cart_items));
        // alert(alert7);
        if (this.props.history.location.pathname !== '/btmf/cart1') this.props.history.push('/btmf/cart1');
        var cartItem = JSON.parse(localStorage.cartItem);
        this.props.cartNumber(cartItem.length);
      })
      .catch(err => {
        console.log('err');
        console.log(err);
        let str = JSON.stringify(err);
        console.log('err2');

        console.log(err);
        console.log(err.response);

        if (err.response && err.response.status === 403) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop(alert3, alert4, orderconflictnote, orderconflictnote2);
          } else {
            this.props.showpop(alert5, alert6, orderconflictnote, orderconflictnote2);
          }
        }

        if (err.response && err.response.status === 400) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop(
              subscriptionmorethanone1,
              subscriptionmorethanone2,
              subscriptionmorethanone3,
              orderconflictnote2
            );
          }
          // alert('111')
        }
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          this.push('/btmf/');
          // alert('111')
        }
      });
  }
  addCart1(key) {
    const { intl } = this.props;
    let subscriptionmorethanone1 = intl.formatMessage({ id: 'subscriptionmorethanone1' });
    let subscriptionmorethanone2 = intl.formatMessage({ id: 'subscriptionmorethanone2' });
    let subscriptionmorethanone3 = intl.formatMessage({ id: 'subscriptionmorethanone3' });
    let alert3 = intl.formatMessage({ id: 'alert3' });
    let alert4 = intl.formatMessage({ id: 'alert4' });
    let alert5 = intl.formatMessage({ id: 'alert5' });
    let alert6 = intl.formatMessage({ id: 'alert6' });
    let orderconflictnote = intl.formatMessage({ id: 'orderconflictnote' });
    let orderconflictnote2 = intl.formatMessage({ id: 'orderconflictnote2' });
    let alert7 = intl.formatMessage({ id: 'alert7' });
    let data = {
      shopping_cart_key: localStorage.cartKey,
      product_key: key
    };
    request
      .post('api/v1/shopping_carts/add_product_to_shopping_cart', data, 1, 22)
      .then(res => {
        //console.log(res);
        localStorage.setItem('cartItem', JSON.stringify(res.data.response.shopping_cart_items));
        // alert(alert7)
        if (this.props.history.location.pathname !== '/btmf/cart1') this.props.history.push('/btmf/cart1');
        var cartItem = JSON.parse(localStorage.cartItem);
        this.props.cartNumber(cartItem.length);
      })
      .catch(err => {
        console.log('err');
        console.log(err);
        let str = JSON.stringify(err);
        console.log('err2');

        console.log(err);
        console.log(err.response);

        if (err.response && err.response.status === 403) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop1(alert3, alert4, orderconflictnote, orderconflictnote2);
          } else {
            this.props.showpop1(alert5, alert6, orderconflictnote, orderconflictnote2);
          }
          // alert('111')
        }
        if (err.response && err.response.status === 400) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop(
              subscriptionmorethanone1,
              subscriptionmorethanone2,
              subscriptionmorethanone3,
              orderconflictnote2
            );
          }
          // alert('111')
        }
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          this.push('/btmf/');
          // alert('111')
        }
      });
  }
  show_time() {
    time_now = new Date().getTime();
    this.setState({
      time_distance: time_end - time_now
    });
    if (this.state.time_distance > 0) {
      this.setState({
        int_day: Math.floor(this.state.time_distance / 1000 / 60 / 60 / 24),
        int_hour: Math.floor((this.state.time_distance / 1000 / 60 / 60) % 24),
        int_minute: Math.floor((this.state.time_distance / 1000 / 60) % 60),
        int_second: Math.floor((this.state.time_distance / 1000) % 60)
      });
      setTimeout(() => this.show_time(), 1000);
    }
  }

  render() {
    const { intl } = this.props;
    let boxcountdown = intl.formatMessage({ id: 'boxcountdown' });
    let boxmobiledetailtitle = intl.formatMessage({ id: 'boxmobiledetailtitle' });
    let boxmobiledetailcontent1 = intl.formatMessage({ id: 'boxmobiledetailcontent1' });
    let boxmobiledetailcontent2 = intl.formatMessage({ id: 'boxmobiledetailcontent2' });
    let boxmobiledetailcontent3 = intl.formatMessage({ id: 'boxmobiledetailcontent3' });
    let boxmobiledetailcontent4 = intl.formatMessage({ id: 'boxmobiledetailcontent4' });
    let boxmobiledetailcontent5 = intl.formatMessage({ id: 'boxmobiledetailcontent5' });
    let boxmobiledetailcontent6 = intl.formatMessage({ id: 'boxmobiledetailcontent6' });
    let boxregularprice = intl.formatMessage({ id: 'boxregularprice' });
    let boxbuynow = intl.formatMessage({ id: 'boxbuynow' });
    let boxfree = intl.formatMessage({ id: 'boxfree' });
    let boxdo = intl.formatMessage({ id: 'cart.cart8' });
    let boxdo1 = intl.formatMessage({ id: 'cart.cart9' });
    let boxbatteries = intl.formatMessage({ id: 'boxbatteries' });
    let boxpcday = intl.formatMessage({ id: 'boxpcday' });
    let boxpchour = intl.formatMessage({ id: 'boxpchour' });
    let boxpcminute = intl.formatMessage({ id: 'boxpcminute' });
    let boxpcsecond = intl.formatMessage({ id: 'boxpcsecond' });
    let installmentavailable = intl.formatMessage({ id: 'installmentavailable' });

    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile-ma">
        <div className="mobile-box" id="mobileBox" style={{ paddingBottom: 37 }}>
          {/* <div className="mobile-box-countdown">
				 		<div>
					 		<h2>{boxcountdown}</h2>
					 		<ul className="mobile-time-list">
					 			<li>
					 				<span>{this.state.int_day<10?'0'+this.state.int_day:this.state.int_day}</span>
					 				<span>{boxmobileday}</span>
					 			</li>
					 			<li>
					 				<span>{this.state.int_hour<10?'0'+this.state.int_hour:this.state.int_hour}</span>
					 				<span>{boxmobilehour}</span>
					 			</li>
					 			<li>
					 				<span>{this.state.int_minute<10?'0'+this.state.int_minute:this.state.int_minute}</span>
					 				<span>{boxmobileminute}</span>
					 			</li>
					 			<li>
					 				<span>{this.state.int_second<10?'0'+this.state.int_second:this.state.int_second}</span>
					 				<span>{boxmobilesecond}</span>
					 			</li>
					 		</ul>
				 		</div>
				 	</div> */}
          <div className="mobile-box-video">
            {/* <img src={require("../video/box-pic.jpg")} alt="Millennium Falcon" /> */}
          </div>
          <div className="mobile-box-details">
            <div className="mobile-box-details-title">
              <h1>{boxmobiledetailtitle}</h1>
            </div>
            <h4>
              {boxmobiledetailcontent1}
              <br />
              {boxmobiledetailcontent2}
              <br />
              {boxmobiledetailcontent3}
              <br />
              {boxmobiledetailcontent4}
              <br />
              {boxmobiledetailcontent6}
              <br />
              {boxmobiledetailcontent5}
            </h4>
            {this.state.onSale ? (<p>{boxregularprice}{offer1regprice}</p>) : <br />}
            <a onClick={() => this.addCart1('build_yourself_kit')}>{boxbuynow}{offer1price}</a>
            <h5>{installmentavailable}<br /><br />{boxfree}</h5>
            <div className="mobile-box-build" onClick={() => this.props.goToSection(16)}>
              <img src={require('../image/home/stk_logo_build_service_button@3x@3x.png')} alt="Build Service Logo" />
              <p>
                {boxdo}
                <br />
                {boxdo1}
              </p>
            </div>
            <span>{boxbatteries}</span>
          </div>
        </div>
      </div>
    ) : (
      <div className="component first-component" style={{ overflow: 'hidden' }}>
        <div className="box" id="webBox">
          <div className="box-pic" style={{ width: pcWidthScale * 796, height: pcWidthScale * 545 }}>
            <img src={require('../video/box-pic.jpg')} alt="Millennium Falcon" />
          </div>
          <div
            className="box-content"
            style={{
              width: this.state.width > 1439 ? this.state.width - 209 : this.state.width > 900 ? 1230 : 100 + '%'
            }}
          >
            <div className="box-details">
              <div className="title">
                <h1>{boxmobiledetailtitle}</h1>
              </div>
              <h4>
                {boxmobiledetailcontent1}
                <br />
                {boxmobiledetailcontent2}
                <br />
                {boxmobiledetailcontent3}
                <br />
                {boxmobiledetailcontent4}
                <br />
                {boxmobiledetailcontent6}
                <br />
                {boxmobiledetailcontent5}
              </h4>
              {this.state.onSale ? (<p>{boxregularprice}{offer1regprice}</p>) : <br />}
              <button onClick={() => this.addCart('build_yourself_kit')}>{boxbuynow}{offer1price}</button>
              <div className="build">
                <h4>{installmentavailable}<br /><br />{boxfree}</h4>
                <div>
                  <p>
                    {boxdo}
                    <br />
                    {boxdo1}
                  </p>
                  <img
                    onClick={() => this.props.goToSection(14)}
                    style={{ cursor: 'pointer' }}
                    src={require('../image/home/stk_logo_build_service_button@3x@3x.png')}
                    alt="Build Service Logo"
                  />
                </div>
                <span>{boxbatteries}</span>
              </div>
            </div>
          </div>
          <div className="box-countdown">
            <div>{boxcountdown}</div>
            <ul>
              <li>{this.state.int_day > 99 ? Math.floor(this.state.int_day / 100) : 0}</li>
              <li>{this.state.int_day > 9 ? Math.floor((this.state.int_day % 100) / 10) : 0}</li>
              <li>
                {this.state.int_day > 0 ? this.state.int_day % 10 : 0}
                <span>{boxpcday}</span>
              </li>
              <li />
              <li>{this.state.int_hour > 9 ? Math.floor(this.state.int_hour / 10) : 0}</li>
              <li>
                {this.state.int_hour > 0 ? Math.floor(this.state.int_hour % 10) : 0}
                <span>{boxpchour}</span>
              </li>
              <li>:</li>
              <li>{this.state.int_minute > 9 ? Math.floor(this.state.int_minute / 10) : 0}</li>
              <li>
                {this.state.int_minute > 0 ? Math.floor(this.state.int_minute % 10) : 0}
                <span>{boxpcminute}</span>
              </li>
              <li>:</li>
              <li>{this.state.int_second > 9 ? Math.floor(this.state.int_second / 10) : 0}</li>
              <li>
                {this.state.int_second > 0 ? Math.floor(this.state.int_second % 10) : 0}
                <span>{boxpcsecond}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
Box = withRouter(injectIntl(Box));
export default Box;
