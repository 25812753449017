const zhTW = {
  'cart.cart1': '購物車',
  'cart.cart2': '送貨資料',
  'cart.cart3': '付款方法',
  'cart.cart4': '產品',
  'cart.cart5': '數量',
  'cart.cart6': '價錢',
  'cart.cart7': '刪除產品',
  'cart.cart8': '不想自己動手組裝?',
  'cart.cart9': '讓我們的模型大師來幫你!',
  'cart.cart10': '送貨費用',
  'cart.cart11': '免費',
  'cart.cart12': '總計',
  'cart.cart13': '預計交貨日期',
  'cart.cart14': '前往結帳',
  'cart.cart15': '個人資料',
  'cart.cart16': '買家名字',
  'cart.cart17': '買家姓氏',
  'cart.cart18': '你的電郵地址',
  'cart.cart19': '再次輸入電子郵件',
  'cart.cart20': '我們將發送您的訂單確認和SF快遞跟蹤號碼到這個電子郵件地址',
  'cart.emaildisclaimer': '我們會把所有的訂單通知和追踪號碼發送到此電郵地址。',
  'cart.mobiledisclaimer': '如交付時遇到任何問題, 我們會聯絡此電話號碼',
  'cart.passworddisclaimer': '登記帳戶來查看您的訂單，接收免費禮品等資訊。 （可選的）',
  'cart.cart21': '收件地址',
  'cart.cart22': '收件人',
  'cart.cart23': '請填寫詳細地址',
  'cart.cart24': '地址二',
  'cart.cart25': '城市',
  'cart.cart26': '國家',
  'cart.cart27': '這訂單是禮物',
  'cart.pickupcheckbox': 'Pick up from STK Workshop store, Lai Chi Kok',
  'cart.cart28': '您的信息將包含在產品包裝盒內',
  'cart.cart29': '繼續',
  'cart.cart30': '訂單摘要',
  'cart.cart31': '打包一個完整的駕駛艙和艙',
  'cart.cart32': '數量',
  'cart.cart33': '價格',
  'cart.cart34': '感謝您的選購！',
  'cart.cart35': '您的付款已成功處理。 我們已發送了訂單確認到您的電子郵箱。',
  'cart.cart36': '回到主頁',
  'cart.cart37': '請完成“建立您的帳戶”',
  'cart.cart38': '設置密碼建立帳戶，以便您可以隨時登錄並查看訂單歷史記錄。',
  'cart.cart39': '你的電郵地址',
  'cart.cart40': '我想收到STK Workshop的最新消息，特別優惠，贈品和更多資訊',
  reginfovalid: '請輸入以上有效資料',
  'cart.cart41': '創建帳戶',
  'cart.cart42': '訂單彙總',
  'cart.cart43': '打包一個完整的駕駛艙和艙',
  'cart.cart44': '價格',
  'cart.cart45': '密碼 (最少八個字)',
  'cart.cart46': '所有組裝，並由專業大師級工匠為您組裝',
  'cart.cart47': '取消組裝服務',
  'cart.cart48': '自行組裝套裝',
  paymentdisclaimer: '如您繼續提交訂單, 將被視為您已閱讀、理解、並同意我們的',
  paymentdisclaimer2: '條款與條件',
  paymentdisclaimer3: '及',
  paymentdisclaimer4: '隱私政策。',
  paymentdepositdisclaimer: '我理解並同意此終極模型訂單的訂金是不可退還的。 我亦理解並同意',
  paymentdepositdisclaimer2: '條款和細則，包括取消政策。',
  mustagreedisclaimer: '付款前必須同意我們的條款和細則，包括取消政策。',
  honyangrecurring:
    '請注意：為了讓我們處理預訂訂單，您必須勾選下一頁顯示的“我同意將信用卡信資料保留在金流系統中，以節省下次付款的時間。”這選項來啟用定期付款。',
  'cart.cart61': '付款不成功',
  'cart.cart62': '您的交易無法處理。 沒有從您的帳戶中扣款。 請再試一次。 如果問題仍然存在，請聯繫客戶服務。',
  'cart.cart63': '請返回再次嘗試付款',
  'cart.cart64tc': '期後每個月 ',
  'cart.cart64': ' ',
  'cart.cart64b': ' ',
  'cart.cart64c': '每個月',
  'cart.cart64d': '期後每個月',
  'cart.deposit': '訂金(將不獲退回)',
  'cart.paynow': '馬上支付: ',
  'cart.balancedue': '發貨後餘額付款',
  'cart.discountamt': '折扣',
  'cart.inputdiscount': '輸入優惠券代碼',
  'cart.invaliddiscountcode': '優惠代碼並不適用於購物車',
  'cart.nevermiss': '每期送到府上',
  'cart.freedelivery': '免運費',
  'cart.nocommitment': '無須預先付款',
  'cart.cancelanytime': '可隨時停止預訂',
  'cart.starter01': '以最低價格開始製作您的拍攝電影用模型復刻品',
  'cart.starter02': '如果您決定組裝其餘部份，您可以今天於網站上顯示的價格繼續訂購。',
  'cart.starter03': '完成整套模型後，您可免費獲得訂戶禮物。',
  'cart.starter04': '不想繼續組裝嗎？無需煩惱，什麼都不用做。我們不會再收取任何額外費用。',
  'cart.cart65': '省',
  'cart.cart66': '台灣',
  'cart.cart67': '澳門',
  'cart.cart68': '中國大陸',
  'cart.cart69': '買家聯絡電話',
  'cart.cart70': '收件人電話號碼',
  cartpaybytermcheckbox: '分期付款',
  cartmonthlypayments: '個月付款',
  'cart.pack1disclaimer1': '您的第1期組合包含',
  'cart.pack1disclaimer2': '駕駛艙和內部船艙所需的所有零件，如圖。',
  'cart.pack1disclaimer3': '要以特別折扣價訂購',
  'cart.pack1disclaimer4': '千年鷹號',
  'cart.pack1disclaimer5': ' ',
  'cart.pack1disclaimer6': '完整組合，',
  'cart.pack1disclaimer7': '請點擊這裡訂購系列',
  'cart.depositdisclaimer0': '請注意: 全套收藏版終極模型為客製化商品，不適用於七天鑑賞期。',
  'cart.depositdisclaimer1':
    '終極模型訂單需要先付訂金才能預訂。 我們現在只會先收取預訂訂金。 當產品準備好發貨後，我們會再收取餘額付款。 請注意，預訂訂金是不予退還的 - 如果您在發貨前取消訂單，則不會退還訂金。',
  'cart.depositdisclaimer2': '有關詳細信息，請參閱常見問題。',
  'cart.receive_date': '預計送貨日期：',
  'cart.receive_date_1': ' 至 ',
  'cart.empty1': '你的購物車內沒有貨品。',
  'cart.empty2': ' ',
  'cart.empty3': '繼續購物',
  'cart.continueshopping': '繼續購物',
  'login.login1': '登入帳戶',
  'login.login2': '請輸入您訂單的電子郵箱跟密碼登入',
  'login.login3': '電郵地址',
  'login.login4': '密碼',
  'login.login5': '登 錄',
  'login.login6': '忘記密碼?',
  notyouraccount: '不是您嗎？ ',
  notyouraccount2: '登入其他帳戶。',
  'forgot.forgot1': '登錄時遇到問題？',
  'forgot.forgot2': '請在下面輸入您的電郵地址並重置密碼',
  'forgot.forgot3': '你的電郵地址',
  'forgot.forgot4': '重置密碼',
  'forgot.forgot5': '郵件發送',
  'common.mainleft1': '個人資料',
  'common.mainleft2': '訂單記錄',
  'common.mainleft3': '登出',
  profiledesc1: '更改了的地址和電話號碼將會在下次訂購時顯示在購物車的訂單資料中。',
  profiledesc2: '如果您想更改已訂購商品的送貨地址，請與我們聯絡。',
  profiledesc3: '訂戶可以在其帳戶的“訂閱”頁面上查看他們下次發送的地址。',
  proile1: '個人資料',
  proile2: '你的電郵地址',
  proile3: '密碼',
  proile4: '更改密碼',
  proile5: '當前密碼',
  proile6: '新密碼',
  proile7: '設置新密碼',
  'proile.firstname': '名字',
  'proile.lastname': '姓氏',
  'proile.contactnum': '聯絡電話',
  'proile.address1': '請填寫詳細地址',
  'proile.address2': '地址二',
  'proile.city': '城市',
  'proile.province': '省',
  'proile.country': '國家',
  'proile.save': '保存',
  order1: '訂單記錄',
  order2: '訂單日期',
  order3: '訂單 价格',
  order4: '狀態',
  shippedqty: '寄出數量: ',
  order5: '數量',
  order6: '價格',
  order7: '如果你對訂單有任何疑問，請致電0809-080-838跟我們的客服聯絡, 謝謝。',
  order: '設置',
  'order.created': 'Created',
  'order.confirmed_order': '訂單已確認',
  'order.partially_shipped': '部分商品已寄出',
  'order.shipped': '已寄出',
  'order.payment_failed': '付款不成功',
  ordervalidinfo: '請輸入以上有效資料',
  ordervalidemailconfirm: '您的確認電子郵件地址與您的電子郵件地址不符。',
  passwordlength: '您的密碼至少6個字符',
  invalidlogincredentials: '你輸入的密碼和賬戶名不匹配',
  'order.ref': '訂單編號: ',
  'order.date': '訂單日期: ',
  'order.status': '訂單狀態: ',
  'order.shipto': '送貨地址: ',
  'order.giftmsg': '禮品信息: ',
  'order.ordertotal': '訂單金額: ',
  'order.paytotal': '支付金額: ',
  'order.item': '物品',
  'subscription.pack': '已運送期數: ',
  'subscription.nextshipment': '預計下個送貨日期: ',
  'subscription.shipaddress': '送貨地址: ',
  'subscription.unsub': '取消預訂',
  'subscription.nounsub': '我要完成我的預訂',
  'subscription.plzunsub': '取消我的預訂',
  'subscription.sub': '預訂訂單',
  'subscription.title': '訂閱',
  'subscription.unsubtitle': '取消預訂',
  'subscription.unsubdesc': '您確定要取消此預訂嗎？',
  alert1: '輸入框不能是空的',
  alert2: '找不到',
  alert3: '單項訂單必須與預訂訂單分開下單。',
  alert4: '請先確認您的訂購訂單，然後單獨訂購單件商品。',
  alert5: '預訂訂單必須與單項訂單分開下單。',
  alert6: '請先確認您的單件商品，然後單獨訂購訂購訂單。',
  orderconflictnote: '對於給您帶來的不便，我們深表歉意 - 這是第三方安全支付服務供應商要求確保正確處理費用的要求。',
  orderconflictnote2: '如果您在訂購多件商品時遇到任何問題，請與我們聯繫，我們將盡力提供協助。',
  alert7: '成功',
  alert8: '錯誤',
  subscriptionmorethanone1: '只可訂購一個預訂訂單.',
  subscriptionmorethanone2: '請先完成付款再訂購下一個預訂訂單.',
  subscriptionmorethanone3:
    '對於給您帶來的不便，我們深表歉意 - 這是第三方安全支付服務供應商要求確保正確處理費用的要求。',
  cartremoveitemheader: '刪除產品',
  cartremoveitem: '您確定要從購物車中刪除此商品嗎？',
  cartremoveconfirm: '刪除',
  cartremovecancel: '取消',
  alert9: '你確定要刪除麽？',
  alert10: 'email保持一致',
  alert11: '購物車爲空',
  boxcountdown: '特別優惠倒計時啟動',
  boxmobileday: '天',
  boxmobilehour: '時',
  boxmobileminute: '分',
  boxmobilesecond: '秒',
  boxmobiledetailtitle: '透過第一期，您會得到',
  boxmobiledetailcontent1: '• 駕駛艙和艙體的所有零組件',
  boxmobiledetailcontent2: '• 包含完整的燈光組件*',
  boxmobiledetailcontent3: '• 英語原裝產品指南',
  boxmobiledetailcontent4: '• 中文原裝產品指南',
  boxmobiledetailcontent6: '• 可下載的簡單說明書',
  boxmobiledetailcontent5: '• 全面的組裝說明影片教學',
  boxregularprice: '原價 NT$',
  boxbuynow: '現在購買 NT$',
  boxfree: '台灣地區享免運費服務',
  boxdo: '不想建造?<br/>讓我們的模型大師為你做吧！',
  boxbatteries: '*不含電池',
  boxpcday: '天',
  boxpchour: '時',
  boxpcminute: '分',
  boxpcsecond: '秒',
  guideevery: '每一期內都含一份產品指南',
  guidefeaturing: '內容包括星際大戰中的迷人的花絮，千年鷹號太空船的性能，以及其角色和他們令人精彩的任務。',
  guideyoull: '您將會擁有原裝英文版和官方中文版產品指南。',
  headernavmenu: '目錄',
  headernavlist1: '影片',
  headernavlist2: '產品',
  headernavlist3: '產品內容',
  headernavlist4: '組裝服務',
  headernavlist5: '相簿',
  headernavlist6: '組裝教學',
  headernavlist7: '常見問題',
  headernavlist8: '展示選項',
  headernavlogin: '登入',
  headernavmyaccount: '我的帳戶',
  headernavbuynow: '立刻訂購',
  headeryou: '您的購物車中沒有商品',
  conceptstoretitle: '「打造你的專屬千年鷹號」特展正式開幕啦！',
  conceptstoredesc1: '親身體驗拍攝《星際大戰: 帝國大反擊》電影用的「',
  conceptstoredesc2: '千年鷹號',
  conceptstoredesc3: '」1:1 官方復刻品，重現電影的精緻細節。',
  conceptstoredesc4: '地點: 靠邊走藝術空間',
  conceptstoredesc5: ' ',
  conceptstoredesc6: '特展時間: 2019/12/8(日)~2020/3/15(日)',
  conceptstoredesc7: '時間: 每週一公休',
  conceptstoredesc8: '週二到五為下午二點至晚上九點',
  conceptstoredesc9: '週六為下午一點至晚上九點',
  conceptstoredesc10: '週日為下午一點至晚上七點',
  conceptstoredesc11: ' ',
  homeOfficial: '拍攝電影用模型的1:1比例官方復刻品',
  homeOfficialMobileA: '1:1比例，拍攝電影用',
  homeOfficialMobileB: '模型的官方復刻品',
  homebuynow: '立刻訂購',
  homelearn: '了解更多',
  homeScroll: '向下滾動查看更多',
  modelonewith:
    '星際大戰五部曲：帝國大反擊，拍攝電影道具的官方復刻品，搭配內部照明和電動坡道，預先上漆過的船體零件，可拆式外殼，能一覽無遺地展示內部結構細節，及令人驚嘆的燈光效果。',
  modelonewith2: ' ',
  modelonewith3: ' ',
  imageDisclaimer: '圖中的模型為已上漆及風化的效果。',
  modeltwosame: ' ',
  modeltwothe: '與帝國大反擊電影道具比例相同',
  modeltwomovie: ' ',
  modelthreethe: '造工細緻',
  modelthreeincludes: '重現電影中出現的',
  modelthreejust: '精緻細節',
  modelthreeremovable: '可拆卸外殼欣賞',
  modelthreeview: '內部細節',
  modelthree1removable: '可拆卸外殼欣賞內部細節',
  modelthree1removableM1: '可拆卸外殼欣賞',
  modelthree1removableM2: '內部細節',
  modelfourdynamic: '動態效果',
  modelfourlanding: '著陸指示燈和電動登船坡道',
  modelfourprogrammed: '後方亞光速引擎',
  modelfoursequence: '燈光效果',
  modelfour1programmed: '後方亞光速引擎燈光效果',
  modelfiveinterior: '內部照明',
  modelfivestunning: '令人驚嘆的駕駛艙燈光效果',
  modelfiveworking: '通道，船艙，駕駛艙',
  modelfivehold: '的燈光',
  modelsixpre: '今日就開始打造',
  modelsixdisclaimer1: '*打造千年鷹號太空船',
  modelsixdisclaimer2: '需要共10期組合。詳情請參閱下文了解更多。',
  modelsixpack: '第一期',
  modelsixpackast: '*',
  modelsixpackast2: ': 完整的駕駛艙和內部艙體',
  modelsixpack1: '首期特別版 - 駕駛艙及艙體',
  modelsixpack1star: '第一期',
  modelsixpack1starast: '*',
  modelsixpack1starast2: ': 完整的駕駛艙和內部艙體',
  modelsixpack1a: '首期特別版',
  modelsixpack1b: '駕駛艙及艙體',
  modelsixpack2: 'Subscribe To The Series',
  modelsixregular: '原價 NT$',
  modelsixbuy: '現在購買 NT$',
  servicelorem:
    '您可以自己親手完成模型! 但如果沒有時間，或是不想自己動手的話，可選擇我們的組裝服務，由我們大師級工匠為您組裝和精心仿做出逼真的效果。',
  servicesimply: '只需選擇STK構建服務選項之一 ',
  serviceall: '您只需要點擊即可!',
  shoppingspecial: '特別優惠倒計時啟動',
  shoppingstart: '現在就開始打造你自己的酷炫千年鷹號',
  shoppingbuild: '分期訂購自行組裝\n模型',
  shoppingregular: '原價 NT$',
  shoppingadd: '加到購物車 NT$',
  shoppingthe: '全套收藏版千年鷹號',
  shoppingtheM1: '全套收藏版',
  shoppingtheM2: '千年鷹號',
  shoppingsubscribe: '自行組裝或由大師級工匠為您服務',
  shoppingpack1head: '還未準備好收藏完整的模型嗎？',
  shoppingpack1head2: '從這些選項開始',
  shoppingpack1: '首期特別版',
  shoppingpack1a: '駕駛艙及艙體',
  shopping10: '第一期組合',
  shopping10M: '第一期組合',
  shopping10a: '之後每期 ',
  shopping10a2: ' ',
  shopping10a2b: ' NT$',
  shopping10a2c: '只需 NT$',
  shopping10a2d: ' (共9期)',
  shopping10b: '現省 NT$',
  shopping10c: '可隨時取消訂購',
  shoppingCompleteKitRegPrice: '第一組原價NT$4480',
  shoppingCompleteKitRegPrice2: '第二組~第十組NT$3990',
  shoppingmonth: '第一組原價NT$4480, 第二組~第十組NT$3990',
  shoppingnow: '立刻訂購',
  shoppingcraftsmen: '終極模型─',
  shoppingcraftsmen1: '已完成所有組裝，',
  shoppingcraftsmen2: '並由專業大師進行上色塗裝',
  shoppingcraftsmen3: ' ',
  fullkit01: '完整組裝模型組件',
  fullkit02: '一次過',
  fullkit03: '購買自行組裝\n千年鷹號的',
  fullkit04: '所有組件',
  fullkit05: ' ',
  fullkit06: '節省NT$',
  saveOverPrice: '現省超過 NT$',
  savePrice: '現省 NT$',
  regularPrice: '原價 ',
  twCurrency: 'NT$',
  fullkit07: '加到購物車 NT$',
  trialpack01: '限定推出簡易入門版',
  trialpack02: '先由製作最為人熟悉的駕駛艙開始，',
  trialpack03: '組裝您的拍攝電影用模型復刻品',
  trialpack04: 'NT$',
  installmentavailable: '免息分期計劃適用',
  freegifttitle: '免費訂購禮物',
  freegiftp1: '購買全隻千年鷹號的自行組裝系列*或終極模型，免費得到一套四款手繪迷你星際大戰人物公仔。',
  freegiftdisclaimer1: '*需完成十期訂單',
  freegiftdisclaimer2: '手繪迷你星際大戰人物公仔為非賣品。',
  freegiftdisclaimer3: '仍然有疑問? 請參見常見問題頁面。',
  footernewsletterslogan: '最新消息、特別優惠、活動、贈品和更多資訊',
  footernewslettersloganA: '最新消息、特別優惠、活動、',
  footernewslettersloganB: '贈品和更多資訊',
  footernewslettersloganm: '最新消息、特別優惠、活動、',
  footernewslettersloganm1: '贈品和更多資訊',
  footernewslettersloganm2: ' ',
  footernewsletterslogan2: '登記會員通訊',
  footernewsletteremail: '你的電郵地址',
  footernewsletterbutton: '馬上登記',
  footernewsletterfineprint: '我們不會與其它第三方透露您的電郵地址',
  footerquestions: '如有任何問題',
  footercontactphone: '請致電: 0809-080-838',
  footercontactphone2: '週一到週五服務時間 09:00-18:00，國定假日除外',
  footercontactemail: '或寄送電子郵件至：helpdesk@stk-workshop.tw',
  footercontactwhatsapp: '或可透過LINE與我們聯絡, 官方帳號為 @stkworkshop',
  footercontactonline: '關注我們',
  downloadapp: '下載 Build The Millennium Falcon手機/平板電腦應用程式',
  shoppingwe: '我們很榮幸地向大家介紹屢獲殊榮的國際暢銷產品”千年隼“',
  shoppingofficially: '與De Agostini合作，獲得盧卡斯影業和迪斯尼的官方許可。 ',
  shoppingavailable: '在中國使用',
  shoppingexclusively: '專門',
  shoppingfrom: '從STK車間。',
  shoppingcustomers: '其他國家的客戶，請訪問我們的網站',
  shoppingsite: ' 國際網站',
  shoppingor: '或以電郵聯絡我們',
  shoppingcom: 'info@stkworkshop.com ',
  shopping2018: '2020 STK Workshop Limited。保留所有權利。',
  shoppingterms: '條款和細則',
  shoppingLegal: '法律聲明',
  privacyPolicy: '隱私政策',
  useOfWebsite: '網站使用條款',
  shoppingCompleteServiceDesc: '終極模型─已完成',
  shoppingCompleteServiceDesc2: '所有組裝，並由專業大師級',
  shoppingCompleteServiceDesc3: '工匠為您組裝和精心',
  shoppingCompleteServiceDesc4: '仿做出逼真的效果',
  shoppingOriginalEach: '*原價每期 NT$ ',
  shoppingmodel: '最終的模型 – ',
  shoppingfully: '完全組裝和手繪的大師手工藝',
  shoppingPackOneKitDesc: '組裝駕駛艙和艙體',
  shoppingPackOneKitDesc2: '所需的所有零件',
  shoppingPackOneKitDesc3: ' ',
  shoppingall: 'All the parts you need to assemble the Cockpit and Hold',
  shoppingall1: '組裝駕駛艙和艙體',
  shoppingall2: '所需的所有零件',
  shoppingPackOneServiceDesc: '已完成組裝的',
  shoppingPackOneServiceDesc2: '駕駛艙和艙體',
  shoppingPackOneServiceDesc3: ' ',
  shoppingthe1: '已完成組裝的駕駛艙和艙體',
  shoppingthe1a: '已完成組裝的',
  shoppingthe1b: '駕駛艙和艙體',
  shoppingfree: '所有訂單均可在台灣地區免費送貨！',
  shoppingfree1: '額外優惠: ',
  shoppingfree2: '立刻訂購，台灣地區享',
  shoppingfree3: '免運費',
  shoppingfree4: '服務',
  shoppingfree5: '台灣地區享免運費服務',
  shoppingswapanchor: 'Click here',
  shoppingswap: ' to get The Complete Millennium Falcon instead.',
  shoppingswapanchor2: 'Click here',
  shoppingswap2: ' for Pack One - The Cockpit and Hold only.',
  accessoriestitle: '展示選項',
  accessoriessection01: '你可能會喜歡',
  accessoriessection01a: '我們為您的千年鷹號專門訂製不同的展示選項',
  accessoriessection02: '查看所有產品',
  accessoriessection03: '金屬展示牌-經典款式',
  accessoriessection04: '金屬展示牌-型號規格款式',
  accessoriessection05: '金屬展示牌組合',
  accessoriessection06: '金屬桌上展示架',
  accessoriessection07: '壓克力落地展示架',
  accessoriessection07b: '金屬掛牆架',
  accessoriessection08: '客製化服務 (+NT$200)',
  accessoriessection09: '取消客製化',
  accessoriessection10: '選擇您要的展示名稱',
  accessoriessection11: 'Assembled and Painted by',
  accessoriessection12: 'Modeller',
  accessoriessection13: 'Presented to',
  accessoriessection14: 'Commissioned by',
  accessoriessection15: '<None> There will be no text above your name',
  accessoriessection16: '輸入金屬展示牌上的姓名',
  accessoriessection17: '最多20個英文字母或10個中文字(包括空格)',
  accessoriessection18: '請仔細檢查您的拼寫,下單後不可更改與退換!',
  accessoriessection19: '請選擇',
  accessoriessection20: '請輸入您要的展示名稱!',
  accessoriessection21: '請選擇你要展示的名稱!',
  accessoriessection22: '客製化',
  accessoriessection23: '展示牌上的姓名: ',
  accessoriessection24: '展示名稱: ',
  plaquetxtbanner01: '購買一套兩款即省$390， ',
  plaquetxtbanner02: '以$790選購千歲鷹號模型展示牌任何一款或$1580組合價購買一套兩款， ',
  learnmore: '了解更多',
  moredetail: '了解詳情',
  saleprice: '特價: NT$',
  originalprice: '售價: NT$',
  addtocart: '加入購物車',
  breadcrumbproduct: '產品',
  currency: 'NT$',
  emf1: '總公司:',
  emf2: 'STK Workshop Limited',
  emf3: '地址: 香港九龍荔枝角長順街18號華盛工業大廈3樓A室',
  emf4: '電話: (852) 2744 7799',
  gallerytitle: '相簿',
  'instructions.title1a': '簡易入門版',
  'instructions.title1': '第一期',
  'instructions.title2': '第二期',
  'instructions.title3': '第三期',
  'instructions.title4': '第四期',
  'instructions.title5': '第五期',
  'instructions.title6': '第六期',
  'instructions.title7': '第七期',
  'instructions.title8': '第八期',
  'instructions.title9': '第九期',
  'instructions.title10': '第十期',
  'instructions.weathering': '上色及舊化技巧教學示範',
  comingsoon: '敬請期待',
  downloadinstructions: '下載說明 (PDF)',
  'instructions.desc':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla condimentum condimentum sem ut ullamcorper.',
  loadmore: '加載更多',
  aboutustitle: '關於我們',
  aboutusp1:
    'STK Workshop is a renowned producer of high-quality collectible models developed in co-operation with international brands and licensors for sale in Japan, Europe and the USA. We are proud to bring you a selection of our favourite products at stk-workshop.com, starting with ',
  aboutusp1a: 'Build The Millennium Falcon',
  aboutusp1b: '.',
  aboutusp2:
    'All products at stk-workshop.com are manufactured to the same specifications and quality standards as our export models. Our offers are tailored to give customers maximum choice – from simple, cost-effective build-yourself kit options to ultimate professionally assembled and hand-painted ready to display models. Our management, product development, operations and customer service teams are the same that service our customers in Japan and around the world.',
  aboutusp3:
    'Register for a user account to get new product announcements, special offers, exclusive competitions, free gifts and more. No purchase is necessary and your user account area is also where you can let us know what products you would like to see next. Come join us on our mission to bring the most innovative, interesting and impressive collectible models to China, Hong Kong, Asia and the world.',
  tc: '條款和細則',
  tc01: '此頁面和所指的網站各區，載明您使用我們網站www.stk-workshop.com(「網站」)所需遵守的使用條款與條件(「條款」)。',
  tc02:
    'We suggest you read them carefully before you use the Website or place any order at the Website.  You must confirm that you have          read and          accept these terms before you place any order at the Website.',
  tc03:
    'By using our Website, you indicate that you understand and accept these Terms, Notice of Use of Website, Acceptable Use Policy,           Privacy           Policy and information included in the FAQ section of the Website and that you agree to abide by them. If you do not              agree to these             Terms, please refrain from using our Website. ',
  tc04:
    'These Terms may be updated from time to time. Such changes will not affect any orders you have previously placed with us. ',
  tc10: '1. Information about us ',
  tc11:
    'The Website is operated by STK Workshop (“STK”, “we” or “us”). We are registered in Hong Kong have our registered office              address           at: 3F Wah Shing Industrial Building, 18 Cheung Shun Street, Lai Chi Kok, Kowloon.',
  tc20: '2. Access and Use of the Website',
  tc21:
    'Please refer to the sections: Notice of Use of Website, Acceptable Use Policy, Privacy Policy and the FAQ section of the                  Website.',
  tc22:
    '2.1 If you choose, or you are provided with a user identification code, password or any other piece of information as part of our         security          procedures, you must treat such information as confidential and you must not disclose it to any third party and you              should inform us             immediately if you have any reason to believe that your password has become known to anyone else, or if the              password is being, or is           likely to be, used in an unauthorised manner. You agree to accept responsibility for all activities that         occur under your account or                  password. We have the right to disable any user identification code or password, whether chosen by            you or allocated by us, at any time,          if in our opinion you have failed to comply with any of the provisions of these Terms. ',
  tc30: '3. Your Order ',
  tc31:
    '3.1 Only persons aged 18 years and over, who are legally entitled to do so are permitted to place orders on the Website.                Therefore,       by placing an order, you confirm that (i) you are at least 18 years old; and (ii) you agree to be bound by these             Terms.',
  tc32:
    '3.2 Products may be ordered by clicking on the items you wish to purchase and then following the prompts that will appear               on-screen.       You will have the opportunity to check and if necessary correct any input errors in your order up until the point at         which you submit your          order to us.',
  tc33:
    '3.3 Your order constitutes an offer to us to buy a Product. All orders are subject to acceptance by us. We are not obliged to           accept            your order and may, in our discretion, decline to accept any order. Where we accept your order, we will confirm such             acceptance to you         by sending you an email that confirms that we have accepted your order (Order Confirmation). The contract             between you and us in                relation        to the products ordered (Contract) will only be formed when we send you the Order Confirmation.          We will send you a further       email to notify        you when your goods have been dispatched. ',
  tc34:
    '3.4 The Contract will relate only to those products whose dispatch we have confirmed in the Order Confirmation. We will not be          obliged to        supply any other products which may have been part of your order until the dispatch of such products has been                confirmed in a                separate Order Confirmation. ',
  tc40: '4. Delivery ',

  tc41:
    '4.1 Your order will be fulfilled by the delivery date set out in the Order Confirmation or, if no delivery date is specified, then within 30 days       of the date of the Order Confirmation, unless there are exceptional circumstances. ',

  tc42:
    '4.2 Your order will be delivered to the delivery address you specify when placing your order. Products comprised within the             same order        cannot be delivered to different addresses. ',
  tc50: '5. Territories',
  tc51:
    '5.1 We will deliver orders to addresses in a limited number of countries. Please consult the FAQ sections of the website for            the latest        information. We will not deliver to nor assist in facilitating delivery to any countries other than those listed.',
  tc60: '6. Risk and title ',
  tc61:
    '6.1 Products ordered will be at your risk from the time of delivery. Ownership of the products ordered will also pass to you on         delivery,         provided full payment of all sums due in respect of the products, including any delivery charges, has been                    received. ',
  tc70: '7. Cancellation and Returns',
  tc71:
    '7.1 Except in relation to certain products set out below, you may cancel your Contract at any time before your order is delivered and within 15           working days of receiving delivery from us of your individual pack order or the first pack of your subscription order.',
  tc72:
    '7.2 If you wish to cancel a subscription order you can may do so by informing us up to 15 working days before the dispatch of           your next         scheduled delivery.',
  tc73:
    '7.3 In the event you wish to cancel a Contract, you must clearly inform us, either via the Website or in writing by email                                 to helpdesk@stk-workshop.com, giving us your name, address and order reference.  ',
  tc74: '7.4 You will not have any right to cancel a Contract for the supply of any build services. ',
  tc75:
    '7.5 You have a legal obligation to take reasonable care of the products while they are in your possession. If you fail to               comply            with this obligation, we may have a right to claim the cost of any deterioration from you.',
  tc76:
    '7.6 If you cancel a Contract between us during the period specified in paragraph 7.1, we will process any refund due to you as          soon as           possible and, in any case, within 30 days of the day you have given notice of your cancellation. We will refund the             price of the               product in full, less any deduction to reflect any reduction in the value of the item if this has been caused by           your inappropriate              handling of them. You will be responsible for the cost of returning the products to us.',
  tc80: '8. Prices',
  tc81:
    '8.1 The price of any products will be as quoted on the Website from time to time. Prices and delivery costs are liable to change at any time, but changes will not affect orders in respect of which we have already sent you an Order Confirmation.',
  tc82:
    "8.2 We will check prices before accepting your order but we find that the product was incorrectly priced and the product's correct price at your          order date is less than our stated price at your order date, we will charge the lower amount. If the product's correct price at your order date is          higher than the price stated, we will contact you for your instructions before we accept your order. If we accept and process your order where a          pricing error is obvious and unmistakeable and could reasonably have been recognised by you as a mispricing, we may end the contract, refund you          any sums you have paid and require the return of any goods provided to you. ",
  tc83:
    '8.3 Payments for orders must be received in full before the product is shipped. Payments may be made only by the methods                indicated       in the FAQ section of the Website. ',
  tc90: '9. Faulty products',
  tc91:
    '9.1 If any Product you order is damaged or defective when delivered to you, the price of the Product shall be refunded to you           in full,        including the cost of delivery. If you believe a Product was delivered damaged or faulty, you must inform us in                writing, giving us         your name, address and order reference. Nothing in this section affects your legal right.',
  tc92:
    '9.2 To return a faulty Product, you must package and return the item to us for inspection. We advise that you return the                Product to      us some form of certified mail. Please contact us to find out our return address and how to send your goods back to          us for a refund.',
  tc93: '9.3 In the event that a Product is found to be faulty by us, we will either: ',
  tc93a:
    '(a) subject to availability, replace your Product free of charge and refund your return delivery costs in full; or (b) if we are unable to               replace your Product, provide you with a full refund including your return delivery costs for returning the faulty Product to us.',
  tc94:
    '9.4 In order for us to refund the return delivery costs to you, please provide us with copies of receipts to evidence your              postage         costs (including cost of postage materials) to the address above. If a Product is not found to be faulty by us, we will         not refund your             delivery costs for returning the Product to us. ',
  tc95:
    '9.5 In the event that the reason you consider the product to be defective is due to a missing part(s), you agree that should we         supply            such part(s) to you to the claim will be considered as resolved to your satisfaction and no return shall be necessary            nor any refund           due. ',
  tc100: '10. Product information',
  tc101:
    '10.1 Whilst we have taken reasonable steps to depict products as accurately as possible through the photographs and other               images         featured on the Website, the detailing you see on-screen will depend on your device settings and, as such, may not               exactly reflect the        actual detailing of a Product when you receive it.',
  tc110: '11. Intellectual property rights',
  tc111:
    '11.1 All intellectual property rights in any content on the Website (including text, graphics, software, photographs and other         images,          videos, sound, trade marks and logos) are owned by us or our licensors.',
  tc112:
    '11.2 If you print off, copy or download any part of our site in breach of these Terms, you must not remove any copyright,              trade mark       or other intellectual property right notice(s) from the content.',
  tc113:
    '11.3 Except to the extent expressly set out in these Terms, you are not being given any rights in respect of any intellectual          property        rights owned by us or our licensors. You do not acquire any ownership of, or other rights in relation to, any such             intellectual property        rights by downloading any content from the Website or otherwise.',
  tc120: '12. Our Liability',
  tc121: '12.1 Nothing in these Terms and conditions shall limit or exclude our liability to you: ',
  tc121a: '(a) for death or personal injury caused by our negligence; ',
  tc121b: '(b) for fraudulent misrepresentation; ',
  tc121c:
    '(c) for breach by us of any term to the effect that we have the right to sell and supply the products to you; or (d) for any            other           liability that may not, under Hong Kong law, be limited or excluded.',
  tc121d: '',
  tc122:
    '12.2 Subject to paragraph 12.1, in no event shall we be liable to you for any business losses and any liability we do have for         losses you        suffer arising from any Contract shall not exceed the purchase price of the relevant products (together with any             ancillary charges, for         example for delivery) and is limited to losses that were reasonably foreseeable. Losses are foreseeable          where, at the time your order is         accepted by us, they were reasonably foreseeable as being a likely consequence of any breach           by us of the agreement between us and you.',
  tc123:
    '12.3 We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under         any               Contract that is caused by events outside our reasonable control.',
  tc124:
    '12.4 We only supply the products for domestic and private use. If you use the products for any commercial, business or re-sale         purpose we        will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business           opportunity. ',
  tc130: '13. External links',
  tc131:
    '13.1 Our Website may include links to other websites. We may include these to provide you with access to information and               services          that you might find useful or interesting. We have no control over and are not responsible for the content of these            other websites or            for anything provided by them, and do not guarantee that they will be available at any given time. The fact           that we include links to            such external sites does not imply any endorsement of, or association with, their operators.',
  tc140: '14. General',
  tc141: '14.1 You may not transfer or assign any or all of your rights or obligations under any Contract.',
  tc142:
    '14.2 All notices given by you to us must be given in writing to the address set out at the end of these Terms. We may give              notice to        you at either the email or postal address you provide to us when placing an order.',
  tc143: '14.3 If we fail to enforce any of our rights, that does not result in a waiver of that right.',
  tc144:
    '14.4 If any provision of these Terms is found to be unenforceable, all other provisions will remain unaffected.',
  tc145: '14.5 These Terms may not be varied except with our express written consent.',
  tc146:
    '14.6 These Terms and any document expressly referred to in them represent the entire agreement between us in relation to the           subject           matter of any Contract. ',
  tc147:
    '14.7  These Terms are written in the English Language and may be translated into other languages. In the event of inconsistency between the English version and the translated version of this Notice, the English version shall prevail. ',

  nrdtctitle: ' ',
  nrdtctitle2: ' ',
  nrddesc: ' ',
  nrdtcpayment: ' ',
  nrdtc10: ' ',
  nrdtc11: ' ',
  nrdtc12: ' ',
  nrdtc13: ' ',
  nrdtcshipment: ' ',
  nrdtc20: ' ',
  nrdtc21: ' ',
  nrdtc22: ' ',
  nrdtccancellation: ' ',
  nrdtc30: ' ',
  nrdtc31: ' ',
  nrdtc32: ' ',
  nrdtc33: ' ',

  faq: '常見問題',
  faqpreorder: 'PRE-ORDER',
  faqpreorder1: 'STK Workshop is now accepting orders for ',
  faqpreorder1a: 'Build The Millennium Falcon',
  faqpreorder1b: '.',
  faqpreorder2: 'Manufacturing is underway and current expected delivery dates for orders are;',
  faqpreorder3: 'Pack One for Subscribers: late April 2019',
  faqpreorder4: 'Pack One Kits: early May 2019',
  faqpreorder5: 'Pack One with STK Workshop Build Service: late May 2019',
  faqpreorder6: 'Complete Model with STK Workshop Build Service: October 2019',
  faqpreorder7: 'Once the pre-order period ends, orders will be processed as described below.',
  faqorder: 'ORDERS',
  faq10: 'How to Order',
  faq11:
    'Place an order directly on stk-workshop.com for the best offers and personalised service but you can also find us on Taobao and JD.com',
  faq20: 'When will I receive my order?',
  faq21:
    'When the goods are in stock you can normally expect to receive your order in 1-3 days. Out of stock items or product that require custom assembly          or painting before dispatch will require a longer wait. During the order process, the expected delivery date will be clearly indicated before you           confirm your order. ',
  faq30: 'How can I check my order status?',
  faq31:
    'Once you have completed your order successfully you will receive a confirmation email with full details of your order.',
  faq32a: 'If you have created an account at stk-workshop.com, the status of your order will be displayed as follows; ',
  faq32b: 'Confirmed – your order has been successfully received and is being processed. ',
  faq32c:
    'Shipped – your order has been collected from our warehouse and is en route to you. As soon as a tracking number has been issued by SF Express it          will also be displayed.',
  faq32d:
    'Next Delivery – if you have placed a Subscription order the planned date of your next dispatch will be shown here.',
  faq32e:
    'We recommend creating an account because it also means you’ll get the best offers and most personalised service.',
  faq40: 'Can I buy in a physical store?',
  faq41:
    'At this time the only way to purchase is online but make sure you register an account at stk-workshop.com to be the first to know about any new            opportunities to purchase. ',
  faq41a:
    'STK Workshop will be opening a unique Gallery space in Kowloon soon. Although we’re excited to share the details, due to agreements with licensors we must wait a little longer before announcing. Sign up to our email newsletter and follow us on social media for more news.',
  faq41b: 'If you have made a purchase and created an account, you’ll be the first to receive an invitation to visit.',
  faq50: 'Can I see the product displayed somewhere? ',
  faq51:
    'We are planning special events soon so make sure you register an account at stk-workshop.com and follow us on social media to be the first to              know where and when. ',
  faq60: 'What is STK Build Service?',
  faq61:
    'Wherever you see the STK Workshop Build Service logo we can provide a full assembly and hand-painting service. Whether you want the ultimate               professional version of the product or you just don’t have time to assemble yourself, simply select the Build Service option and our master                modellers will assemble and paint for you. All you have to do is click. ',
  faq62:
    'Please note that STK Workshop Build Service is not available for every product - only where the option is clearly indicated by the orange STK              Workshop Build Service logo.',
  faq70: 'Can I buy all the packs now? Do I have to wait for 10 months to build my model?',
  faq71:
    'At this time we are only shipping each pack on a monthly release schedule. Customers who order the complete STK Workshop Build Service option             will be the first to receive the full model. Thereafter we may offer all packs in one delivery. Make sure you create an account at                         stk-workshop.com to be notified should full kits become available. ',
  faq80: 'Do you accept international orders?',
  faq81:
    'At this time we are accepting orders only for delivery to China, Hong Kong and Macau addresses. Create an account at stk-workshop.com to let us            know where you are and to be notified when we can ship to your country. And feel free to share the stk-workshop website or product information             with your friends and groups – the more requests we receive from customers in your country the sooner we’re likely to be able to service. Plus,            registered account holders will be the first to get notified when we start shipments to more countries and have access to the best early-bird              offers.',
  faq90: 'Can I order by phone?',
  faq91:
    'At this time we are not accepting orders by phone. Please don’t reveal your bank or credit card information to anyone during any phone call to             STK Workshop. ',
  faq100: '10 Can I order by email?',
  faq101:
    'At this time we are not accepting orders by email. Please don’t reveal your bank or credit card information in any email to STK Workshop. ',
  faq110: 'What happens if the item I want is out of stock? ',
  faq111:
    'We make every effort to ensure stocks of every product are available for immediate dispatch but at times a particular item may be temporarily             out of stock. Whenever this happens you will be clearly notified before you place your order. You’ll still be able place an order to ensure you            receive your delivery as soon as stocks are available.',
  faq120: 'Can I order replacement parts?',
  faq121:
    'Although we don’t sell items individually, we understand that sometimes parts can get lost or broken and we want you to be able to complete your           model successfully. Contact our customer service team on 2133 1661 or at helpdesk@stk-workshop.com and we’ll do our best to help.',
  faq130: 'What if I want to cancel my order?',
  faq131: 'Contact our customer service team as soon as possible on 2133 1661 or at helpdesk@stk-workshop.com. ',
  faq132: 'Please note that;',
  faq133:
    '- once an order has been processed for delivery it will not be possible to cancel. The order will be delivered as planned. Please refer to the section on RETURNS & EXCHANGES.',
  faq134: '- we cannot accept cancellations or refunds for STK Build Service products.',
  faq135:
    'If you are a Subscriber you can cancel your subscription up to 15 working days before the dispatch of your next scheduled delivery. Please visit          your account page to notify us of your cancellation. Please note that any special gifts for Subscribers that you have not already received will            also be automatically cancelled.',

  faqsubscriptions: 'SUBSCRIPTIONS',
  faq140: 'What is a Subscription?',
  faq141:
    'Placing a Subscription order simply lets us know that you want to order and receive the next pack of model parts each month at a special                  discounted price. Your recurring order will be charged and fulfilled on a monthly schedule and when the last pack of parts is delivered along              with any special subscriber-only gifts, your Subscription will automatically stop.',
  faq142: 'You can cancel any time before that – please see the Cancelling a Subscription article for full details.',
  faq150: 'What are the benefits of a Subscription?',
  faq151:
    'Ordering a Subscription is the best way to save money, time and trouble. You get the best offers and gifts, and priority delivery. All with no commitment as you can cancel anytime.With a Subscription order you will;',
  faq1510: '	•	save more than 10% compared to the cost of purchasing each pack individually',
  faq1511: '	•	make no long-term advance payment – you are charged monthly before the next pack is shipped',
  faq1512: '	•	receive each subsequent pack automatically – no need to place another order every month ',
  faq1513: '	•	receive delivery first – before those purchasing individually',
  faq1514: '	•	have delivery priority in case of any temporary stock shortage ',
  faq1515: '	•	receive special free gifts only available to Subscribers ',
  faq1516:
    '	•	make no long-term commitment - you can cancel any time. (Please see the Cancelling a Subscription article for full details)',
  faq160: 'Do I have to sign a contract?',
  faq161:
    'No. Placing a Subscription order simply lets us know that you want to receive the next pack of model parts each month. Your recurring order will be fulfilled and when the last pack of parts is delivered along with any special Subscriber-only gifts, your Subscription will automatically stop.',
  faq162: 'And you can cancel any time – please see the Cancelling a Subscription article for full details.',
  faq170: 'When will my card/account be charged?',
  faq171:
    'The first pack in your Subscription will be billed at the time you place your order. Each subsequent payment will occur 2-3 days before the next pack is due for dispatch. The exact date is dependent on when you placed your original order and notified to you in your order confirmation and account area, but each payment/dispatch will be approximately 30 days after the last.',
  faq180: 'Will the price change during my Subscription?',
  faq181:
    'The basic price of the product you purchase directly from STK Workshop as well as the delivery cost will be the same every month until the end of your Subscription. However, should the statutory rate of VAT change between your order date and the date we supply the product the final price of your product may change. We may increase the price to reflect the VAT increase if you have not paid for the product. If you have already paid in full for the product, then the price will not change.',
  faq190: 'Will the shipping be free for every pack?',
  faq191: 'Yes, shipping is free for each subsequent pack in your Subscription until it completes. ',
  faq200: '20 Can I order more than one Subscription?',
  faq201:
    'You can order as many Subscriptions you wish and have each delivered to the same or separate addresses. Shipping is free of charge so multiple Subscription orders do not affect your monthly delivery cost or your statutory rights.',
  faq210: 'Can I temporarily suspend my Subscription?',
  faq211:
    'Unfortunately we are unable to temporarily suspend a Subscription. If you cancel your Subscription you may not be able to restart from the same number and price per pack at a later date. However, if you do have to cancel and decide later to restart please contact us on 2133 1661 or at helpdesk@stk-workshop.com and we will do our best to help.',
  faq220: 'Cancelling a Subscription',
  faq221:
    'We’re sorry you don’t want to complete your model and we hope you’ll let us know why at your account page or by contacting us on 2133 1661 or at helpdesk@stk-workshop.com',
  faq222:
    'You can cancel your Subscription up to 15 working days before the dispatch of your next scheduled delivery. ',
  faq223:
    'Please visit your account page to notify us of your cancellation. Please note that any special gifts for Subscribers that you have not already received will also be automatically cancelled. No further deliveries will be dispatched and you will not be charged any further amount.',
  faq224: 'No amounts for orders already delivered or processed will be refunded.',

  faqbilling: 'BILLING & PAYMENT',
  faq230: 'Accepted Forms of Payment',
  faq231: 'We accept major credit cards, Alipay and WeChat pay. ',
  faq240: 'When is my Card Charged?',
  faq241:
    'You will be billed at the time you place your order. Once we receive confirmation that your payment has been successful your order will be processed immediately. ',
  faq242:
    'For Subscribers, each subsequent payment will occur 2-3 days before the next pack is due for dispatch. The exact date is dependent on when you placed your original order and notified to you in your order confirmation and account area, but each payment/dispatch will be approximately 30 days after the last.',
  faq250: 'How do I update my billing details?',
  faq251:
    'For your security and peace of mind, STK Workshop does not see or store your payment details. All payments are securely processed by AsiaPay Limited. You will be required to input your payment details each time you place an order. ',
  faq252:
    'Subscribers only need to place their order one time and AsiaPay will process recurring payments automatically. If you need to update your details for your recurring Subscription payments please contact us on 2133 1661 or at helpdesk@stk-workshop.com with your order reference number and we’ll give you instructions how to update billing details. Please don’t share any of your payment/card information by email or during a phone call.',
  faq260: 'What if my credit card expires?',
  faq261:
    'If your credit card has expired please contact us on 2133 1661 or at helpdesk@stk-workshop.com and let us know your order reference number with your order reference number and we’ll give you instructions how to update billing details. Please don’t share any of your payment/card information by email or during a phone call.',
  faq270: 'Can I change the payment charge date?',
  faq271:
    'Unfortunately we cannot normally change a payment date as all payments are systemized and handled securely but please contact us on 2133 1661 or at helpdesk@stk-workshop.com and we’ll do our best to help.',
  faq280: 'Is your website secure?',
  faq281:
    'Your personal information submitted online at stk-worksop.com is completely secure. Our best-in-class providers protect the information you send us with secure forms and systems.',
  faq282:
    'Your payment details are not seen or stored by STK Workshop. For your security and peace of mind, all payments are securely processed by AsiaPay Limited.',

  faqshipping: 'SHIPPING',
  faq290: 'How will my order be shipped?',
  faq291:
    'After your order is confirmed our warehouse will immediately process for dispatch and delivery to the respective SF Express hub. SF Express will deliver to the address you specified when you ordered.',
  faq300: '30 What are the shipping costs?',
  faq301:
    'Nothing. All our products include free delivery to addresses in Hong Kong and Macau for orders received at the respective stk-workshop.com sites. ',
  faq310: 'When will my order be shipped?',
  faq311:
    'In most cases your order will be processed within hours of you placing it and should leave the STK Workshop warehouse within a day of receipt. Products that require custom assembly or painting may require a longer period before dispatch. Out of stock items’ dispatch dates will vary case by case but the expected delivery date will be clearly indicated before you confirm your order. ',
  faq320: 'When will my order arrive?',
  faq321:
    'When the goods are in stock you can normally expect to receive your order in 1-3 days. Out of stock items or product that require custom assembly or painting before dispatch will require a longer wait. During the order process, the expected delivery date will be clearly indicated before you confirm your order. ',
  faq330: 'How do I track my order?',
  faq331: 'You can see the current status of your order in your account area at stk-workshop.com.',
  faq332:
    'Once your SF Express tracking number has been issued you’ll receive an email and link from us and you can track your shipment at the SF Express website.',
  faq333:
    'If you did not create an account please wait for the email with your tracking number. And while you wait, why not create an account now so you can enjoy earlier status updates and much more. ',
  faq340: 'Can I ship to a different address?',
  faq341:
    'Yes. During the checkout process you can provide one address for delivery and another for billing. So you can ship to your office or to a friend instead of to your home if you wish.',
  faq350: 'Can I ship to a PO Box?',
  faq351:
    'As SF Express cannot ship to a Post Office box, STK Workshop will not deliver to a PO Box address. If you enter a PO Box address during check out you will be asked to advise an alternative address for delivery.',
  faq360: 'Can I ship as a gift?',
  faq361:
    'Absolutely. We’re happy to help you make someone’s day by sending them such a special gift. During the checkout process you will be able to provide one address for delivery and another for billing. You can even include a short personalised note. The recipient will NOT be notified about the cost of the purchase. ',
  faq362:
    'Note that the delivery details and tracking number will be sent only to the buyer’s email address so the delivery will be a surprise to the recipient unless you pass on the delivery information. So please be aware that extra charges may apply if the goods cannot be delivered in a reasonable time – see the article What happens if I’m not at home when the delivery arrives? ',
  faq370: 'Can I collect my package myself?',
  faq371:
    'At this time we are accepting orders only for delivery to Hong Kong or Macau addresses. Make sure you create an account at stk-workshop.com to be notified when it might be possible to pick up from a physical store or special event location.',
  faq380: 'I gave the wrong delivery address with my order.',
  faq381:
    'Please contact us on 2133 1661 or at helpdesk@stk-workshop.com immediately and we will do our best to help. Be sure to go to your account area at stk.com and update your delivery address details as soon as possible.',
  faq390: 'Can I change my delivery address?',
  faq391:
    'If you already placed your order please contact us on 2133 1661 or at helpdesk@stk-workshop.com immediately and we will do our best to help. Be sure to go to your account area at stk-workshop.com and update your delivery address details as soon as possible.',
  faq392:
    'If you are a subscriber just go to your account area at stk.com and update your delivery address details in good time before your next shipment is due. Contact us on 2133 1661 or at helpdesk@stk-workshop.com if you need to be sure about your next delivery getting to your new address.',
  faq400: 'What happens if I’m not at home when the delivery arrives?',
  faq401:
    "If SF Express has attempted to deliver a parcel that is too large to fit through a letter box and there is nowhere suitable to leave it, they will return it to the local SF Express office. Please look for a 'We tried to deliver' notice which should have the relevant contact details on it. Contact your local SF Express office with those details to arrange a convenient time for delivery.",
  faq402: 'You can also track your package at the SF Express website.',
  faq403:
    'If SF Express are unable to deliver to you because you have not arranged a delivery date with them within a reasonable period of the initial attempt to deliver, or to collect from the local depot yourself, we may charge you for storage costs and any further delivery costs while we will try to contact you to make new arrangements. If we remain unable to contact you or to arrange a suitable delivery or collection we may terminate the agreement, refund to you any amount you may have paid in advance for goods we are yet to dispatch and deduct or charge you appropriate compensation for costs incurred as a result of you not allowing us to deliver the products to you.',
  faq410: 'Additional Shipping Fees',
  faq411:
    'STK Workshop will not be responsible for costs resulting from orders shipped to out of date or incorrect address. Customers will be charged for any return shipping fees due to incorrect delivery address information.',
  faq420: 'Which countries do you deliver to? Do you ship internationally?',
  faq421:
    'At this time we deliver only to a China, Hong Kong or Macau address. Create an account at stk-workshop.com so you can let us know where you are and be notified when we can ship to your country. And feel free to share the stk-workshop website or product information with your friends and groups – the more requests we receive from customers in your country the sooner we’re likely to be able to service. Plus, as always, registered account holders will be the first to know when we start shipments to more countries and will get the very best early-bird offers.',

  faqreturns: 'RETURNS AND EXCHANGES',
  faq430: 'What if I want to return my item? What is your Returns Policy?',
  faq431:
    'STK Workshop direct customers are covered by a three week (15 working day) return window from the date of delivery for any pack purchased individually or the first pack of any subscription order. Simply return your order within 15 working days of receiving the delivery from us, with all elements included, all packaging intact and undamaged. Once the goods are received at our warehouse and the condition verified we will refund your purchase price less any deduction to reflect any reduction in the value of the item if this has been caused by your inappropriate handling of them, i.e. in a way that would not be permitted in a store, such as opening or breaking internal package seals, damage to or removal of any component items or external packaging, etc ',
  faq432:
    'Contact us on 2133 1661 or at helpdesk@stk-workshop.com so we can provide you with the correct return address and a return reference number. ',
  faq433:
    'You will need to pay the shipping fee to return the product to us except in the case of defective items. Please refer to the Who pays the return shipping fees? article for more details.',
  faq434:
    'Please note that STK Workshop will only accept returns as described above for orders originally placed at stk-workshop.com, at STK Workshop owned stores or at promotional events conducted by STK Workshop. If you ordered from other sources, please consult the returns policy from the seller/retailer you purchased it from. If the seller is unable to assist you, contact us on 2133 1661 or at helpdesk@stk-workshop.com and we will do our best to help. However, in any event STK Workshop is unable to issue any refunds for products that were not purchased directly from STK Workshop.',
  faq435:
    'We cannot accept returns nor offer refunds for the second or subsequent packs of a subscription order. If you wish to cancel your subscription to stop receiving future packs, please refer to the Cancelling a Subscription article. ',
  faq436: 'We cannot accept returns or refunds for STK Build Service products.',
  faq437:
    'Any refund amount will be for the benefit of the original purchaser only. STK Workshop will not refund any amount to anyone other than the original purchaser.',
  faq438:
    'All approved refunds will be processed as quickly as possible. STK Workshop will determine the appropriate payment method of refund – which may not be the same as the original method of payment and may include a personal cheque for the benefit of the original purchaser sent to the billing address registered.',
  faq440: 'What if my order is defective, incomplete or damaged? ',
  faq441:
    'STK Workshop products are subject to the strictest standards of quality control – every order is individually inspected before dispatch and will in each case match the original versions approved by licensors. Many component parts are hand-finished or painted, which results in small differences between examples of the same piece received by different customers or as pictured on the STK Workshop website or promotional materials. Such variations are not considered defects.',
  faq442:
    'However, in the rare case your order arrives with defective, missing or damaged parts we will provide replacements. ',
  faq443:
    'Contact us at on 2133 1661 or at helpdesk@stk-workshop.com if you believe your order has arrived in a damaged or defective condition and we’ll discuss with you the appropriate arrangements to receive a replacement and how to have any damaged or defective parts returned to us. ',
  faq444:
    'You will not need to pay the shipping fee to return defective product to us or to receive your replacement item. ',
  faq445:
    'Please note that STK Workshop will only accept returns as described above for orders originally placed at stk-workshop.com, at STK Workshop owned stores or at promotional events conducted by STK Workshop. If you ordered from other sources, please consult the seller/retailer you purchased from. ',
  faq446:
    'Any refund amount will be for the benefit of the original purchaser only. STK Workshop will not refund any amount to anyone other than the original purchaser.',
  faq447:
    'In certain circumstances we may to ask you to return the full pack at our cost and refund your purchase. In such cases, we will discuss with you the appropriate arrangements. STK Workshop will determine the appropriate payment method of any refund – which may not be the same as the original method of payment and may include a personal cheque for the benefit of the original purchaser sent to the billing address registered.',
  faq448:
    'Cosmetic damage to packaging including small creases or bends or dents at the corners are not considered as defects and will not be eligible for replacement. Outer cartons (brown corrugated board boxes) are for protective purposes only and any kind of damage to them is not considered as a defect and will not be eligible for replacement.',
  faq450: 'Who pays the return shipping fees?',
  faq451:
    'If you are exercising your right to change your mind after purchasing any items you will need to cover the cost of shipping them back to us. ',
  faq452:
    'If your order arrived with damaged or defective parts and we are unable to provide you a replacement, as well as refunding the appropriate purchase cost we will assist you in returning them to us at our cost. ',

  faqmodel: 'BUILDING YOUR MODEL',
  faq460: 'Where are the instructions how to build? ',
  faq461:
    'You’ll find comprehensive written instructions inside your box plus full length videos online you can follow along with. Just go to stk-workshop.com/instructions.',
  faq470: 'What if I don’t understand some part of the instructions and still need help?',
  faq471:
    'Just contact us on 2133 1661 or at helpdesk@stk-workshop.com and we’ll answer your questions about building.',
  faq480: 'Do I need any special tools to build?',
  faq481:
    'No. STK products are designed to be easy to build using items that are commonly owned or easily available. Some packs include special tools where required.',
  faq482: 'What is the appropriate age range?',
  faq483: 'Build The Millennium Falcon ',
  faq483a:
    'is not a toy. The kits are designed for adults aged 16 and older. The model includes detailed and delicate parts replicating the original movie prop. The specialist tools and glues supplied should be handled with some care. Assembled kits, including models assembled and painted by the STK Workshop Build Service, include fine, fragile details so we recommend that they be handled by responsible enthusiasts aged 13 and older.',
  faq484:
    'Ability and experience will differ from person to person and some younger modellers may be able to assemble the kits successfully. If you are concerned about the appropriateness for your younger modeller, please review the instruction video before making your purchase decision.',
  faq490: 'Do I need to paint my model? ',
  faq491:
    'The model parts are manufactured in the correct colours or pre-painted so you don’t need to paint your model if you don’t want to.',
  faq492:
    'However the original studio prop of the Millennium Falcon has ‘weathering’ effects that are only possible to replicate once the model is assembled.  If you would like to add the full weathering effects and other special hand-painting touches to your model, it’s not difficult to accomplish after you have completed the relevant assemblies and we’ll provide you with the paints, brushes and instructions. ',
  faq493:
    'You can select one of the STK Workshop Build Service options to have one of our master modellers will assemble and paint your model for you.',
  faq494: '',
  faq495: '',
  faq496: '',
  faq497: '',

  faqtroub: 'TROUBLESHOOTING',
  faq500: 'My delivery doesn’t look like the picture',
  faq501:
    'Images on the website are for illustration only. Product specifications are subject to minor change and variation from images on the website. ',
  faq502: 'Hand-painted parts may naturally vary slightly between different production pieces.',
  faq503:
    'Differences in customers’ device settings will mean that colours displayed may not match appearance of actual products. ',
  faq504:
    'However, you can be assured that all STK Workshop products are subject to the strictest standards of quality control and will in every case match the original versions approved by licensors.',
  faq510: 'I’m having trouble using the website',
  faq511: 'Please contact us on 2133 1661 or at helpdesk@stk-workshop.com',
  faq512:
    'Remember that although we can help you navigate the order and checkout process, our operators cannot place your order for you and you never need to share any of your payment/card information during a phone call or an email conversation.',
  faq520: 'I can’t find the answer to my question in these FAQ',
  faq521: 'Please contact us on 2133 1661 or at helpdesk@stk-workshop.com',

  faqmy: 'MY ACCOUNT',
  faq530: 'Do I need to create an account?',
  faq531:
    'You can complete your order on stk-workshop.com without creating an account. You’ll just need to provide an email address so that we can send you confirmation of your order and a tracking number for your delivery. But from there, you only need to decide a password and you’ve finished creating your account. ',
  faq540: 'What are the benefits of creating an account?',
  faq541:
    'STK Workshop account holders are our VIP customers and your account area is where you’ll find special discounts, pre-order announcements, invitations to events, competitions, free gifts, product giveaways and more. ',
  faq542:
    'Most offers are not advertised anywhere else so be sure to check in regularly to get the latest special offers just for you. Look for the notification icon to know when something new has been added for you. And check the box to receive our short, irregular and relevant email messages.',
  faq550: 'How do I create an account?',
  faq551: 'Just click on the icon and follow the instructions.',
  faq560: 'Can I create an account if I haven’t made a purchase?',
  faq561: 'Absolutely. Just click the icon and follow the instructions.',
  faq570: 'Do I have to subscribe to your mailing list?',
  faq571:
    'It’s not necessary to subscribe to our mailing list. You can visit your account area any time to see the latest news, offers, gifts, etc.',
  faq572:
    'But please note that as many offers, invitations and giveaways are limited in number, they are advertised and allocated on a first come, first served basis. Mailing list readers will usually be the among the first to snap them up first so we suggest you stay signed up. ',
  faq573:
    'We’re anti-spam just like you so you can be assured we’ll keep our emails short, irregular and relevant. And if you really wish to stop receiving them it’s easy to unsubscribe any time. ',
  faq580: 'How do I unsubscribe from your mailing list?',
  faq581:
    'Just visit your account area and deselect the ‘send me email – but not too many’ button or click the unsubscribe link in the email.',

  faqcustomer: 'CUSTOMER SERVICE',
  faq590: 'How can I contact you?',
  faq591:
    'By phone on 2133 1661 during the hours of 09:00 to 18:00 Monday to Friday (except public holidays) and by email anytime at helpdesk@stk-workshop.com.',
  faq592: 'Or you can send us a message on Facebook or WeChat.',

  privacy00: '',
  privacy01: '',
  privacy10: '',
  privacy10a: '',
  privacy11: '',
  privacy12: '',
  privacy121: '',
  privacy122: '',
  privacy123: '',
  privacy124: '',
  privacy125: '',
  privacy126: '',
  privacy127: '',
  privacy128: '',
  privacy129: '',
  privacy1210: '',
  privacy1211: '',
  privacy1212: '',
  privacy1213: '',
  privacy1214: '',
  privacy1215: '',
  privacy1216: '',
  privacy1217: '',
  privacy1218: '',
  privacy1219: '',
  privacy1220: '',
  privacy20: '',
  privacy20a: '',
  privacy21: '',
  privacy211: '',
  privacy212: '',
  privacy213: '',
  privacy214: '',
  privacy215: '',
  privacy216: '',
  privacy30: '',
  privacy30a: '',
  privacy31: '',
  privacy311: '',
  privacy312: '',
  privacy313: '',
  privacy314: '',
  privacy32: '',
  privacy33: '',
  privacy34: '',
  privacy40: '',
  privacy40a: '',
  privacy41: '',
  privacy50: '',
  privacy50a: '',
  privacy51: '',
  privacy52: '',
  privacy60: '',
  privacy60a: '',
  privacy61: '',
  privacy62: '',
  privacy70: '',
  privacy70a: '',
  privacy71: '',
  privacy711: '',
  privacy712: '',
  privacy713: '',
  privacy714: '',
  privacy715: '',
  privacy716: '',
  privacy717: '',
  privacy72: '',
  privacy73: '',
  privacy80: '',
  privacy80a: '',
  privacy81: '',
  privacy90: '',
  privacy90a: '',
  privacy91: '',
  privacy91a: '',
  privacy91b: '',
  privacy91c: '',
  privacy91d: '',
  privacy91e: '',
  privacy91f: '',
  privacy91g: '',
  privacy92: '',
  privacy100: '',
  privacy100a: '',
  privacy101: '',
  privacyDate: '',

  webuse000: '',
  webuse001: '',
  webuse002: '',
  webuse010: '',
  webuse010a: '',
  webuse011: '',
  webuse012: '',
  webuse013: '',
  webuse020: '',
  webuse020a: '',
  webuse021: '',
  webuse022: '',
  webuse023: '',
  webuse030: '',
  webuse030a: '',
  webuse031: '',
  webuse032: '',
  webuse040: '',
  webuse040a: '',
  webuse041: '',
  webuse042: '',
  webuse043: '',
  webuse050: '',
  webuse050a: '',
  webuse051: '',
  webuse052: '',
  webuse053: '',
  webuse060: '',
  webuse060a: '',
  webuse061: '',
  webuse070: '',
  webuse070a: '',
  webuse071: '',
  webuse080: '',
  webuse080a: '',
  webuse081: '',
  webuse082: '',
  webuse090: '',
  webuse090a: '',
  webuse091: '',
  webuse0911: '',
  webuse0912: '',
  webuse0913: '',
  webuse100: '',
  webuse100a: '',
  webuse101: '',
  webuse102: '',
  webuse1021: '',
  webuse1022: '',
  webuse1023: '',
  webuse1024: '',
  webuse1025: '',
  webuse1026: '',
  webuse1027: '',
  webuse1028: '',
  webuse1029: '',
  webuse10210: '',
  webuse10211: '',
  webuse10212: '',
  webuse10213: '',
  webuse10214: '',
  webuse103: '',
  webuse1031: '',
  webuse1032: '',
  webuse1033: '',
  webuse1034: '',
  webuse110: '',
  webuse110a: '',
  webuse111: '',
  webuse120: '',
  webuse120a: '',
  webuse121: '',
  webuse130: '',
  webuse130a: '',
  webuse131: '',
  webuse1311: '',
  webuse1312: '',
  webuse1313: '',
  webuse1314: '',
  webuse1315: '',
  webuse1316: '',
  webuse1317: '',
  webuse1318: '',
  webuse140: '',
  webuse140a: '',
  webuse141: '',
  webuse142: '',
  webuse143: '',
  webuse144: '',
  webuse150: '',
  webuse150a: '',
  webuse151: '',
  webuse152: '',
  webuse153: '',
  webuse154: '',
  webuse160: '',
  webuse160a: '',
  webuse161: '',
  webuse170: '',
  webuse170a: '',
  webuse171: '',
  webuse180: '',
  webuse180a: '',
  webuse181: '',
  webuse190: '',
  webuse190a: '',
  webuse191: '',
  webuse200: '',
  webuse200a: '',
  webuse201: '',
  webuse210: '',
  webuse210a: '',
  webuse211: '',
  webuse220: '',
  webuse220a: '',
  webuse221: '',
  webuse230: '',
  webuse230a: '',
  webuse231: '',
  webuseDate: '',

  'shipmentstatus.status': '货运状态',
  'shipmentstatus.preparingshipment': '準備貨運',
  'shipmentstatus.shipped': '訂單已發貨完成',
  'shipmentstatus.shipmentfailed': '貨運失敗',

  snhBenefit1: '95折優惠',
  snhBenefit2: '宅配免運費',
  snhBenefit3: '可隨時取消訂閱',
  snhBenefit4: '提前收到*',
  snhBenefit5: '不錯過任何一期',
  snhBenefit6: '專屬贈品大型Snoopy玩偶*',

  amcBenefit1: '95折優惠',
  amcBenefit2: '宅配免運費',
  amcBenefit3: '可隨時取消訂閱',
  amcBenefit4: '提前收到*',
  amcBenefit5: '不錯過任何一期',
  amcBenefit6: '贈送SHELBY COBRA',
  amcBenefit7: '6月30日前開始訂閱送您第6期Ford Mustang Boss 429',

  cartBenefitDisclaimer: '* 有關詳細資料，請參閱常見問題。',

  orderStatusCreated: '訂單待請款',
  orderStatusConfirmedOrder: '訂單已確認',
  orderStatusPartiallyShipped: '訂單部分的產品已出貨',
  orderStatusFulfilled: '訂單已發貨完成',
  orderStatusPaymentFailed: '訂單請款失敗',
  orderStatusCancelled: '訂單已取消',
  orderStatusRefunded: '訂單已退款',

  twCity: '市/縣',
  twDistrict: '區',
  postal_code: '郵遞區號',

  'twCity.taipeiCity': '臺北市',
  'twCity.keelungCity': '基隆市',
  'twCity.newTaipeiCity': '新北市',
  'twCity.yilanCounty': '宜蘭縣',
  'twCity.hsinchuCity': '新竹市',
  'twCity.hsinchuCounty': '新竹縣',
  'twCity.taoyuanCity': '桃園市',
  'twCity.miaoliCounty': '苗栗縣',
  'twCity.taichungCity': '臺中市',
  'twCity.changhuaCounty': '彰化縣',
  'twCity.nantouCounty': '南投縣',
  'twCity.chiayiCity': '嘉義市',
  'twCity.chiayiCounty': '嘉義縣',
  'twCity.yunlinCounty': '雲林縣',
  'twCity.tainanCity': '臺南市',
  'twCity.kaohsiungCity': '高雄市',
  'twCity.southChinaSeaIslands': '南海諸島',
  'twCity.penghuCounty': '澎湖縣',
  'twCity.pingtungCounty': '屏東縣',
  'twCity.taitungCounty': '臺東縣',
  'twCity.hualienCounty': '花蓮縣',
  'twCity.kinmenIslands': '金門縣',
  'twCity.lienchiangCounty': '連江縣',

  'twDistrict.zhongzhengDistrict': '中正區',
  'twDistrict.datongDistrict': '大同區',
  'twDistrict.zhongshanDistrict': '中山區',
  'twDistrict.songshanDistrict': '松山區',
  'twDistrict.daAnDistrict': '大安區',
  'twDistrict.wanhuaDistrict': '萬華區',
  'twDistrict.xinyiDistrict': '信義區',
  'twDistrict.shilinDistrict': '士林區',
  'twDistrict.beitouDistrict': '北投區',
  'twDistrict.neihuDistrict': '內湖區',
  'twDistrict.nangangDistrict': '南港區',
  'twDistrict.wenshanDistrict': '文山區',

  'twDistrict.renAiDistrict': '仁愛區',
  'twDistrict.xinyiDistrict': '信義區',
  'twDistrict.zhongzhengDistrict': '中正區',
  'twDistrict.zhongshanDistrict': '中山區',
  'twDistrict.anleDistrict': '安樂區',
  'twDistrict.nuannuanDistrict': '暖暖區',
  'twDistrict.qiduDistrict': '七堵區',

  'twDistrict.wanliDistrict': '萬里區',
  'twDistrict.jinshanDistrict': '金山區',
  'twDistrict.banqiaoDistrict': '板橋區',
  'twDistrict.xizhiDistrict': '汐止區',
  'twDistrict.shenkengDistrict': '深坑區',
  'twDistrict.shidingDistrict': '石碇區',
  'twDistrict.ruifangDistrict': '瑞芳區',
  'twDistrict.pingxiDistrict': '平溪區',
  'twDistrict.shuangxiDistrict': '雙溪區',
  'twDistrict.gongliaoDistrict': '貢寮區',
  'twDistrict.xindianDistrict': '新店區',
  'twDistrict.pinglinDistrict': '坪林區',
  'twDistrict.wulaiDistrict': '烏來區',
  'twDistrict.yongheDistrict': '永和區',
  'twDistrict.zhongheDistrict': '中和區',
  'twDistrict.tuchengDistrict': '土城區',
  'twDistrict.sanxiaDistrict': '三峽區',
  'twDistrict.shulinDistrict': '樹林區',
  'twDistrict.yinggeDistrict': '鶯歌區',
  'twDistrict.sanchongDistrict': '三重區',
  'twDistrict.xinzhuangDistrict': '新莊區',
  'twDistrict.taishanDistrict': '泰山區',
  'twDistrict.linkouDistrict': '林口區',
  'twDistrict.luzhouDistrict': '蘆洲區',
  'twDistrict.wuguDistrict': '五股區',
  'twDistrict.baliDistrict': '八里區',
  'twDistrict.tamsuiDistrict': '淡水區',
  'twDistrict.sanzhiDistrict': '三芝區',
  'twDistrict.shimenDistrict': '石門區',

  'twDistrict.yilan': '宜蘭',
  'twDistrict.toucheng': '頭城',
  'twDistrict.jiaoxi': '礁溪',
  'twDistrict.zhuangwei': '壯圍',
  'twDistrict.yuanshan': '員山',
  'twDistrict.luodong': '羅東',
  'twDistrict.sanxing': '三星',
  'twDistrict.datong': '大同',
  'twDistrict.wujie': '五結',
  'twDistrict.dongshan': '冬山',
  'twDistrict.suAo': '蘇澳',
  'twDistrict.nanAo': '南澳',
  'twDistrict.diaoyutaiIslands': '釣魚臺列嶼',

  'twDistrict.eastDistrict': '東區',
  'twDistrict.northDistrict': '北區',
  'twDistrict.xiangshanDistrict': '香山區',

  'twDistrict.zhubei': '竹北',
  'twDistrict.hukou': '湖口',
  'twDistrict.xinfeng': '新豐',
  'twDistrict.xinpu': '新埔',
  'twDistrict.guanxi': '關西',
  'twDistrict.qionglin': '芎林',
  'twDistrict.baoshan': '寶山',
  'twDistrict.zhudong': '竹東',
  'twDistrict.wufeng': '五峰',
  'twDistrict.hengshan': '橫山',
  'twDistrict.jianshi': '尖石',
  'twDistrict.beipu': '北埔',
  'twDistrict.emei': '峨眉',

  'twDistrict.zhongliDistrict': '中壢區',
  'twDistrict.pingzhenDistrict': '平鎮區',
  'twDistrict.longtanDistrict': '龍潭區',
  'twDistrict.yangmeiDistrict': '楊梅區',
  'twDistrict.xinwuDistrict': '新屋區',
  'twDistrict.guanyinDistrict': '觀音區',
  'twDistrict.taoyuanDistrict': '桃園區',
  'twDistrict.guishanDistrict': '龜山區',
  'twDistrict.badeDistrict': '八德區',
  'twDistrict.daxiDistrict': '大溪區',
  'twDistrict.fuxingDistrict': '復興區',
  'twDistrict.dayuanDistrict': '大園區',
  'twDistrict.luzhuDistrict': '蘆竹區',

  'twDistrict.zhunan': '竹南',
  'twDistrict.toufen': '頭份',
  'twDistrict.sanwan': '三灣',
  'twDistrict.nanzhuang': '南庄',
  'twDistrict.shitan': '獅潭',
  'twDistrict.houlong': '後龍',
  'twDistrict.tongxiao': '通霄',
  'twDistrict.yuanli': '苑裡',
  'twDistrict.miaoli': '苗栗',
  'twDistrict.zaoqiao': '造橋',
  'twDistrict.touwu': '頭屋',
  'twDistrict.gongguan': '公館',
  'twDistrict.dahu': '大湖',
  'twDistrict.taiAn': '泰安',
  'twDistrict.tongluo': '銅鑼',
  'twDistrict.sanyi': '三義',
  'twDistrict.xihu': '西湖',
  'twDistrict.zhuolan': '卓蘭',

  'twDistrict.centralDistrict': '中區',
  'twDistrict.eastDistrict': '東區',
  'twDistrict.southDistrict': '南區',
  'twDistrict.westDistrict': '西區',
  'twDistrict.northDistrict': '北區',
  'twDistrict.beitunDistrict': '北屯區',
  'twDistrict.xitunDistrict': '西屯區',
  'twDistrict.nantunDistrict': '南屯區',
  'twDistrict.taipingDistrict': '太平區',
  'twDistrict.daliDistrict': '大里區',
  'twDistrict.wufengDistrict': '霧峰區',
  'twDistrict.wuriDistrict': '烏日區',
  'twDistrict.fengyuanDistrict': '豐原區',
  'twDistrict.houliDistrict': '后里區',
  'twDistrict.shigangDistrict': '石岡區',
  'twDistrict.dongshiDistrict': '東勢區',
  'twDistrict.hepingDistrict': '和平區',
  'twDistrict.xinsheDistrict': '新社區',
  'twDistrict.tanziDistrict': '潭子區',
  'twDistrict.dayaDistrict': '大雅區',
  'twDistrict.shengangDistrict': '神岡區',
  'twDistrict.daduDistrict': '大肚區',
  'twDistrict.shaluDistrict': '沙鹿區',
  'twDistrict.longjingDistrict': '龍井區',
  'twDistrict.wuqiDistrict': '梧棲區',
  'twDistrict.qingshuiDistrict': '清水區',
  'twDistrict.dajiaDistrict': '大甲區',
  'twDistrict.waipuDistrict': '外埔區',
  'twDistrict.daAnDistrict': '大安區',

  'twDistrict.changhua': '彰化',
  'twDistrict.fenyuan': '芬園',
  'twDistrict.huatan': '花壇',
  'twDistrict.xiushui': '秀水',
  'twDistrict.lukang': '鹿港',
  'twDistrict.fuxing': '福興',
  'twDistrict.xianxi': '線西',
  'twDistrict.hemei': '和美',
  'twDistrict.shengang': '伸港',
  'twDistrict.yuanlin': '員林',
  'twDistrict.shetou': '社頭',
  'twDistrict.yongjing': '永靖',
  'twDistrict.puxin': '埔心',
  'twDistrict.xihu': '溪湖',
  'twDistrict.dacun': '大村',
  'twDistrict.puyan': '埔鹽',
  'twDistrict.tianzhong': '田中',
  'twDistrict.beidou': '北斗',
  'twDistrict.tianwei': '田尾',
  'twDistrict.pitou': '埤頭',
  'twDistrict.xizhou': '溪州',
  'twDistrict.zhutang': '竹塘',
  'twDistrict.erlin': '二林',
  'twDistrict.dacheng': '大城',
  'twDistrict.fangyuan': '芳苑',
  'twDistrict.ershui': '二水',

  'twDistrict.nantou': '南投',
  'twDistrict.zhongliao': '中寮',
  'twDistrict.caotun': '草屯',
  'twDistrict.guoxing': '國姓',
  'twDistrict.puli': '埔里',
  'twDistrict.renAi': '仁愛',
  'twDistrict.mingjian': '名間',
  'twDistrict.jiji': '集集',
  'twDistrict.shuili': '水里',
  'twDistrict.yuchi': '魚池',
  'twDistrict.xinyi': '信義',
  'twDistrict.zhushan': '竹山',
  'twDistrict.lugu': '鹿谷',

  'twDistrict.eastDistrict': '東區',
  'twDistrict.westDistrict': '西區',

  'twDistrict.fanlu': '番路',
  'twDistrict.meishan': '梅山',
  'twDistrict.zhuqi': '竹崎',
  'twDistrict.alishan': '阿里山',
  'twDistrict.zhongpu': '中埔',
  'twDistrict.dapu': '大埔',
  'twDistrict.shuishang': '水上',
  'twDistrict.lucao': '鹿草',
  'twDistrict.taibao': '太保',
  'twDistrict.puzi': '朴子',
  'twDistrict.dongshi': '東石',
  'twDistrict.liujiao': '六腳',
  'twDistrict.xingang': '新港',
  'twDistrict.minxiong': '民雄',
  'twDistrict.dalin': '大林',
  'twDistrict.xikou': '溪口',
  'twDistrict.yizhu': '義竹',
  'twDistrict.budai': '布袋',

  'twDistrict.dounan': '斗南',
  'twDistrict.dapi': '大埤',
  'twDistrict.huwei': '虎尾',
  'twDistrict.tuku': '土庫',
  'twDistrict.baozhong': '褒忠',
  'twDistrict.dongshi': '東勢',
  'twDistrict.taixi': '臺西',
  'twDistrict.lunbei': '崙背',
  'twDistrict.mailiao': '麥寮',
  'twDistrict.douliu': '斗六',
  'twDistrict.linnei': '林內',
  'twDistrict.gukeng': '古坑',
  'twDistrict.citong': '莿桐',
  'twDistrict.xiluo': '西螺',
  'twDistrict.erlun': '二崙',
  'twDistrict.beigang': '北港',
  'twDistrict.shuilin': '水林',
  'twDistrict.kouhu': '口湖',
  'twDistrict.sihu': '四湖',
  'twDistrict.yuanchang': '元長',

  'twDistrict.westCentralDistrict': '中西區',
  'twDistrict.eastDistrict': '東區',
  'twDistrict.southDistrict': '南區',
  'twDistrict.northDistrict': '北區',
  'twDistrict.anpingDistrict': '安平區',
  'twDistrict.annanDistrict': '安南區',
  'twDistrict.yongkangDistrict': '永康區',
  'twDistrict.guirenDistrict': '歸仁區',
  'twDistrict.xinhuaDistrict': '新化區',
  'twDistrict.zuozhenDistrict': '左鎮區',
  'twDistrict.yujingDistrict': '玉井區',
  'twDistrict.nanxiDistrict': '楠西區',
  'twDistrict.nanhuaDistrict': '南化區',
  'twDistrict.rendeDistrict': '仁德區',
  'twDistrict.guanmiaoDistrict': '關廟區',
  'twDistrict.longciDistrict': '龍崎區',
  'twDistrict.guantianDistrict': '官田區',
  'twDistrict.madouDistrict': '麻豆區',
  'twDistrict.jialiDistrict': '佳里區',
  'twDistrict.xigangDistrict': '西港區',
  'twDistrict.qiguDistrict': '七股區',
  'twDistrict.jiangjunDistrict': '將軍區',
  'twDistrict.xuejiaDistrict': '學甲區',
  'twDistrict.beimenDistrict': '北門區',
  'twDistrict.xinyingDistrict': '新營區',
  'twDistrict.houbiDistrict': '後壁區',
  'twDistrict.baiheDistrict': '白河區',
  'twDistrict.dongshanDistrict': '東山區',
  'twDistrict.liujiaDistrict': '六甲區',
  'twDistrict.xiayingDistrict': '下營區',
  'twDistrict.liuyingDistrict': '柳營區',
  'twDistrict.yanshuiDistrict': '鹽水區',
  'twDistrict.shanhuaDistrict': '善化區',
  'twDistrict.daneiDistrict': '大內區',
  'twDistrict.shanshangDistrict': '山上區',
  'twDistrict.xinshiDistrict': '新市區',
  'twDistrict.andingDistrict': '安定區',

  'twDistrict.xinxingDistrict': '新興區',
  'twDistrict.qianjinDistrict': '前金區',
  'twDistrict.lingyaDistrict': '苓雅區',
  'twDistrict.yanchengDistrict': '鹽埕區',
  'twDistrict.gushanDistrict': '鼓山區',
  'twDistrict.qijinDistrict': '旗津區',
  'twDistrict.qianzhenDistrict': '前鎮區',
  'twDistrict.sanminDistrict': '三民區',
  'twDistrict.nanzihDistrict': '楠梓區',
  'twDistrict.xiaogangDistrict': '小港區',
  'twDistrict.zuoyingDistrict': '左營區',
  'twDistrict.renwuDistrict': '仁武區',
  'twDistrict.dasheDistrict': '大社區',
  'twDistrict.gangshanDistrict': '岡山區',
  'twDistrict.luzhuDistrict': '路竹區',
  'twDistrict.alianDistrict': '阿蓮區',
  'twDistrict.tianliaoDistrict': '田寮區',
  'twDistrict.yanchaoDistrict': '燕巢區',
  'twDistrict.qiaotouDistrict': '橋頭區',
  'twDistrict.ziguanDistrict': '梓官區',
  'twDistrict.mituoDistrict': '彌陀區',
  'twDistrict.yongAnDistrict': '永安區',
  'twDistrict.huneiDistrict': '湖內區',
  'twDistrict.fengshanDistrict': '鳳山區',
  'twDistrict.daliaoDistrict': '大寮區',
  'twDistrict.linyuanDistrict': '林園區',
  'twDistrict.niaosongDistrict': '鳥松區',
  'twDistrict.dashuDistrict': '大樹區',
  'twDistrict.qishanDistrict': '旗山區',
  'twDistrict.meinongDistrict': '美濃區',
  'twDistrict.liuguiDistrict': '六龜區',
  'twDistrict.neimenDistrict': '內門區',
  'twDistrict.shanlinDistrict': '杉林區',
  'twDistrict.jiaxianDistrict': '甲仙區',
  'twDistrict.taoyuanDistrict': '桃源區',
  'twDistrict.namaxiaDistrict': '那瑪夏區',
  'twDistrict.maolinDistrict': '茂林區',
  'twDistrict.jiadingDistrict': '茄萣區',

  'twDistrict.pratasIsland': '東沙',
  'twDistrict.spratlyIslands': '南沙',

  'twDistrict.magong': '馬公',
  'twDistrict.xiyu': '西嶼',
  'twDistrict.wangAn': '望安',
  'twDistrict.qimei': '七美',
  'twDistrict.baisha': '白沙',
  'twDistrict.huxi': '湖西',

  'twDistrict.pingtung': '屏東',
  'twDistrict.sandimen': '三地門',
  'twDistrict.wutai': '霧臺',
  'twDistrict.majia': '瑪家',
  'twDistrict.jiuru': '九如',
  'twDistrict.ligang': '里港',
  'twDistrict.gaoshu': '高樹',
  'twDistrict.yanpu': '鹽埔',
  'twDistrict.changzhi': '長治',
  'twDistrict.linluo': '麟洛',
  'twDistrict.zhutian': '竹田',
  'twDistrict.neipu': '內埔',
  'twDistrict.wandan': '萬丹',
  'twDistrict.chaozhou': '潮州',
  'twDistrict.taiwu': '泰武',
  'twDistrict.laiyi': '來義',
  'twDistrict.wanluan': '萬巒',
  'twDistrict.kanding': '崁頂',
  'twDistrict.sinpi': '新埤',
  'twDistrict.nanzhou': '南州',
  'twDistrict.linbian': '林邊',
  'twDistrict.donggang': '東港',
  'twDistrict.liuqiu': '琉球',
  'twDistrict.jiadong': '佳冬',
  'twDistrict.xinyuan': '新園',
  'twDistrict.fangliao': '枋寮',
  'twDistrict.fangshan': '枋山',
  'twDistrict.chunri': '春日',
  'twDistrict.shizi': '獅子',
  'twDistrict.checheng': '車城',
  'twDistrict.mudan': '牡丹',
  'twDistrict.hengchun': '恆春',
  'twDistrict.manzhou': '滿州',

  'twDistrict.taitung': '臺東',
  'twDistrict.greenIsland': '綠島',
  'twDistrict.lanyu': '蘭嶼',
  'twDistrict.yanping': '延平',
  'twDistrict.beinan': '卑南',
  'twDistrict.luye': '鹿野',
  'twDistrict.guanshan': '關山',
  'twDistrict.haiduan': '海端',
  'twDistrict.chishang': '池上',
  'twDistrict.donghe': '東河',
  'twDistrict.chenggong': '成功',
  'twDistrict.changbin': '長濱',
  'twDistrict.taimali': '太麻里',
  'twDistrict.jinfeng': '金峰',
  'twDistrict.dawu': '大武',
  'twDistrict.daren': '達仁',

  'twDistrict.hualien': '花蓮',
  'twDistrict.xincheng': '新城',
  'twDistrict.xiulin': '秀林',
  'twDistrict.jiAn': '吉安',
  'twDistrict.shoufeng': '壽豐',
  'twDistrict.fenglin': '鳳林',
  'twDistrict.guangfu': '光復',
  'twDistrict.fengbin': '豐濱',
  'twDistrict.ruisui': '瑞穗',
  'twDistrict.wanrong': '萬榮',
  'twDistrict.yuli': '玉里',
  'twDistrict.zhuoxi': '卓溪',
  'twDistrict.fuli': '富里',

  'twDistrict.jinsha': '金沙',
  'twDistrict.jinhu': '金湖',
  'twDistrict.jinning': '金寧',
  'twDistrict.jincheng': '金城',
  'twDistrict.lieyu': '烈嶼',
  'twDistrict.wuqiu': '烏坵',

  'twDistrict.nangan': '南竿',
  'twDistrict.beigan': '北竿',
  'twDistrict.juguang': '莒光',
  'twDistrict.dongyin': '東引',
  auto_pay_at: '自動轉帳日期: '
};
export default zhTW;
