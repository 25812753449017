import React from 'react';
import { injectIntl } from 'react-intl';
import { NavLink, withRouter, Link } from 'react-router-dom';
import HeaderNav from './../components/HeaderNav';
import * as request from '../apiClient/request';
import '../css/main.css';
import '../css/instructions.css';
import '../css/productList.css';
var webWidthScale = document.documentElement.clientWidth / 1440;
var thiz;
var PERSONALIZED_PLAQUE_LIST = [
  'btmf-personalized-technical-plaque',
  'btmf-personalized-plaque-bundle',
];
class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webWidthScale: document.documentElement.clientWidth / 1440,
      webHeight: document.documentElement.clientHeight,
      webWight: document.documentElement.webWight,
      data: [],
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.resize);
  }
  componentDidMount() {
    request
    .get(`api/v1/products/get_all_products`, 1, 23)
    .then(res => {
      console.log('res');
      console.log(res.response);
      console.log(res.response.length);
      var data = [];
      for (let i = 0; i < res.response.length; i++) {
        if (res.response[i].is_accessory) {
          if(!PERSONALIZED_PLAQUE_LIST.includes(res.response[i].slug)){
            res.response[i].regular_price = Math.floor(res.response[i].regular_price);
            res.response[i].price = Math.floor(res.response[i].price);
            data.push(res.response[i]);
          }
        }
      }
      console.log("accessory data >> ", data);
      this.setState({
        data: data
      });
    //   document.getElementById('preloader-container').style.display = 'none';
    })
    .catch(err => {
      let str = JSON.stringify(err);
      console.log('err2');
      console.log(err);
      console.log(err.response);
      if (err.response && err.response.status === 401) {
        localStorage.clear();
        this.push('/btmf/');
      }
    });
  }
  resize() {
    thiz.setState({
      webWidthScale: document.documentElement.clientWidth / 1440,
      webHeight: document.documentElement.clientHeight,
      webWight: document.documentElement.webWight
    });
    webWidthScale = document.documentElement.clientWidth / 1440;
  }

  render() {
    thiz = this;
    const { intl } = this.props;
    let accessoriestitle = intl.formatMessage({ id: 'accessoriestitle' });
    let currency = intl.formatMessage({ id: 'currency' });
    let { data } = this.state;

    return (
      <div className="max-home">
        <HeaderNav />

        <div style={{ paddingTop: 139 * webWidthScale }}>
          <div className="container-wrapper75 product-list-results">
            {/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
              <React.Fragment>
              {localStorage.lang === 'en' ? (
                <div className="product-list-banner" style={{ backgroundImage: 'url(' + require('../image/home/plaque-banner-tw-en-m.jpg') + ')' }}></div>
              ) : (
                <div className="product-list-banner" style={{ backgroundImage: 'url(' + require('../image/home/plaque-banner-tw-tc-m.jpg') + ')' }}></div>
              )}
              </React.Fragment>
            ) : (
              <React.Fragment>
              {localStorage.lang === 'en' ? (
                <div className="product-list-banner" style={{ backgroundImage: 'url(' + require('../image/home/plaque-banner-tw-en.jpg') + ')' }}></div>
              ) : (
                <div className="product-list-banner" style={{ backgroundImage: 'url(' + require('../image/home/plaque-banner-tw-tc.jpg') + ')' }}></div>
              )}
              </React.Fragment>
            )}
            <h1>{accessoriestitle}</h1>
            <div className="row">
            {data.map((item) => (
                <div className="product-result">
                    <Link to={`/btmf/product/` + item.slug}>
                        <div>
                            <div className="product-thumb" style={{ backgroundImage: 'url(' + item.image + ')' }}></div>
                        </div>
                    </Link>
                    <div className="product-title-wrapper">
                        <Link to={`/btmf/product/` + item.slug}>
                            {localStorage.lang === 'en' ? (
                                <span className="product-title">{item.name}</span>
                            ) : (
                                <span className="product-title">{item.zhtw_product_item_name}</span>
                            )}
                        </Link>
                    </div>
                    {localStorage.lang === 'en' ? (
                        <div className="product-desc">{item.product_variation}</div>
                    ) : (
                        <div className="product-desc">{item.zhtw_product_variation}</div>
                    )}
                    <div className="product-price">
                        {item.regular_price == item.price ? (
                          <React.Fragment>
                            <div className="product-no-sale-price">{currency} {item.price}</div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div className="product-original-price">{currency} {item.regular_price}</div>
                            <div className="product-sale-price">{currency} {item.price}</div>
                          </React.Fragment>
                        )}
                    </div>
                </div>
            ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ProductList = withRouter(injectIntl(ProductList));
export default ProductList;
