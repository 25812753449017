import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter, NavLink } from 'react-router-dom';
import isMobile from '../../utils/isMobile';
import * as request from '../../apiClient/request';
import '../../css/mobilehome.css';
import '../../css/mobilenav.css';
import '../../css/main.css';
import config from '../../config';

var yy = 1;

class HeaderNav extends React.Component {
  state = {
    cartNumber: 0,
    width: document.documentElement.clientWidth,
    showempty: false,
    isOpen: false
  };

  static defaultProps = {
    isBuyButtonVisible: true,
    isTransparent: false
  };

  componentWillMount() {
    window.addEventListener('resize', this.resize);
    let key;
    if (localStorage.cartKey) {
      key = localStorage.cartKey;
      request
        .get(`api/v1/shopping_carts/get_shopping_cart_item_count?shopping_cart_key=${key}`, 1)
        .then(res => {
          localStorage.setItem('cartItemCount', JSON.stringify(res));
          this.setState({
            cartNumber: res
          });
        })
        .catch(err => {
          let str = JSON.stringify(err);
          console.log('err2');
          console.log(err);
          console.log(err.response);
          if (err.response && err.response.status === 401) {
            localStorage.clear();
            if (this.props.history.location.pathname !== '/btmf/') this.props.history.push('/btmf/');
          }
        });
    }
  }

  componentDidMount = () => {
    if (isMobile()) {
      localStorage.lang === 'en'
        ? (document.getElementsByClassName('language')[0].innerText = 'English')
        : localStorage.lang === 'zh'
        ? (document.getElementsByClassName('language')[0].innerText = '內地')
        : (document.getElementsByClassName('language')[0].innerText = '繁體中文');
    } else {
      localStorage.lang === 'en'
        ? (document.getElementsByClassName('language')[0].innerText = 'English')
        : localStorage.lang === 'zh'
        ? (document.getElementsByClassName('language')[0].innerText = '內地')
        : (document.getElementsByClassName('language')[0].innerText = '繁體中文');
      localStorage.lang === 'en'
        ? (document.getElementsByClassName('language-tablet')[0].innerText = 'English')
        : localStorage.lang === 'zh'
        ? (document.getElementsByClassName('language-tablet')[0].innerText = '內地')
        : (document.getElementsByClassName('language-tablet')[0].innerText = '繁體中文');
    }
  };

  openNav = () => this.setState({ isOpen: true });

  closeNav = () => this.setState({ isOpen: false });

  cart = () => {
    if (this.props.history.location.pathname !== '/btmf/cart1') this.props.history.push('/btmf/cart1');
  };

  languageMobile(name) {
    localStorage.setItem('lang', name);
    window.location.reload();
    document.getElementsByClassName('mobile-language-choose')[0].style.display = 'none';
  }

  languagePc(name) {
    localStorage.setItem('lang', name);
    window.location.reload();
    document.getElementsByClassName('pc-language-choose')[0].style.display = 'none';
  }

  currentLanguage(name) {
    console.log(this.props);
    if (yy) {
      name === 'mobile'
        ? (document.getElementsByClassName('mobile-language-choose')[0].style.display = 'block')
        : (document.getElementsByClassName('pc-language-choose')[0].style.display = 'block');
      yy = 0;
    } else {
      name === 'mobile'
        ? (document.getElementsByClassName('mobile-language-choose')[0].style.display = 'none')
        : (document.getElementsByClassName('pc-language-choose')[0].style.display = 'none');
      yy = 1;
    }
  }

  login() {
    if (localStorage.user) {
      request
        .get('api/v1/users/get_current_user', 1)
        .then(res => {
          if (this.props.history.location.pathname !== '/btmf/user') this.props.history.push('/btmf/user');
        })
        .catch(err => {
          if (this.props.history.location.pathname !== '/btmf/login') this.props.history.push('/btmf/login');
        });
    } else {
      if (this.props.history.location.pathname !== '/btmf/login') this.props.history.push('/btmf/login');
    }
  }

  onmove() {
    if (localStorage.cartItemCount && JSON.parse(localStorage.cartItemCount) > 0) {
    } else {
      this.setState({
        showempty: true
      });
    }
  }

  resize = () => {
    this.setState({
      width: document.documentElement.clientWidth
    });
  };

  render() {
    const { intl } = this.props;
    let headernavmenu = intl.formatMessage({ id: 'headernavmenu' });
    let headernavlist1 = intl.formatMessage({ id: 'headernavlist1' });
    let headernavlist2 = intl.formatMessage({ id: 'headernavlist2' });
    let headernavlist3 = intl.formatMessage({ id: 'headernavlist3' });
    let headernavlist4 = intl.formatMessage({ id: 'headernavlist4' });
    let headernavlist5 = intl.formatMessage({ id: 'headernavlist5' });
    let headernavlist6 = intl.formatMessage({ id: 'headernavlist6' });
    let headernavlist7 = intl.formatMessage({ id: 'headernavlist7' });
    let headernavlist8 = intl.formatMessage({ id: 'headernavlist8' });
    let headernavlogin = intl.formatMessage({ id: 'headernavlogin' });
    let headernavbuynow = intl.formatMessage({ id: 'headernavbuynow' });
    let headeryou = intl.formatMessage({ id: 'headeryou' });
    let headernavmyaccount = intl.formatMessage({ id: 'headernavmyaccount' });

    if (isMobile()) {
      return (
        <div
          className="mobile-nav"
          ref="mobileNav"
          id="mobileNav"
          style={{
            width: this.state.isOpen ? '70%' : '100%',
            height: this.state.isOpen ? '100%' : 'auto',
            backgroundColor: this.props.isTransparent ? 'transparent' : '#000'
          }}
        >
          <div className="mobile-nav-content">
            <div
              className="menu"
              ref="menu"
              id="menu"
              style={{
                left: this.state.isOpen ? '0' : '-100%'
              }}
            >
              <div className="menu-title">
                <h3>{headernavmenu}</h3>
                <div className="menu-shut" ref="menuShut" onClick={this.closeNav}>
                  <i />
                  <i />
                </div>
              </div>
              <ul className="menu-list">
                <li>
                  <NavLink to="/btmf/?section=2" onClick={this.closeNav}>
                    {headernavlist1}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/btmf/?section=3" onClick={this.closeNav}>
                    {headernavlist2}
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/btmf/?section=14" onClick={this.closeNav}>
                    {headernavlist3}
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="/btmf/?section=16" onClick={this.closeNav}>
                    {headernavlist4}
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/?section=20" onClick={this.closeNav}>
                    {headernavlist5}
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="/btmf/instructions">{headernavlist6}</NavLink>
                </li>
                <li>
                  <NavLink to="/btmf/?section=19" onClick={this.closeNav}>
                    {headernavlist8}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/btmf/faq">{headernavlist7}</NavLink>
                </li>
                <li>
                  <a href="/r2d2">R2-D2</a>
                </li>
                <li>
                  <a href={config.snhUrl}>{'Snoopy & Friends'}</a>
                </li>
                <li>
                  <a href={config.amcUrl}>{'美國經典跑車典藏誌'}</a>
                </li>
              </ul>
              <div className="mune-button">
                {localStorage.lang !== 'zh' ? (
                  <button onClick={() => this.login()}>{headernavmyaccount}</button>
                ) : null}
                <button style={{display:'none'}}>
                  <div className="mobile-language">
                    <h3 onClick={() => this.currentLanguage('mobile')} className="language">
                      Taiwan
                    </h3>
                    <div className="mobile-language-arrow">
                      <img
                        src={require('../../image/home/ic_keyboard_arrow_down_white_36dp.png')}
                        alt="Select Language"
                      />
                    </div>
                    <ul className="mobile-language-choose">
                      <li onClick={() => this.languageMobile('tw')}>繁體中文</li>
                      <li onClick={() => this.languageMobile('en')}>English</li>
                    </ul>
                  </div>
                </button>
              </div>
            </div>
            <div className="home-header">
              <div className="home-nav">
                <div
                  className="nav-button"
                  ref="navButton"
                  onClick={this.openNav}
                  id="navButton"
                  style={{
                    display: this.state.isOpen ? 'none' : 'block'
                  }}
                >
                  <i />
                  <i />
                  <i />
                </div>
              </div>
              <h1 id="home-nav-title">
                <NavLink to="/btmf/?section=1">
                  <img src={require('../../image/common/STK_workshop_horiz@3x@3x.png')} alt="STK Workshop" />
                </NavLink>
              </h1>
              <div className="mobile-header-right">
                {localStorage.lang !== 'zh' ? (
                  <NavLink className="home-cart" to="/btmf/cart1">
                    <img src={require('../../image/home/cart@3x.png')} alt="Shopping Cart" />
                    <div
                      className="product-num"
                      style={{ display: this.props.cartNumber || this.state.cartNumber > 0 ? 'block' : 'none' }}
                    >
                      <i />
                      <span>{this.props.cartNumber ? this.props.cartNumber : this.state.cartNumber}</span>
                    </div>
                  </NavLink>
                ) : null}
                {this.props.isBuyButtonVisible && (
                  <div className="mobile-header-right-button" id="mobile-header-right-button">
                    <NavLink to="/btmf/?section=17">{headernavbuynow}</NavLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        id="mainHeader"
        style={{
          backgroundColor: this.props.isTransparent ? 'transparent' : '#000',
          width: this.state.isOpen ? '70%' : '100%',
          height: this.state.isOpen ? '100%' : 'auto'
        }}
      >
        <div className="menu" ref="menu" id="menu" style={{ left: this.state.isOpen ? '0' : '-100%' }}>
          <div className="menu-title">
            <h3>{headernavmenu}</h3>
            <div className="menu-shut" ref="menuShut" onClick={this.closeNav}>
              <i />
              <i />
            </div>
          </div>
          <ul className="menu-list">
            <li>
              <NavLink to="/btmf/?section=2" onClick={this.closeNav}>
                {headernavlist1}
              </NavLink>
            </li>
            <li>
              <NavLink to="/btmf/?section=3" onClick={this.closeNav}>
                {headernavlist2}
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/btmf/?section=12" onClick={this.closeNav}>
                {headernavlist3}
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/btmf/?section=14" onClick={this.closeNav}>
                {headernavlist4}
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/?section=18" onClick={this.closeNav}>
                {headernavlist5}
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/btmf/instructions">{headernavlist6}</NavLink>
            </li>
            <li>
              <NavLink to="/btmf/?section=17" onClick={this.closeNav}>
                {headernavlist8}
              </NavLink>
            </li>
            <li>
              <NavLink to="/btmf/faq">{headernavlist7}</NavLink>
            </li>
            <li>
              <a href="/r2d2">R2-D2</a>
            </li>
            <li>
              <a href={config.snhUrl}>{'Snoopy & Friends'}</a>
            </li>
            <li>
                  <a href={config.amcUrl}>{'美國經典跑車典藏誌'}</a>
                </li>
          </ul>
          <div className="mune-button">
            {localStorage.lang !== 'zh' ? (
              <button onClick={() => this.login()}>{headernavmyaccount}</button>
            ) : null}
            <button  style={{display:'none'}}>
              <div className="mobile-language">
                <h3 onClick={() => this.currentLanguage('mobile')} className="language-tablet">
                  Taiwan
                </h3>
                <div className="mobile-language-arrow">
                  <img src={require('../../image/home/ic_keyboard_arrow_down_white_36dp.png')} alt="Select Language" />
                </div>
                <ul className="mobile-language-choose">
                  <li onClick={() => this.languageMobile('tw')}>繁體中文</li>
                  <li onClick={() => this.languageMobile('en')}>English</li>
                </ul>
              </div>
            </button>
          </div>
        </div>

        <div className="responsive-header">
          <div className="responsive-header-nav">
            <div
              className="responsive-header-nav-button"
              ref="navButton"
              onClick={this.openNav}
              id="responsive-header-nav-button"
            >
              <i />
              <i />
              <i />
            </div>
          </div>
          <NavLink to="/btmf/?section=1" className="responsive-nav-title">
            <img
              className="responsive-header-nav-logo-icon"
              src={require('../../image/common/STK_workshop_horiz@3x@3x.png')}
              alt="STK Workshop"
            />
          </NavLink>
          <div className="responsive-header-right">
            {localStorage.lang !== 'zh' ? (
              <NavLink to="/btmf/cart1" className="responsive-header-home-cart">
                <img src={require('../../image/home/cart@3x.png')} alt="Shopping Cart" />
                <div
                  className="responsive-header-product-num"
                  style={{ display: this.props.cartNumber || this.state.cartNumber > 0 ? 'block' : 'none' }}
                >
                  <i />
                  <span>{this.props.cartNumber ? this.props.cartNumber : this.state.cartNumber}</span>
                </div>
              </NavLink>
            ) : null}
            {this.props.isBuyButtonVisible && (
              <div className="responsive-header-right-button" id="responsive-header-right-button">
                <NavLink to="/btmf/?section=15">{headernavbuynow}</NavLink>
              </div>
            )}
          </div>
        </div>

        <div
          id="header"
          style={{
            width: this.state.width < 1550 ? 'auto' : this.state.width > 1550 ? this.state.width - 129 : 'auto'
          }}
        >
          <ul id="nav" className="pagination-additional pager pager-lg">
            <li>
              <NavLink to="/btmf/?section=1" style={{ marginRight: this.state.width < 1251 ? 17 : 27 }}>
                <img
                  className="nav-logo-icon"
                  src={require('../../image/common/STK_workshop_horiz@3x@3x.png')}
                  alt="STK Workshop"
                />
              </NavLink>
            </li>
            <li>
              <NavLink to="/btmf/?section=2" style={{ marginRight: this.state.width < 1251 ? 7 : 20 }}>
                {headernavlist1}
                <span id="nav-item-video" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/btmf/?section=3" style={{ marginRight: this.state.width < 1251 ? 7 : 20 }}>
                {headernavlist2}
                <span id="nav-item-model" className={this.props.selectedItem === 'model' ? 'current' : ''} />
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/btmf/?section=12" style={{ marginRight: this.state.width < 1251 ? 7 : 20 }}>
                {headernavlist3}
                <span id="nav-item-box" className={this.props.selectedItem === 'box' ? 'current' : ''} />
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/btmf/?section=14" style={{ marginRight: this.state.width < 1251 ? 7 : 20 }}>
                {headernavlist4}
                <span id="nav-item-service" className={this.props.selectedItem === 'service' ? 'current' : ''} />
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/?section=18" style={{ marginRight: this.state.width < 1251 ? 7 : 20 }}>
                {headernavlist5}
                <span id="nav-item-gallery" className={this.props.selectedItem === 'gallery' ? 'current' : ''} />
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/btmf/instructions" style={{ marginRight: this.state.width < 1251 ? 7 : 20 }}>
                {headernavlist6}
                <span
                  id="nav-item-instructions"
                  className={this.props.selectedItem === 'instructions' ? 'current' : ''}
                />
              </NavLink>
            </li>
            <li>
              <NavLink to="/btmf/?section=17" style={{ marginRight: this.state.width < 1251 ? 7 : 20 }}>
                {headernavlist8}
                <span id="nav-item-accessories" className={this.props.selectedItem === 'accessories' ? 'current' : ''} />
              </NavLink>
            </li>
            <li>
              <NavLink to="/btmf/faq" style={{ marginRight: this.state.width < 1251 ? 7 : 20 }}>
                {headernavlist7}
                <span id="nav-item-faq" className={this.props.selectedItem === 'faq' ? 'current' : ''} />
              </NavLink>
            </li>
            <li>
              <a href="/r2d2" style={{ marginRight: this.state.width < 1251 ? 7 : 20 }}>
                R2-D2
                <span id="nav-item-r2d2" />
              </a>
            </li>
            <li>
              <a href={config.snhUrl} style={{ marginRight: this.state.width < 1251 ? 7 : 20 }}>
              {'Snoopy & Friends'}
                <span id="nav-item-snoopy=friends" />
              </a>
            </li>
            <li>
                  <a href={config.amcUrl} style={{ marginRight: this.state.width < 1251 ? 7 : 0 }}>
              {'美國經典跑車典藏誌'}
                <span id="nav-item-snoopy=friends" />
              </a>
                </li>
          </ul>
          <div className="header-right" style={{ float: 'right', marginRight: 10 }}>
            <ul>
              {this.props.isBuyButtonVisible && (
                <li>
                  <NavLink to="/btmf/?section=15" className="button-primary">
                    {headernavbuynow}
                  </NavLink>
                </li>
              )}
              <li className="header-language" style={{display:'none'}}>
                <span onClick={() => this.currentLanguage('pc')} className="language" style={{ cursor: 'pointer' }}>
                  Taiwan
                </span>
                <div id="pc-language-choose" className="pc-language-choose">
                  {/*<div onClick={() => this.languagePc("zh")}  style={{cursor:'pointer'}}>內地</div>*/}
                  <div onClick={() => this.languagePc('tw')} style={{ cursor: 'pointer' }}>
                    繁體中文
                  </div>
                  <div onClick={() => this.languagePc('en')} style={{ cursor: 'pointer' }}>
                    English
                  </div>
                </div>
              </li>
              {localStorage.lang !== 'zh' ? (
                <li
                  className="cart"
                  id="cart"
                  title="Shopping Cart"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.cart()}
                >
                  <div
                    className="cartName"
                    style={{ display: this.props.cartNumber || this.state.cartNumber > 0 ? 'block' : 'none' }}
                  >
                    <span>{this.props.cartNumber ? this.props.cartNumber : this.state.cartNumber}</span>
                  </div>
                  <img
                    className="cart-icon"
                    src={require('../../image/home/cart@3x.png')}
                    onMouseEnter={() => this.onmove()}
                    onMouseLeave={() => this.setState({ showempty: false })}
                    alt="Shopping Cart"
                  />
                  <div className={this.state.showempty ? 'cart-hover' : 'hidden'}>
                    <i />
                    {headeryou}
                  </div>
                </li>
              ) : null}
              {localStorage.lang !== 'zh' ? (
              <li onClick={() => this.login()} title={headernavmyaccount} style={{ cursor: 'pointer' }}>
                <img className="account-icon" src={require('../../image/home/login@3x.png')} alt="My Account" />
              </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(injectIntl(HeaderNav));
