import apiRequest from './apiRequest';

export async function get(url, newdata, data2,   isReturnOriginalResponse = false) {
  const opt = {
    method: 'get',
    url: url
  };

  const response = await apiRequest(opt, newdata);
  return new Promise(function(resolve, reject) {
    //console.log("get");
    //console.log(response);
    if (data2 === 23) {
      if (response.data) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } else {
      if (!response.data.response && response.data.status && response.data.status !== 'ok') {
        // alert(response.data.errors?response.data.errors:response.data.error);
        reject(response.data.errors);
      } else {
        if (isReturnOriginalResponse) {
          resolve(response.data);
        } else {
          resolve(response.data.response);
        }
      }
    }
  });
}
export async function post(url, data, newdata, newdata2) {
  const opt = {
    method: 'post',
    url: url,
    data
  };

  const response = await apiRequest(opt, newdata);

  return new Promise(function(resolve, reject) {
    console.log('post');
    console.log('after post');
    console.log(response);
    console.log('after reponses');
    if (!response.data.response && response.data.status !== 'ok') {
      console.log('reject1');
      // alert(response.data.errors?response.data.errors:response.data.error);
      reject(response.data.errors);
    } else {
      console.log('else statemnet');
      let header = response.headers;
      if (url === 'auth/sign_in') {
        let data = {
          token: header['access-token'],
          uid: header.uid,
          client: header.client
        };
        localStorage.setItem('userheader', JSON.stringify(data));
      }
      console.log('entering newdata2');
      if (newdata2 && newdata2 === 22) {
        console.log('resolved response');
        resolve(response);
      } else {
        console.log('resolved data response');
        resolve(response.data.response);
      }
    }
  });
}
export async function put(url, data, newdata) {
  const opt = {
    method: 'put',
    url: url,
    data
  };

  const response = await apiRequest(opt, newdata);
  return new Promise(function(resolve, reject) {
    if (!response.data.response) {
      alert(response.data.errors ? response.data.errors : response.data.error);
      reject(response.data.errors);
    } else {
      resolve(response.data.response);
    }
  });
}
export async function del(url, data, newdata) {
  const opt = {
    method: 'delete',
    url: url,
    data
  };

  const response = await apiRequest(opt, newdata);
  return new Promise(function(resolve, reject) {
    if (!response) {
      // alert(response.data.errors ? response.data.errors : response.data.error);
      reject(response);
    } else {
      resolve(response.data.response);
    }
  });
}
