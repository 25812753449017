export default {
  IMAGE_HOST: 'https://scrap-api-test.s3-ap-northeast-1.amazonaws.com/',

  // API DOMAIN - Testing
  // apiBaseUrl: 'https://stk-test.herokuapp.com/',
  // API DOMAIN - Production
  // apiBaseUrl: 'https://stk-taiwan.herokuapp.com/',
  // apiBaseUrl: 'http://localhost:3000/',

  // PAYMENT GATEWAY - Testing
  // payurl: 'https://test.esafe.com.tw/Service/Etopm.aspx',
  // PAYMENT GATEWAY - Production
  // payurl: 'https://www.esafe.com.tw/Service/Etopm.aspx',
  // CART3 REDIRECT
  // currentUrl: 'http://localhost:3001/'
  // currentUrl: 'https://share-test.com/'
  // currentUrl: 'http://share-test.com.s3-website-ap-northeast-1.amazonaws.com/'
  // currentUrl: 'https://stk-workshop.tw/'

  /* Local  */
  // apiBaseUrl: 'http://localhost:3000/',
  // payurl: 'https://test.esafe.com.tw/Service/Etopm.aspx',
  // currentUrl: 'http://localhost:3001/'

  /* Test */
  apiBaseUrl: 'https://stk-taiwan-test.herokuapp.com/',
  payurl: 'https://test.esafe.com.tw/Service/Etopm.aspx',
  snhUrl: 'http://stk-snh-uat-tw.s3-website-ap-northeast-1.amazonaws.com/',
  amcUrl: 'http://stk-amc-uat-tw.s3-website-ap-northeast-1.amazonaws.com/',
  currentUrl: 'https://stk-tw.share-test.com/',
  merchantID: 'S1909209106'

  /* Production */
  // apiBaseUrl: 'https://stk-taiwan.herokuapp.com/',
  // payurl: 'https://www.esafe.com.tw/Service/Etopm.aspx',
  // snhUrl: 'https://snh.stk-workshop.tw',
  // amcUrl: 'https://amc.stk-workshop.tw',
  // currentUrl: 'https://stk-workshop.tw/',
  // merchantID: 'S1911010070'
};
