import React from 'react';
import { injectIntl } from 'react-intl';
import isMobile from '../utils/isMobile';
import '../css/mobileModelTwo.css';
import '../css/mobileVideo.css';

var clientHeight = document.documentElement.clientHeight;
var clientWidth = document.documentElement.clientWidth;

class ModelTwo extends React.Component {
  state = {
    heightScale: clientHeight / 868 > clientWidth / 1359 ? clientWidth / 1359 : clientHeight / 868,
    widthScale: clientHeight / 868 > clientWidth / 1359 ? clientWidth / 1359 : clientHeight / 868,
    mobileScale: clientWidth / 375
  };

  componentWillMount = () => {
    window.addEventListener('resize', this.modelTwoResize);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.engaged !== this.props.engaged) {
      if (this.props.engaged) {
        if (!isMobile()) {
          document.getElementById('pc-model2-canvas-virtual-l1').classList.add('engaged');
          document.getElementById('pc-model2-canvas-virtual-l2').classList.add('engaged');
          document.getElementById('pc-model2-canvas-virtual-l3').classList.add('engaged');
          document.getElementById('pc-model2-canvas-virtual-l4').classList.add('engaged');
          document.getElementById('pc-model2-canvas-virtual-w1').classList.add('engaged');
          document.getElementById('pc-model2-canvas-virtual-w2').classList.add('engaged');
          document.getElementById('pc-model2-canvas-virtual-w3').classList.add('engaged');
          document.getElementById('pc-model2-canvas-virtual-w4').classList.add('engaged');
          //TODO
          window.setTimeout(() => {
            if (document.getElementById('pc-model2-canvas-virtual-h1') != null)
              document.getElementById('pc-model2-canvas-virtual-h1').classList.add('engaged');
            if (document.getElementById('pc-model2-canvas-virtual-h2') != null)
              document.getElementById('pc-model2-canvas-virtual-h2').classList.add('engaged');
            if (document.getElementById('pc-model2-canvas-virtual-h3') != null)
              document.getElementById('pc-model2-canvas-virtual-h3').classList.add('engaged');
            if (document.getElementById('pc-model2-canvas-virtual-h4') != null)
              document.getElementById('pc-model2-canvas-virtual-h4').classList.add('engaged');
            if (document.getElementById('pc-model2-canvas-virtual-l') != null)
              document.getElementById('pc-model2-canvas-virtual-l').classList.add('engaged');
          }, 1000);
          //TODO
          window.setTimeout(() => {
            if (document.getElementById('pcModelTwo-sizeL') != null)
              document.getElementById('pcModelTwo-sizeL').style.visibility = 'visible';
            if (document.getElementById('pcModelTwo-sizeL') != null)
              document.getElementById('pcModelTwo-sizeL').style.opacity = 1;
          }, 2000);

          //TODO
          window.setTimeout(() => {
            if (document.getElementById('pc-model2-canvas-virtual-w') != null)
              document.getElementById('pc-model2-canvas-virtual-w').classList.add('engaged');
          }, 2000);

          //TODO
          window.setTimeout(() => {
            if (document.getElementById('pc-model2-canvas-virtual-h') != null)
              document.getElementById('pc-model2-canvas-virtual-h').classList.add('engaged');

            if (document.getElementById('pcModelTwo-sizeW') != null)
              document.getElementById('pcModelTwo-sizeW').style.visibility = 'visible';
            if (document.getElementById('pcModelTwo-sizeW') != null)
              document.getElementById('pcModelTwo-sizeW').style.opacity = 1;
          }, 3000);

          //TODO
          window.setTimeout(() => {
            if (document.getElementById('pcModelTwo-sizeH') != null)
              document.getElementById('pcModelTwo-sizeH').style.visibility = 'visible';
            if (document.getElementById('pcModelTwo-sizeH') != null)
              document.getElementById('pcModelTwo-sizeH').style.opacity = 1;
          }, 4000);

          //TODO
          window.setTimeout(() => {
            if (document.getElementById('pcModelTwo-weight') != null)
              document.getElementById('pcModelTwo-weight').style.visibility = 'visible';
            if (document.getElementById('pcModelTwo-weight') != null)
              document.getElementById('pcModelTwo-weight').style.opacity = 1;
          }, 4000);
        } else {
          document.getElementById('mobile-model2-canvas-virtual-l1').classList.add('engaged');
          document.getElementById('mobile-model2-canvas-virtual-l2').classList.add('engaged');
          document.getElementById('mobile-model2-canvas-virtual-l3').classList.add('engaged');
          document.getElementById('mobile-model2-canvas-virtual-l4').classList.add('engaged');
          document.getElementById('mobile-model2-canvas-virtual-w1').classList.add('engaged');
          document.getElementById('mobile-model2-canvas-virtual-w2').classList.add('engaged');
          document.getElementById('mobile-model2-canvas-virtual-w3').classList.add('engaged');
          document.getElementById('mobile-model2-canvas-virtual-w4').classList.add('engaged');

          //TODO
          window.setTimeout(() => {
            if (document.getElementById('mobile-model2-canvas-virtual-h1') != null)
              document.getElementById('mobile-model2-canvas-virtual-h1').classList.add('engaged');
            if (document.getElementById('mobile-model2-canvas-virtual-h2') != null)
              document.getElementById('mobile-model2-canvas-virtual-h2').classList.add('engaged');
            if (document.getElementById('mobile-model2-canvas-virtual-h3') != null)
              document.getElementById('mobile-model2-canvas-virtual-h3').classList.add('engaged');
            if (document.getElementById('mobile-model2-canvas-virtual-h4') != null)
              document.getElementById('mobile-model2-canvas-virtual-h4').classList.add('engaged');
          }, 1500);
          //TODO
          window.setTimeout(() => {
            if (document.getElementById('mobile-model2-canvas-virtual-l') != null)
              document.getElementById('mobile-model2-canvas-virtual-l').classList.add('engaged');
          }, 2000);
          //TODO
          window.setTimeout(() => {
            if (document.getElementById('mobile-model2-canvas-virtual-w') != null)
              document.getElementById('mobile-model2-canvas-virtual-w').classList.add('engaged');
          }, 2800);
          //TODO
          window.setTimeout(() => {
            if (document.getElementById('mobile-model2-canvas-virtual-h') != null)
              document.getElementById('mobile-model2-canvas-virtual-h').classList.add('engaged');
          }, 3600);
        }
      }
    }
  }

  modelTwoResize = () => {
    clientHeight = document.documentElement.clientHeight;
    clientWidth = document.documentElement.clientWidth;
    this.setState({
      heightScale: clientHeight / 868 > clientWidth / 1359 ? clientWidth / 1359 : clientHeight / 868,
      widthScale: clientHeight / 868 > clientWidth / 1359 ? clientWidth / 1359 : clientHeight / 868
    });
  };

  render() {
    const { intl } = this.props;
    let modeltwosame = intl.formatMessage({ id: 'modeltwosame' });
    let modeltwothe = intl.formatMessage({ id: 'modeltwothe' });
    let modeltwomovie = intl.formatMessage({ id: 'modeltwomovie' });
    return isMobile() ? (
      <div className="mobile-ma">
        <div className="mobile-model2" id="mobileModelTwo" style={{ paddingBottom: 37 }}>
          <div className="mobile-model2-info">
            <div>
              <div className="mobile-model2-icon">
                <i />
                <span>1:1</span>
              </div>
              <div className="mobile-model2-des">
                <p>{modeltwosame}</p>
                <h3>{modeltwothe}</h3>
                <p>{modeltwomovie}</p>
              </div>
            </div>
          </div>
          <div className="mobile-model2-pic">
            <canvas id="mobile-model2-canvas" />
            <div className="mobile-model2-line">
              <div
                id="mobile-model2-canvas-virtual-l1"
                className="mobile-canvas-virtual-model2 "
                style={{
                  width: 1,
                  height: 250 * this.state.mobileScale,
                  left: (335 * this.state.widthScale) / Math.cos(19),
                  top: -53 * this.state.mobileScale,
                  display: 'block',
                  position: 'absolute'
                }}
              >
                <div className="line1" />
              </div>
              <div
                id="mobile-model2-canvas-virtual-l2"
                className="mobile-canvas-virtual-model2 "
                style={{
                  width: 1,
                  height: 100 * this.state.mobileScale,
                  left: (0 * this.state.widthScale) / Math.cos(19),
                  top: 105 * this.state.mobileScale,
                  display: 'block',
                  position: 'absolute'
                }}
              >
                <div className="line1" />
              </div>
              <div
                id="mobile-model2-canvas-virtual-l3"
                className="mobile-canvas-virtual-model2 "
                style={{
                  width: 1,
                  height: 288 * this.state.mobileScale,
                  left: (950 * this.state.widthScale) / Math.cos(19),
                  top: -1 * this.state.mobileScale,
                  display: 'block',
                  position: 'absolute'
                }}
              >
                <div className="line1" />
              </div>
              <div
                id="mobile-model2-canvas-virtual-l4"
                className="mobile-canvas-virtual-model2 "
                style={{
                  width: 1,
                  height: 288 * this.state.mobileScale,
                  left: (970 * this.state.widthScale) / Math.cos(19),
                  top: 44 * this.state.mobileScale,
                  display: 'block',
                  position: 'absolute'
                }}
              >
                <div className="line1" />
              </div>

              <div
                id="mobile-model2-canvas-virtual-w1"
                className="mobile-canvas-virtual-model2 "
                style={{
                  width: 1,
                  height: 250 * this.state.mobileScale,
                  left: (300 * this.state.widthScale) / Math.cos(75),
                  top: 42 * this.state.mobileScale,
                  display: 'block',
                  position: 'absolute'
                }}
              >
                <div className="line1" />
              </div>
              <div
                id="mobile-model2-canvas-virtual-w2"
                className="mobile-canvas-virtual-model2 "
                style={{
                  width: 1,
                  height: 250 * this.state.mobileScale,
                  left: (215 * this.state.widthScale) / Math.cos(75),
                  top: 82 * this.state.mobileScale,
                  display: 'block',
                  position: 'absolute'
                }}
              >
                <div className="line1" />
              </div>
              <div
                id="mobile-model2-canvas-virtual-w3"
                className="mobile-canvas-virtual-model2 "
                style={{
                  width: 1,
                  height: 250 * this.state.mobileScale,
                  left: (945 * this.state.widthScale) / Math.cos(75),
                  top: -66 * this.state.mobileScale,
                  display: 'block',
                  position: 'absolute'
                }}
              >
                <div className="line1" />
              </div>
              <div
                id="mobile-model2-canvas-virtual-w4"
                className="mobile-canvas-virtual-model2 "
                style={{
                  width: 1,
                  height: 80 * this.state.mobileScale,
                  left: (1340 * this.state.widthScale) / Math.cos(75),
                  top: 93 * this.state.mobileScale,
                  display: 'block',
                  position: 'absolute'
                }}
              >
                <div className="line1" />
              </div>

              <div
                id="mobile-model2-canvas-virtual-h1"
                className="mobile-canvas-virtual-model2 "
                style={{
                  height: 90 * this.state.mobileScale,
                  left: -10 * this.state.mobileScale,
                  top: 102 * this.state.mobileScale,
                  display: 'block'
                }}
              >
                <div className="line2 " />
              </div>
              <div
                id="mobile-model2-canvas-virtual-h2"
                className="mobile-canvas-virtual-model2 "
                style={{
                  height: 90 * this.state.mobileScale,
                  left: 165 * this.state.mobileScale,
                  top: 165 * this.state.mobileScale,
                  display: 'block'
                }}
              >
                <div className="line2 " />
              </div>
              <div
                id="mobile-model2-canvas-virtual-h3"
                className="mobile-canvas-virtual-model2 "
                style={{
                  height: 43 * this.state.mobileScale,
                  left: 189 * this.state.mobileScale,
                  top: 16 * this.state.mobileScale,
                  display: 'block'
                }}
              >
                <div className="line2" />
              </div>
              <div
                id="mobile-model2-canvas-virtual-h4"
                className="mobile-canvas-virtual-model2 "
                style={{
                  height: 90 * this.state.mobileScale,
                  left: 385 * this.state.mobileScale,
                  top: 65 * this.state.mobileScale,
                  display: 'block'
                }}
              >
                <div className="line2" />
              </div>

              <div
                id="mobile-model2-canvas-virtual-h"
                className="mobile-canvas-virtual-model2 "
                style={{
                  width: 1,
                  height: 50 * this.state.mobileScale,
                  left: 165 * this.state.mobileScale,
                  top: 185 * this.state.mobileScale,
                  display: 'block'
                }}
              >
                <div className="line v-l" />
              </div>
              <div
                id="mobile-model2-canvas-virtual-l"
                className="mobile-canvas-virtual-model2 "
                style={{
                  width: 1,
                  height: 220 * this.state.mobileScale,
                  left: (85 * this.state.mobileScale) / Math.cos(19),
                  top: -36 * this.state.mobileScale,
                  display: 'block',
                  position: 'absolute'
                }}
              >
                <div className="line v-l" />
              </div>
              <div
                id="mobile-model2-canvas-virtual-w"
                className="mobile-canvas-virtual-model2 "
                style={{
                  width: 1,
                  height: 199 * this.state.mobileScale,
                  left: (64 * this.state.mobileScale) / Math.cos(75),
                  top: 60 * this.state.mobileScale,
                  display: 'block',
                  position: 'absolute'
                }}
              >
                <div className="line v-r" />
              </div>
            </div>
            <img src={require('../image/home/TheComplete.png')} alt="Millennium Falcon" />
          </div>
          <ul className="size">
            <li>
              L:
              <span>808mm</span>
            </li>
            <li>
              W:
              <span>596mm</span>
            </li>
            <li>
              H:
              <span>192mm</span>
            </li>
          </ul>
          <div className="mobile-model2-model2">
            <img src={require('../image/home/kg.png')} alt="Weight in KG Icon" />
          </div>
        </div>
      </div>
    ) : (
      <div className="component first-component">
        <div
          className="model2"
          id="webModelTwo"
          style={{
            paddingTop: 144 * this.state.heightScale,
            paddingBottom: 68 * this.state.heightScale,
            position: 'relative'
          }}
        >
          <div
            className="model2-pic"
            id="model2-pic"
            style={{
              width:
                clientWidth < 1053
                  ? '100%'
                  : clientWidth < 1425
                  ? 1359 * this.state.widthScale
                  : 1359 * this.state.widthScale,
              height: 656 * this.state.heightScale,
              position: 'relative'
            }}
          >
            <div className="model2-info">
              <div className="model2-icon">
                <i />
                <span>1:1</span>
              </div>
              <div className="model2-des">
                <h4>{modeltwosame}</h4>
                <p>
                  {modeltwothe}
                  <span>{modeltwomovie}</span>
                </p>
              </div>
            </div>
            <canvas id="pc-model2-canvas" />
            <div
              id="pc-model2-canvas-virtual-l1"
              className="canvas-virtual-model2"
              style={{
                width: 1,
                height: 850 * this.state.heightScale,
                left: (375 * this.state.widthScale) / Math.cos(19),
                top: -260 * this.state.heightScale,
                display: 'block',
                position: 'absolute'
              }}
            >
              <div className="line1" />
            </div>
            <div
              id="pc-model2-canvas-virtual-l2"
              className="canvas-virtual-model2"
              style={{
                width: 1,
                height: 320 * this.state.heightScale,
                left: (130 * this.state.widthScale) / Math.cos(19),
                top: 205 * this.state.heightScale,
                display: 'block',
                position: 'absolute'
              }}
            >
              <div className="line1" />
            </div>
            <div
              id="pc-model2-canvas-virtual-l3"
              className="canvas-virtual-model2"
              style={{
                width: 1,
                height: 800 * this.state.heightScale,
                left: (930 * this.state.widthScale) / Math.cos(19),
                top: -98 * this.state.heightScale,
                display: 'block',
                position: 'absolute'
              }}
            >
              <div className="line1" />
            </div>
            <div
              id="pc-model2-canvas-virtual-l4"
              className="canvas-virtual-model2"
              style={{
                width: 1,
                height: 800 * this.state.heightScale,
                left: (930 * this.state.widthScale) / Math.cos(19),
                top: 26 * this.state.heightScale,
                display: 'block',
                position: 'absolute'
              }}
            >
              <div className="line1" />
            </div>

            <div
              id="pc-model2-canvas-virtual-w1"
              className="canvas-virtual-model2"
              style={{
                width: 1,
                height: 820 * this.state.heightScale,
                left: (350 * this.state.widthScale) / Math.cos(75),
                top: -26 * this.state.heightScale,
                display: 'block',
                position: 'absolute'
              }}
            >
              <div className="line1" />
            </div>
            <div
              id="pc-model2-canvas-virtual-w2"
              className="canvas-virtual-model2"
              style={{
                width: 1,
                height: 720 * this.state.heightScale,
                left: (310 * this.state.widthScale) / Math.cos(75),
                top: 130 * this.state.heightScale,
                display: 'block',
                position: 'absolute'
              }}
            >
              <div className="line1" />
            </div>
            <div
              id="pc-model2-canvas-virtual-w3"
              className="canvas-virtual-model2"
              style={{
                width: 1,
                height: 670 * this.state.heightScale,
                left: (910 * this.state.widthScale) / Math.cos(75),
                top: -220 * this.state.heightScale,
                display: 'block',
                position: 'absolute'
              }}
            >
              <div className="line1" />
            </div>
            <div
              id="pc-model2-canvas-virtual-w4"
              className="canvas-virtual-model2"
              style={{
                width: 1,
                height: 180 * this.state.heightScale,
                left: (1120 * this.state.widthScale) / Math.cos(75),
                top: 198 * this.state.heightScale,
                display: 'block',
                position: 'absolute'
              }}
            >
              <div className="line1" />
            </div>

            <div
              id="pc-model2-canvas-virtual-h1"
              className="canvas-virtual-model2"
              style={{
                height: 240 * this.state.heightScale,
                left: 27 * this.state.widthScale,
                top: 230 * this.state.heightScale,
                display: 'block'
              }}
            >
              <div className="line1 " />
            </div>
            <div
              id="pc-model2-canvas-virtual-h2"
              className="canvas-virtual-model2"
              style={{
                height: 240 * this.state.heightScale,
                left: 617 * this.state.widthScale,
                top: 380 * this.state.heightScale,
                display: 'block'
              }}
            >
              <div className="line1 " />
            </div>
            <div
              id="pc-model2-canvas-virtual-h3"
              className="canvas-virtual-model2 "
              style={{
                height: 100 * this.state.heightScale,
                left: 705 * this.state.widthScale,
                top: 1 * this.state.heightScale,
                display: 'block'
              }}
            >
              <div className="line1" />
            </div>
            <div
              id="pc-model2-canvas-virtual-h4"
              className="canvas-virtual-model2"
              style={{
                height: 240 * this.state.heightScale,
                left: 1240 * this.state.widthScale,
                top: 110 * this.state.heightScale,
                display: 'block'
              }}
            >
              <div className="line1" />
            </div>

            <div
              id="pc-model2-canvas-virtual-h"
              className="canvas-virtual-model2"
              style={{
                width: 1,
                height: 125 * this.state.heightScale,
                left: 617 * this.state.widthScale,
                top: 445 * this.state.heightScale,
                display: 'block'
              }}
            >
              <div className="line v-l" />
            </div>
            <div
              id="pc-model2-canvas-virtual-l"
              className="canvas-virtual-model2"
              style={{
                width: 1,
                height: 720 * this.state.heightScale,
                left: (360 * this.state.widthScale) / Math.cos(19),
                top: -190 * this.state.heightScale,
                display: 'block',
                position: 'absolute'
              }}
            >
              <div className="line v-l" />
            </div>
            <div
              id="pc-model2-canvas-virtual-w"
              className="canvas-virtual-model2"
              style={{
                width: 1,
                height: 612 * this.state.heightScale,
                left: (297 * this.state.widthScale) / Math.cos(75),
                top: 60 * this.state.heightScale,
                display: 'block',
                position: 'absolute'
              }}
            >
              <div className="line v-r" />
            </div>

            <img src={require('../image/home/A034_01210425_C026_LRes@3x.png')} alt="Millennium Falcon" />
            <div
              id="pcModelTwo-sizeL"
              className="pcModelTwo-size pcModelTwo-sizeL"
              style={{ top: 120 * this.state.heightScale, left: 350 * this.state.widthScale, display: 'block' }}
            >
              <span>L:</span>
              <span>808mm</span>
            </div>
            <div
              id="pcModelTwo-sizeW"
              className="pcModelTwo-size pcModelTwo-sizeW"
              style={{ top: 380 * this.state.heightScale, left: 210 * this.state.widthScale, display: 'block' }}
            >
              <span>W:</span>
              <span>596mm</span>
            </div>
            <div
              id="pcModelTwo-sizeH"
              className="pcModelTwo-size pcModelTwo-sizeH"
              style={{ top: 500 * this.state.heightScale, left: 650 * this.state.widthScale, display: 'block' }}
            >
              <span>H:</span>
              <span>192mm</span>
            </div>
            <div
              id="pcModelTwo-weight"
              className="pcModelTwo-weight"
              style={{ top: 530 * this.state.heightScale, left: 1060 * this.state.widthScale, display: 'block' }}
            >
              <img src={require('../image/home/kg.png')} alt="Weight in KG Icon" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ModelTwo = injectIntl(ModelTwo);
export default ModelTwo;
