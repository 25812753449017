import React from 'react';
import queryString from 'query-string';

import HeaderNav from './../components/HeaderNav';
import HomePageContent from './HomePageContent';

import isMobile from '../utils/isMobile';

import '../css/main.css';

export default class HomePage extends React.Component {
  state = {
    showpop: false,
    showpop1: false,
    text1: '',
    text2: '',
    text3: '',
    cartNumber: null,

    isNavVisible: true,
    currentSection: 0,
    scrollToSection: null
  };

  componentWillMount = () => {
    if (!localStorage.cartKey) {
      let key = Date.now();
      key = key + '' + Math.floor(Math.random() * 9999999999999999);
      localStorage.setItem('cartKey', key);
    }
    console.log('localStorage.cartKey = ' + localStorage.cartKey);

    if (!localStorage.lang) {
      localStorage.setItem('lang', 'tw');
   }
  };

  componentDidMount() {
    this.handleScrollToSectionFromQuery();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location && prevProps.location && this.props.location.search !== prevProps.location.search) {
      this.handleScrollToSectionFromQuery();
    }
  }

  handleScrollToSectionFromQuery() {
    const section = this.getSectionFromQuery();
    if (section && section !== this.state.currentSection) {
      this.setState(() => ({ scrollToSection: section }));
    }
  }

  getSectionFromQuery() {
    const query = queryString.parse(this.props.location.search);
    if (query.section) {
      const section = parseInt(query.section);
      if (!Number.isNaN(section) && section > 0 && section <= 22) {
        return section;
      }
    }
  }

  onChangeCurrentSection = section => {
    this.setState(state => {
      if (state.scrollToSection !== state.currentSection) {
        //this.props.history.replace(`/`);
      }

      return {
        currentSection: section,
        scrollToSection: section === state.scrollToSection ? null : state.scrollToSection
      };
    });
  };

  showpop = (text1, text2, text3) => {
    this.setState({ showpop: true, text1, text2, text3 });
  };

  hidepop = () => {
    this.setState({ showpop: false });
  };

  showpop1 = (text1, text2, text3) => {
    this.setState({ showpop1: true, text1, text2, text3 });
  };

  hidepop1 = () => {
    this.setState({ showpop1: false });
  };

  renderDesktop() {
    const { currentSection, scrollToSection, showpop, text1, text2, showpop1 } = this.state;

    console.log('currentSection',currentSection)

    let selectedMenuItem = null;
    if (currentSection >= 3 && currentSection <= 10) {
      selectedMenuItem = 'model';
    } else if (currentSection === 12) {
      selectedMenuItem = 'box';
    } else if (currentSection === 14) {
      selectedMenuItem = 'service';
    } else if (currentSection === 18) {
      selectedMenuItem = 'gallery';
    } else if (currentSection === 17) {
      selectedMenuItem = 'accessories';
    }

    return (
      <div className="max-home">
        {currentSection !== 2 && (
          <HeaderNav
            isTransparent={currentSection === 1}
            isBuyButtonVisible={![1, 11, 12, 15, 16, 17].includes(currentSection)}
            selectedItem={selectedMenuItem}
            goToSection={num => this.setState({ scrollToSection: num })}
            cartNumber={this.state.cartNumber}
          />
        )}

        <HomePageContent
          scrollToSection={scrollToSection}
          currentSection={currentSection}
          onChangeCurrentSection={this.onChangeCurrentSection}
          showpop={(text1, text2, text3) => this.showpop(text1, text2, text3)}
          showpop1={(text1, text2, text3) => this.showpop1(text1, text2, text3)}
        />

        <div className={showpop ? 'pop' : 'hidden'}>
          <p>{text1}</p>
          <p>{text2}</p>
          <p className="footnote">{this.state.text3}</p>
          <div className="btn" onClick={this.hidepop}>
            OK
          </div>
          <img src={require('../image/common/close.png')} className="close" onClick={this.hidepop} alt="Close Dialog" />
        </div>
        <div className={showpop1 ? 'mobilepop' : 'hidden'}>
          <p>{text1}</p>
          <p>{text2}</p>
          <p className="footnote">{this.state.text3}</p>
          <div className="btn" onClick={this.hidepop1}>
            OK
          </div>
          <img
            src={require('../image/common/close.png')}
            className="close"
            onClick={this.hidepop1}
            alt="Close Dialog"
          />
        </div>
      </div>
    );
  }

  renderMobile() {
    const { currentSection, scrollToSection, showpop, text1, text2, showpop1 } = this.state;

    let selectedMenuItem = null;
    if (currentSection >= 3 && currentSection <= 10) {
      selectedMenuItem = 'model';
    } else if (currentSection === 12) {
      selectedMenuItem = 'box';
    } else if (currentSection === 14) {
      selectedMenuItem = 'service';
    } else if (currentSection === 16) {
      selectedMenuItem = 'gallery';
    }

    return (
      <div className="max-home">
        {currentSection !== 2 && (
          <HeaderNav
            isTransparent={currentSection === 1}
            isBuyButtonVisible={![1, 13, 14, 17, 18, 19].includes(currentSection)}
            selectedItem={selectedMenuItem}
            goToSection={num => this.setState({ scrollToSection: num })}
            cartNumber={this.state.cartNumber}
          />
        )}

        <HomePageContent
          scrollToSection={scrollToSection}
          currentSection={currentSection}
          onChangeCurrentSection={this.onChangeCurrentSection}
          showpop={(text1, text2, text3) => this.showpop(text1, text2, text3)}
          showpop1={(text1, text2, text3) => this.showpop1(text1, text2, text3)}
        />

        <div className={showpop ? 'pop' : 'hidden'}>
          <p>{text1}</p>
          <p>{text2}</p>
          <p className="footnote">{this.state.text3}</p>
          <div className="btn" onClick={this.hidepop}>
            OK
          </div>
          <img src={require('../image/common/close.png')} className="close" onClick={this.hidepop} alt="Close Dialog" />
        </div>
        <div className={showpop1 ? 'mobilepop' : 'hidden'}>
          <p>{text1}</p>
          <p>{text2}</p>
          <p className="footnote">{this.state.text3}</p>
          <div className="btn" onClick={this.hidepop1}>
            OK
          </div>
          <img
            src={require('../image/common/close.png')}
            className="close"
            onClick={this.hidepop1}
            alt="Close Dialog"
          />
        </div>
      </div>
    );
  }

  render() {
    return isMobile() ? this.renderMobile() : this.renderDesktop();
  }
}
