import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import '../css/mobileModelSix.css';
import * as request from '../apiClient/request';
const offer1regprice = 4480;
//const offer1price = 3980;
const offer1price = 4480;
class ModelSix extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: document.documentElement.clientWidth,
      onSale: false
    };
  }
  componentDidMount = () => {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      //      this.autoPlay()
    }
    window.addEventListener('resize', this.resize);
  };
  componentDidUpdate(prevProps) {
    if (prevProps.engaged !== this.props.engaged && this.video) {
      if (this.props.engaged) {
        this.video.play()
      } else {
        //this.video.currenTime = 0;
      }
    }
  }
  resize = () => {
    this.setState({
      width: document.documentElement.clientWidth
    });
  };
  addCart(key) {
    const { intl } = this.props;
    let subscriptionmorethanone1 = intl.formatMessage({ id: 'subscriptionmorethanone1' });
    let subscriptionmorethanone2 = intl.formatMessage({ id: 'subscriptionmorethanone2' });
    let subscriptionmorethanone3 = intl.formatMessage({ id: 'subscriptionmorethanone3' });
    let alert3 = intl.formatMessage({ id: 'alert3' });
    let alert4 = intl.formatMessage({ id: 'alert4' });
    let alert5 = intl.formatMessage({ id: 'alert5' });
    let alert6 = intl.formatMessage({ id: 'alert6' });
    let orderconflictnote = intl.formatMessage({ id: 'orderconflictnote' });
    let orderconflictnote2 = intl.formatMessage({ id: 'orderconflictnote2' });
    let alert7 = intl.formatMessage({ id: 'alert7' });
    let data = {
      shopping_cart_key: localStorage.cartKey,
      product_key: key
    };
    request
      .post('api/v1/shopping_carts/add_product_to_shopping_cart', data, 1, 22)
      .then(res => {
        //console.log(res);
        localStorage.setItem('cartItem', JSON.stringify(res.data.response.shopping_cart_items));
        // alert(alert7);
        if (this.props.history.location.pathname !== '/btmf/cart1') this.props.history.push('/btmf/cart1');
        var cartItem = JSON.parse(localStorage.cartItem);
        this.props.cartNumber(cartItem.length);
      })
      .catch(err => {
        console.log('err');
        console.log(err);
        let str = JSON.stringify(err);
        console.log('err2');

        console.log(err);
        console.log(err.response);

        if (err.response && err.response.status === 403) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop(alert3, alert4, orderconflictnote, orderconflictnote2);
          } else {
            this.props.showpop(alert5, alert6, orderconflictnote, orderconflictnote2);
          }
          // alert('111')
        }
        if (err.response && err.response.status === 400) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop(
              subscriptionmorethanone1,
              subscriptionmorethanone2,
              subscriptionmorethanone3,
              orderconflictnote2
            );
          }
          // alert('111')
        }
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          this.push('/btmf/');
          // alert('111')
        }
      });
  }
  addCart1(key) {
    const { intl } = this.props;
    let subscriptionmorethanone1 = intl.formatMessage({ id: 'subscriptionmorethanone1' });
    let subscriptionmorethanone2 = intl.formatMessage({ id: 'subscriptionmorethanone2' });
    let subscriptionmorethanone3 = intl.formatMessage({ id: 'subscriptionmorethanone3' });
    let alert3 = intl.formatMessage({ id: 'alert3' });
    let alert4 = intl.formatMessage({ id: 'alert4' });
    let alert5 = intl.formatMessage({ id: 'alert5' });
    let alert6 = intl.formatMessage({ id: 'alert6' });
    let orderconflictnote = intl.formatMessage({ id: 'orderconflictnote' });
    let orderconflictnote2 = intl.formatMessage({ id: 'orderconflictnote2' });
    let alert7 = intl.formatMessage({ id: 'alert7' });
    let data = {
      shopping_cart_key: localStorage.cartKey,
      product_key: key
    };
    request
      .post('api/v1/shopping_carts/add_product_to_shopping_cart', data, 1, 22)
      .then(res => {
        //console.log(res);
        localStorage.setItem('cartItem', JSON.stringify(res.data.response.shopping_cart_items));
        // alert(alert7)
        if (this.props.history.location.pathname !== '/btmf/cart1') this.props.history.push('/btmf/cart1');
        var cartItem = JSON.parse(localStorage.cartItem);
        this.props.cartNumber(cartItem.length);
      })
      .catch(err => {
        console.log('err');
        console.log(err);
        let str = JSON.stringify(err);
        console.log('err2');

        console.log(err);
        console.log(err.response);

        if (err.response && err.response.status === 403) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop1(alert3, alert4, orderconflictnote, orderconflictnote2);
          } else {
            this.props.showpop1(alert5, alert6, orderconflictnote, orderconflictnote2);
          }
          // alert('111')
        }
        if (err.response && err.response.status === 400) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop(
              subscriptionmorethanone1,
              subscriptionmorethanone2,
              subscriptionmorethanone3,
              orderconflictnote2
            );
          }
          // alert('111')
        }
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          this.push('/btmf/');
          // alert('111')
        }
      });
  }
  autoPlay = () => {
    document.getElementById('mobileModelSixVideo').play();
  };

  render() {
    const { intl } = this.props;
    let modelsixpre = intl.formatMessage({ id: 'modelsixpre' });
    let modelsixpack = intl.formatMessage({ id: 'modelsixpack' });
    let modelsixpackast = intl.formatMessage({ id: 'modelsixpackast' });
    let modelsixpackast2 = intl.formatMessage({ id: 'modelsixpackast2' });
    let modelsixpack1 = intl.formatMessage({ id: 'modelsixpack1' });
    let modelsixpack1star = intl.formatMessage({ id: 'modelsixpack1star' });
    let modelsixpack1starast = intl.formatMessage({ id: 'modelsixpack1starast' });
    let modelsixpack1starast2 = intl.formatMessage({ id: 'modelsixpack1starast2' });
    let modelsixdisclaimer1 = intl.formatMessage({ id: 'modelsixdisclaimer1' });
    let modelsixdisclaimer2 = intl.formatMessage({ id: 'modelsixdisclaimer2' });
    let modelsixregular = intl.formatMessage({ id: 'modelsixregular' });
    let modelsixbuy = intl.formatMessage({ id: 'modelsixbuy' });
    let installmentavailable = intl.formatMessage({ id: 'installmentavailable' });
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile-ma">
        <div className="mobile-model6" id="mobileModelSix" style={{ paddingBottom: 85 }}>
          <div className="mobil-emodel6-video">
            <div className="mobil-model6-video-inner">
              <video
                autoPlay="true"
                loop="true"
                muted="true"
                playsInline="true"
                id="mobileModelSixVideo"
                //src={require('../video/model6m.mp4')}
                src="https://stk-resource.s3-ap-northeast-1.amazonaws.com/videos/model6m.mp4"
                style={{ width: 'auto', height: '100%' }}
                type="video/mp4"
              />
            </div>
          </div>
          <div className="mobile-model6-details">
            <h2>{modelsixpre}</h2>
            <h1>
              {modelsixpack}
              <span className="smallasterisk">{modelsixpackast}</span>
              {modelsixpackast2}
            </h1>
            {this.state.onSale ? (<p>{modelsixregular}{offer1regprice}</p>) : <br />}
            <a onClick={() => this.addCart1('build_yourself_kit')}>{modelsixbuy}{offer1price}</a>
            <h4>{installmentavailable}</h4>
          </div>
        </div>
        <p
          style={{ paddingLeft: 20, paddingRight: 20, fontSize: 13, color: '#FFF', textAlign: 'center', width: '100%' }}
        >
          <span style={{ fontStyle: 'italic' }}>{modelsixdisclaimer1}</span>
          {modelsixdisclaimer2}
        </p>
      </div>
    ) : (
      <div className="component first-component">
        <div className="model6" id="webModelSix" style={{ overflow: 'hidden' }}>
          <div className="null" />
          <div className="model6-details">
            <h2>{modelsixpre}</h2>
            <h1>
              {modelsixpack1star}
              <span className="smallasterisk">{modelsixpack1starast}</span>
              {modelsixpack1starast2}
            </h1>
            {this.state.onSale ? (<p>{modelsixregular}{offer1regprice}</p>) : <br />}
            <button onClick={() => this.addCart('build_yourself_kit')}>{modelsixbuy}{offer1price}</button>
            <h4>{installmentavailable}</h4>
          </div>
          <video
            ref={ref => this.video = ref}
            autoPlay="true"
            loop="true"
            muted="true"
            src={require('../video/model6.mp4')}
            style={{ width: '100%', height: '100%' }}
            type="video/mp4"
          />
          <p className="model6-disclaimer" style={{ fontSize: this.state.width > 1800 ? 21 : 14 }}>
            <span style={{ fontStyle: 'italic' }}>{modelsixdisclaimer1}</span>
            {modelsixdisclaimer2}
          </p>
        </div>
      </div>
    );
  }
}
ModelSix = withRouter(injectIntl(ModelSix));
export default ModelSix;
