import React from 'react';
import { injectIntl } from 'react-intl';
import '../css/mobileModelOne.css';
import '../css/mobileVideo.css';
var pcWidthScale = document.documentElement.clientWidth / 1440;
class ModelOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight
    };
  }

  componentWillMount () {
    window.addEventListener('resize', this.resize);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.engaged !== this.props.engaged && this.video) {
      if (this.props.engaged) {
        this.video.play()
      } else {
        this.video.currenTime = 0;
      }
    }
  }

  resize = () => {
    pcWidthScale = document.documentElement.clientWidth / 1440;
    this.setState({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight
    });
  };

  render() {
    const { intl } = this.props;
    let homeOfficial = intl.formatMessage({ id: 'homeOfficial' });
    let homeOfficialMobileA = intl.formatMessage({ id: 'homeOfficialMobileA' });
    let homeOfficialMobileB = intl.formatMessage({ id: 'homeOfficialMobileB' });
    let modelonewith = intl.formatMessage({ id: 'modelonewith' });
    let modelonewith2 = intl.formatMessage({ id: 'modelonewith2' });
    let modelonewith3 = intl.formatMessage({ id: 'modelonewith3' });
    let imageDisclaimer = intl.formatMessage({ id: 'imageDisclaimer' });
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile-ma">
        <div className="mobile-model1" id="mobileModelOne">
          <div className="mobile-model1-info" style={{ paddingBottom: 0 }}>
            <h3>
              {homeOfficialMobileA}
              <br />
              {homeOfficialMobileB}
            </h3>
          </div>
          <div className="mobile-model1-video1">
            <video
              ref={ref => this.video = ref}
              autoPlay="true"
              muted="true"
              playsInline="true"
              id="modelOneVideoM"
              //src={require('../video/model1m.mp4')}
              src="https://stk-resource.s3-ap-northeast-1.amazonaws.com/videos/model1m.mp4"
              style={{ width: '100%', height: '100%' }}
              type="video/mp4"
            />
          </div>
          <div className="mobile-model1-info-b">
            <p>
              {modelonewith}
              <span className="strengthen">{modelonewith2}</span>
              {modelonewith3}
            </p>
            <p className="smallnote">{imageDisclaimer}</p>
          </div>
        </div>
      </div>
    ) : (
      <div className="component first-component" style={{ overflow: 'hidden' }}>
        <div className="model1" id="webModelOne" style={{ overflow: 'hidden' }}>
          <div className="model1-info" style={{ left: this.state.width < 1161 ? 0 : 110 }}>
            <h3>{homeOfficial}</h3>
            <p>
              {modelonewith}
              <span className="strengthen">{modelonewith2}</span>
              {modelonewith3}
            </p>
            <p className="smallnote">{imageDisclaimer}</p>
          </div>
          <div
            className="model1-video1"
            style={{
              width: pcWidthScale * 946,
              height: pcWidthScale * 530,
              marginTop: this.state.height < 600 ? -265 : -(pcWidthScale * 265) + 52
            }}
          >
            <video
              ref={ref => this.video = ref}
              autoPlay="true"
              muted="true"
              src={require('../video/model1.mp4')}
              style={{ width: '100%', height: '100%' }}
              type="video/mp4"
              id="modelOneVideo"
            />
          </div>
        </div>
      </div>
    );
  }
}
ModelOne = injectIntl(ModelOne);
export default ModelOne;
