import React from 'react';
import { injectIntl } from 'react-intl';
//import $ from  'jquery';
import '../css/slide.css';
//import "../css/slideindex.css";
import '../css/mobileGuide.css';
var page = 0;
var slide = false;

class Guide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: document.documentElement.clientWidth
    };
  }
  componentWillMount = () => {
    window.addEventListener('resize', this.resize);
  };
  resize = () => {
    this.setState({
      width: document.documentElement.clientWidth
    });
  };
  componentDidMount = () => {
    this.banner();
  };
  banner = () => {
    //	   		debugger;
    var slide2d = document.getElementById('guide-slide2d');
    var slideLi = slide2d.getElementsByTagName('li');
    if (!slide) {
      this.time = setInterval(() => {
        for (var i = 0; i < slideLi.length; i++) {
          slideLi[i].style.display = 'none';
        }
        slideLi[page].style.display = 'block';
        page++;
        if (page === slideLi.length) {
          page = 0;
        }
      }, 3000);
    }
  };
  handleOver = () => {
    clearInterval(this.time);
  };
  handleOut = () => {
    var slide2d = document.getElementById('guide-slide2d');
    var slideLi = slide2d.getElementsByTagName('li');
    this.time = setInterval(() => {
      for (var i = 0; i < slideLi.length; i++) {
        slideLi[i].style.display = 'none';
      }
      slideLi[page].style.display = 'block';
      page++;
      if (page === slideLi.length) {
        page = 0;
      }
    }, 3000);
  };
  guidePrev = () => {
    var slide2d = document.getElementById('guide-slide2d');
    var slideLi = slide2d.getElementsByTagName('li');
    for (var i = 0; i < slideLi.length; i++) {
      slideLi[i].style.display = 'none';
    }
    slideLi[page].style.display = 'block';
    page--;
    if (page < 0) {
      page = slideLi.length - 1;
    }
  };
  guideNext = () => {
    var slide2d = document.getElementById('guide-slide2d');
    var slideLi = slide2d.getElementsByTagName('li');
    for (var i = 0; i < slideLi.length; i++) {
      slideLi[i].style.display = 'none';
    }
    slideLi[page].style.display = 'block';
    page++;
    if (page === slideLi.length) {
      page = 0;
    }
  };
  render() {
    const { intl } = this.props;
    let guideevery = intl.formatMessage({ id: 'guideevery' });
    let guidefeaturing = intl.formatMessage({ id: 'guidefeaturing' });
    let guideyoull = intl.formatMessage({ id: 'guideyoull' });
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile-ma">
        <div className="mobile-guide" id="mobileGuide" style={{ paddingBottom: 37 }}>
          <div className="mobile-guide1-title">
            <h2>{guideevery}</h2>
          </div>
          <div className="mobile-guide-slide2">
            <div className="mobile-guide-slide2-inner">
              <ul id="guide-slide2d" className="mobile-guide-slide-list clearfix">
                <li className="item0" style={{ display: 'block' }}>
                  <img
                    className="cover-img"
                    src={require('../image/guide1/01.jpg')}
                    alt="Build the Millennium Falcon Magazine Page"
                  />
                </li>
                <li className="item1">
                  <img
                    className="cover-img"
                    src={require('../image/guide1/02.jpg')}
                    alt="Build the Millennium Falcon Magazine Page"
                  />
                </li>
                <li className="item2">
                  <img
                    className="cover-img"
                    src={require('../image/guide1/03.jpg')}
                    alt="Build the Millennium Falcon Magazine Page"
                  />
                </li>
                <li className="item3">
                  <img
                    className="cover-img"
                    src={require('../image/guide1/04.jpg')}
                    alt="Build the Millennium Falcon Magazine Page"
                  />
                </li>
                <li className="item4">
                  <img
                    className="cover-img"
                    src={require('../image/guide1/05.jpg')}
                    alt="Build the Millennium Falcon Magazine Page"
                  />
                </li>
                <li className="item5">
                  <img
                    className="cover-img"
                    src={require('../image/guide1/06.jpg')}
                    alt="Build the Millennium Falcon Magazine Page"
                  />
                </li>
                <li className="item6">
                  <img
                    className="cover-img"
                    src={require('../image/guide1/07.jpg')}
                    alt="Build the Millennium Falcon Magazine Page"
                  />
                </li>
                <li className="item7">
                  <img
                    className="cover-img"
                    src={require('../image/guide1/08.jpg')}
                    alt="Build the Millennium Falcon Magazine Page"
                  />
                </li>
              </ul>
              <div className="mobile-guide-slide-controls">
                <a className="item-prev glyphicon" href="javascript:void(0);" onClick={this.guidePrev}>
                  <img src={require('../image/home/Path 5.png')} alt="Scroll Left" />
                </a>
                <a className="item-next glyphicon" href="javascript:void(0);" onClick={this.guideNext}>
                  <img src={require('../image/home/Path 5 Copy.png')} alt="Scroll Right" />
                </a>
              </div>
            </div>
          </div>
          <div className="mobile-guide1-info">
            <p>{guidefeaturing}</p>
            <p className="guide-footnote">{guideyoull}</p>
          </div>
        </div>
      </div>
    ) : (
      <div className="component first-component">
        <div className="guide" id="webGuide">
          <div className="null" />
          <div
            className="guide-content"
            style={{
              width: this.state.width > 1439 ? this.state.width - 209 : this.state.width > 1230 ? 1230 : '100%'
            }}
          >
            <div className="guide-inner">
              <div
                className="sider"
                onMouseOver={this.handleOver}
                onMouseOut={this.handleOut}
                style={{
                  marginTop:
                    this.state.width > 1450
                      ? this.state.width < 1650
                        ? (1650 - this.state.width) / 2.5
                        : null
                      : this.state.width < 1160
                      ? 60
                      : null
                }}
              >
                <div className="sider-inner">
                  <div className="slide-carousel slide-2d">
                    <ul id="guide-slide2d" className="item-list clearfix">
                      <li className="item0" style={{ display: 'block' }}>
                        <div className="item-content">
                          <a href="javascript:void(0);">
                            <img
                              className="cover-img"
                              src={require('../image/guide1/01.jpg')}
                              alt="Build the Millennium Falcon Magazine Page"
                            />
                          </a>
                        </div>
                      </li>
                      <li className="item1">
                        <div className="item-content">
                          <a href="javascript:void(0);">
                            <img
                              className="cover-img"
                              src={require('../image/guide1/02.jpg')}
                              alt="Build the Millennium Falcon Magazine Page"
                            />
                          </a>
                        </div>
                      </li>
                      <li className="item2">
                        <div className="item-content">
                          <a href="javascript:void(0);">
                            <img
                              className="cover-img"
                              src={require('../image/guide1/03.jpg')}
                              alt="Build the Millennium Falcon Magazine Page"
                            />
                          </a>
                        </div>
                      </li>
                      <li className="item3">
                        <div className="item-content">
                          <a href="javascript:void(0);">
                            <img
                              className="cover-img"
                              src={require('../image/guide1/04.jpg')}
                              alt="Build the Millennium Falcon Magazine Page"
                            />
                          </a>
                        </div>
                      </li>
                      <li className="item4">
                        <div className="item-content">
                          <a href="javascript:void(0);">
                            <img
                              className="cover-img"
                              src={require('../image/guide1/05.jpg')}
                              alt="Build the Millennium Falcon Magazine Page"
                            />
                          </a>
                        </div>
                      </li>
                      <li className="item5">
                        <div className="item-content">
                          <a href="javascript:void(0);">
                            <img
                              className="cover-img"
                              src={require('../image/guide1/06.jpg')}
                              alt="Build the Millennium Falcon Magazine Page"
                            />
                          </a>
                        </div>
                      </li>
                      <li className="item6">
                        <div className="item-content">
                          <a href="javascript:void(0);">
                            <img
                              className="cover-img"
                              src={require('../image/guide1/07.jpg')}
                              alt="Build the Millennium Falcon Magazine Page"
                            />
                          </a>
                        </div>
                      </li>
                      <li className="item7">
                        <div className="item-content">
                          <a href="javascript:void(0);">
                            <img
                              className="cover-img"
                              src={require('../image/guide1/08.jpg')}
                              alt="Build the Millennium Falcon Magazine Page"
                            />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="controls">
                    <a className="item-prev glyphicon" href="javascript:void(0);" onClick={this.guidePrev}>
                      <img src={require('../image/home/Path 5.png')} alt="Scroll Left" />
                    </a>
                    <a className="item-next glyphicon" href="javascript:void(0);" onClick={this.guideNext}>
                      <img src={require('../image/home/Path 5 Copy.png')} alt="Scroll Right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="guide-info">
                <div className="guide-info-inner">
                  <h5>{guideevery}</h5>
                  <p>{guidefeaturing}</p>
                  <p className="guide-footnote">{guideyoull}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Guide = injectIntl(Guide);
export default Guide;
