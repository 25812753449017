import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/base.css';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/css/bootstrap-theme.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
registerServiceWorker();

const render = Component => {
  ReactDOM.render(
      <Component />,
    document.getElementById('root')
  );
};
render(App);
registerServiceWorker();
// if(module.hot){
// module.hot.accept('./App',()=>{
//     render(App)
// })
// }

if (module.hot) {
  module.hot.accept();
}