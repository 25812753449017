import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import '../css/mobileBox.css';
import '../css/mobileShopping.css';
import '../css/gallery.css';
import * as request from '../apiClient/request';
var webWidthScale = document.documentElement.clientWidth / 1440;
var webHeightScale = document.documentElement.clientHeight;
var sum;
var slider;
var item;
var list;
var mask;
var loadMore = 0;
var galleryTopOn = true;
class Gallery extends React.Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    window.addEventListener('resize', this.resize);
  }
  resize = () => {
    webWidthScale = document.documentElement.clientWidth / 1440;
    this.setState({
      width: document.documentElement.clientWidth
    });
  };
  componentDidMount() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {}
    else {
      slider = document.getElementById('slider')
    }
    mask = document.getElementsByClassName('gallery-img-mask');
    item = document.getElementById('slider-item');
    if (item) {
      list = item.getElementsByTagName('img');
    }
  }
  getPic(num) {
    sum = num;
    slider.style.display = 'block';
    for (var i = 0; i < list.length; i++) {
      list[i].style.display = 'none';
      mask[i].style.display = 'none';
      list[i].addEventListener('click', this.click);
    }
    list[sum].style.display = 'block';
  }
  left() {
    if (sum > 0) {
      sum--;
    }
    for (var i = 0; i < list.length; i++) {
      list[i].style.display = 'none';
    }
    list[sum].style.display = 'block';
  }
  right() {
    if (sum < 8) {
      sum++;
    }
    for (var i = 0; i < list.length; i++) {
      list[i].style.display = 'none';
    }
    list[sum].style.display = 'block';
  }
  click() {
    slider.style.display = 'none';
    for (var i = 0; i < mask.length; i++) {
      mask[i].style.display = 'block';
    }
  }
  loadMore() {
    var pic = this.refs.pic.getElementsByTagName('div');
    loadMore++;
    if (loadMore === 1) {
      for (var i = 3; i < 6; i++) {
        pic[i].style.display = 'block';
      }
    } else if (loadMore === 2) {
      for (var i = 6; i < 10; i++) {
        pic[i].style.display = 'block';
      }
    }
  }
  tc() {
    if (this.props.history.location.pathname !== '/btmf/terms-and-conditions') this.props.history.push('/btmf/terms-and-conditions');
    localStorage.setItem('tc', true);
  }
  /*changeGallery() {
    if (galleryTopOn) {
      document.getElementById("gallery-topleft").style.display="none";
      document.getElementById("gallery-topright").style.display="none";
      document.getElementById("gallery-bottomleft").style.display="block";
      document.getElementById("gallery-bottomright").style.display="block";
      galleryTopOn = false;
    } else {
      document.getElementById("gallery-bottomleft").style.display="none";
      document.getElementById("gallery-bottomright").style.display="none";
      document.getElementById("gallery-topleft").style.display="block";
      document.getElementById("gallery-topright").style.display="block";
      galleryTopOn = true;
    }
  }*/
  render() {
    const { intl } = this.props;
    let gallerytitle = intl.formatMessage({ id: 'gallerytitle' });

    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile-ma">
        <div className="mobile-shopping" id="mobileShopping">
          <div className="mobile-gallery-pic" ref="pic">
            <h1>{gallerytitle}</h1>
            <div
              className="mobile-gallery-image"
              style={{ backgroundImage: 'url(' + require('../image/gallery/Position_1m.jpg') + ')' }}
            >
              <div className="glow">
                <div className="btn-glow" />
              </div>
            </div>
            <div
              className="mobile-gallery-image"
              style={{ backgroundImage: 'url(' + require('../image/gallery/Position_2m.jpg') + ')' }}
            >
              <div className="glow">
                <div className="btn-glow" />
              </div>
            </div>
            <div
              className="mobile-gallery-image"
              style={{ backgroundImage: 'url(' + require('../image/gallery/Position_3m.jpg') + ')' }}
            >
              <div className="glow">
                <div className="btn-glow" />
              </div>
            </div>
            <div
              className="mobile-gallery-image"
              style={{ backgroundImage: 'url(' + require('../image/gallery/Position_4m.jpg') + ')' }}
            >
              <div className="glow">
                <div className="btn-glow" />
              </div>
            </div>
            <div
              className="mobile-gallery-image"
              style={{ backgroundImage: 'url(' + require('../image/gallery/Position_5m.jpg') + ')' }}
            >
              <div className="glow">
                <div className="btn-glow" />
              </div>
            </div>
            <div
              className="mobile-gallery-image"
              style={{ backgroundImage: 'url(' + require('../image/gallery/Position_6m.jpg') + ')' }}
            >
              <div className="glow">
                <div className="btn-glow" />
              </div>
            </div>
            <div
              className="mobile-gallery-image"
              style={{ backgroundImage: 'url(' + require('../image/gallery/Position_7m.jpg') + ')' }}
            >
              <div className="glow">
                <div className="btn-glow" />
              </div>
            </div>
            <div
              className="mobile-gallery-image"
              style={{ backgroundImage: 'url(' + require('../image/gallery/Position_8m.jpg') + ')' }}
            >
              <div className="glow">
                <div className="btn-glow" />
              </div>
            </div>
            <div
              className="mobile-gallery-image"
              style={{ backgroundImage: 'url(' + require('../image/gallery/Position_9m.jpg') + ')' }}
            >
              <div className="glow">
                <div className="btn-glow" />
              </div>
            </div>
            <div
              className="mobile-gallery-image"
              style={{ backgroundImage: 'url(' + require('../image/gallery/Position_10m.jpg') + ')' }}
            >
              <div className="glow">
                <div className="btn-glow" />
              </div>
            </div>
          </div>
          {/* <div className="loadMore" onClick={()=>this.loadMore()}>
              <h2>{loadmore}</h2>
          </div> */}
        </div>
      </div>
    ) : (
      <div className="component first-component">
        <div className="gallery" id="webGallery">
          <div className="null" id="null" style={{ minHeight: 104, height: 139 * webWidthScale, maxHeight: 150 }} />
          <div
            className="shopping-content"
            id="shopping-content"
            style={{
              overflow: 'visible',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: -((530 * webWidthScale) / 2 - 50),
              marginLeft: -((1110 * webWidthScale) / 2)
            }}
          >
            <div className="gallery-video" style={{ width: 1110 * webWidthScale, height: 480 * webWidthScale }}>
              {/*<div onClick={()=>this.changeGallery()} id="gallery-show-left" className="gallery-show-arrow">
                <img src={require("../image/common/gallery-left.png")} alt="" style={{width:'100%',height:'100%'}}/>
              </div>
              <div onClick={()=>this.changeGallery()} id="gallery-show-right" className="gallery-show-arrow">
                <img src={require("../image/common/gallery-right.png")} alt="" style={{width:'100%',height:'100%'}}/>
              </div>*/}
              <div id="gallery-top">
                <div
                  id="gallery-topleft"
                  style={{ width: 549 * webWidthScale, height: 313 * webWidthScale, marginBottom: 11 * webWidthScale }}
                >
                  <div style={{ width: '100%', height: '100%' }}>
                    <img src={require('../image/gallery/Position_1.jpg')} alt="The Hold" />
                    <div onClick={() => this.getPic(0)} className="gallery-img-mask" />
                  </div>
                </div>
                <div
                  id="gallery-topright"
                  className="item"
                  style={{
                    width: 549 * webWidthScale,
                    height: 313 * webWidthScale,
                    marginLeft: 11 * webWidthScale,
                    marginBottom: 11 * webWidthScale
                  }}
                >
                  <div
                    style={{
                      width: 269 * webWidthScale,
                      height: 151 * webWidthScale,
                      marginBottom: 11 * webWidthScale
                    }}
                  >
                    <img src={require('../image/gallery/Position_2.jpg')} alt="The Hold" />
                    <div onClick={() => this.getPic(1)} className="gallery-img-mask" />
                  </div>
                  <div
                    style={{
                      width: 269 * webWidthScale,
                      height: 151 * webWidthScale,
                      marginLeft: 11 * webWidthScale,
                      marginBottom: 11 * webWidthScale
                    }}
                  >
                    <img src={require('../image/gallery/Position_3.jpg')} alt="Tunnel" />
                    <div onClick={() => this.getPic(2)} className="gallery-img-mask" />
                  </div>
                  <div style={{ width: 269 * webWidthScale, height: 151 * webWidthScale }}>
                    <img src={require('../image/gallery/Position_4.jpg')} alt="Tunnel" />
                    <div onClick={() => this.getPic(3)} className="gallery-img-mask" />
                  </div>
                  <div
                    style={{ width: 269 * webWidthScale, height: 151 * webWidthScale, marginLeft: 11 * webWidthScale }}
                  >
                    <img src={require('../image/gallery/Position_5.jpg')} alt="Cockpit" />
                    <div onClick={() => this.getPic(4)} className="gallery-img-mask" />
                  </div>
                </div>
              </div>
              <div id="gallery-bottom">
                <div
                  id="gallery-bottomleft"
                  className="item"
                  style={{ width: 549 * webWidthScale, height: 151 * webWidthScale }}
                >
                  <div style={{ width: 269 * webWidthScale, height: 151 * webWidthScale }}>
                    <img src={require('../image/gallery/Position_6.jpg')} alt="The Cockpit and Hold" />
                    <div onClick={() => this.getPic(5)} className="gallery-img-mask" />
                  </div>
                  <div
                    style={{ width: 269 * webWidthScale, height: 151 * webWidthScale, marginLeft: 11 * webWidthScale }}
                  >
                    <img src={require('../image/gallery/Position_7.jpg')} alt="Cockpit Exterior" />
                    <div onClick={() => this.getPic(6)} className="gallery-img-mask" />
                  </div>
                </div>
                <div
                  id="gallery-bottomright"
                  className="item"
                  style={{ width: 549 * webWidthScale, height: 151 * webWidthScale, marginLeft: 11 * webWidthScale }}
                >
                  <div style={{ width: 269 * webWidthScale, height: 151 * webWidthScale }}>
                    <img src={require('../image/gallery/Position_8.jpg')} alt="Cockpit" />
                    <div onClick={() => this.getPic(7)} className="gallery-img-mask" />
                  </div>
                  <div
                    style={{ width: 269 * webWidthScale, height: 151 * webWidthScale, marginLeft: 11 * webWidthScale }}
                  >
                    <img src={require('../image/gallery/Position_10.jpg')} alt="Tunnel Looking Into Hold" />
                    <div onClick={() => this.getPic(8)} className="gallery-img-mask" />
                  </div>
                </div>
              </div>
              <div className="slider" id="slider" ref="slider">
                <div className="slider-item" id="slider-item">
                  <img src={require('../image/gallery/Position_1.jpg')} alt="" style={{ display: 'none' }} />
                  <img src={require('../image/gallery/Position_2.jpg')} alt="" style={{ display: 'none' }} />
                  <img src={require('../image/gallery/Position_3.jpg')} alt="" style={{ display: 'none' }} />
                  <img src={require('../image/gallery/Position_4.jpg')} alt="" style={{ display: 'none' }} />
                  <img src={require('../image/gallery/Position_5.jpg')} alt="" style={{ display: 'none' }} />
                  <img src={require('../image/gallery/Position_6.jpg')} alt="" style={{ display: 'none' }} />
                  <img src={require('../image/gallery/Position_7.jpg')} alt="" style={{ display: 'none' }} />
                  <img src={require('../image/gallery/Position_8.jpg')} alt="" style={{ display: 'none' }} />
                  <img src={require('../image/gallery/Position_10.jpg')} alt="" style={{ display: 'none' }} />
                </div>
                <div
                  className="left-arrow"
                  onClick={this.left}
                  style={{
                    width: 14 * webWidthScale,
                    height: 30 * webWidthScale,
                    position: 'absolute',
                    top: 225 * webWidthScale,
                    left: -20 * webWidthScale
                  }}
                >
                  <img
                    src={require('../image/common/gallery-left.png')}
                    alt="Scroll Left"
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
                <div
                  className="right-arrow"
                  onClick={this.right}
                  style={{
                    width: 14 * webWidthScale,
                    height: 30 * webWidthScale,
                    position: 'absolute',
                    top: 225 * webWidthScale,
                    right: -20 * webWidthScale
                  }}
                >
                  <img
                    src={require('../image/common/gallery-right.png')}
                    alt="Scroll Right"
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Gallery = withRouter(injectIntl(Gallery));
export default Gallery;
