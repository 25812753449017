import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
//import '../css/mobileService.css';
//import '../css/slide.css';
import '../css/accessoriesSection.css';

class Accessories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: document.documentElement.clientWidth
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.resize);
  }
  resize = () => {
    this.setState({
      width: document.documentElement.clientWidth
    });
  };
  //		this.ban();
  componentDidMount = () => {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      //      this.autoPlay()
    }
  };

  render() {
    const { intl } = this.props;
    let accessoriessection01 = intl.formatMessage({ id: 'accessoriessection01' });
    let accessoriessection01a = intl.formatMessage({ id: 'accessoriessection01a' });
    let accessoriessection02 = intl.formatMessage({ id: 'accessoriessection02' });
    let accessoriessection03 = intl.formatMessage({ id: 'accessoriessection03' });
    let accessoriessection04 = intl.formatMessage({ id: 'accessoriessection04' });
    let accessoriessection05 = intl.formatMessage({ id: 'accessoriessection05' });
    let accessoriessection06 = intl.formatMessage({ id: 'accessoriessection06' });
    let accessoriessection07 = intl.formatMessage({ id: 'accessoriessection07' });
    let accessoriessection07b = intl.formatMessage({ id: 'accessoriessection07b' });
    let installmentavailable = intl.formatMessage({ id: 'installmentavailable' });
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile-ma">
        <div className="mobile-service" id="mobileService" style={{ paddingBottom: 37 }}>
            <div className="accessories-section">
                <div className="section-title">{accessoriessection01}</div>
                <div className="section-desc">{accessoriessection01a}</div>
                <div className="section-row">
                    <Link to={`/btmf/products`}>
                        <div className="section-row-item-see-all">
                            <div className="section-item-label-see-all">{accessoriessection02}</div>
                        </div>
                    </Link>
                    <Link to={`/btmf/product/btmf-classic-plaque`}>
                        <div className="section-row-item section-row-item-bg01b">
                            <div className="section-item-label-top">{accessoriessection03}</div>
                            <div className="section-item-stripe-top"></div>
                        </div>
                    </Link>
                    <Link to={`/btmf/product/btmf-technical-plaque`}>
                        <div className="section-row-item section-row-item-bg02b">
                            <div className="section-item-label-top">{accessoriessection04}</div>
                            <div className="section-item-stripe-top"></div>
                        </div>
                    </Link>
                    <Link to={`/btmf/product/btmf-plaque-bundle`}>
                        <div className="section-row-item section-row-item-bg03b">
                            <div className="section-item-label-top">{accessoriessection05}</div>
                            <div className="section-item-stripe-top"></div>
                        </div>
                    </Link>
                </div>
                <div className="section-row">
                    <Link to={`/btmf/product/btmf-metal-table-top-stand`}>
                        <div className="section-row-item section-row-item-bg04b">
                            <div className="section-item-label-top">{accessoriessection06}</div>
                            <div className="section-item-stripe-top"></div>
                            <div className="section-item-mask"></div>
                        </div>
                    </Link>
                    <Link to={`/btmf/product/btmf-acrylic-floor-stand`}>
                        <div className="section-row-item section-row-item-bg05">
                            <div className="section-item-label-top">{accessoriessection07}</div>
                            <div className="section-item-stripe-top"></div>
                            <div className="section-item-mask"></div>
                        </div>
                    </Link>
                    <Link to={`/btmf/product/btmf-wall-mount`}>
                        <div className="section-row-item section-row-item-bg06">
                            <div className="section-item-label-top">{accessoriessection07b}</div>
                            <div className="section-item-stripe-top"></div>
                            <div className="section-item-mask"></div>
                        </div>
                    </Link>
                </div>
                <h4>{installmentavailable}</h4>
            </div>
        </div>
      </div>
    ) : (
      <div className="component first-component">
        <div className="service" id="webService">
          <div className="service-content accessories-section">
            <div className="accessories-section-holder">
                <div className="accessories-section-center">
                    <div className="section-title">{accessoriessection01}</div>
                    <div className="section-desc">{accessoriessection01a}</div>
                    <div className="section-row">
                        <Link to={`/btmf/product/btmf-classic-plaque`}>
                            <div className="section-row-item section-row-item-bg01">
                                <div className="section-item-label-top">{accessoriessection03}</div>
                                <div className="section-item-stripe-top"></div>
                                <div className="section-item-mask"></div>
                            </div>
                        </Link>
                        <Link to={`/btmf/product/btmf-technical-plaque`}>
                            <div className="section-row-item section-row-item-bg02">
                                <div className="section-item-label-top">{accessoriessection04}</div>
                                <div className="section-item-stripe-top"></div>
                                <div className="section-item-mask"></div>
                            </div>
                        </Link>
                        <Link to={`/btmf/product/btmf-plaque-bundle`}>
                            <div className="section-row-item section-row-item-bg03">
                                <div className="section-item-label-top">{accessoriessection05}</div>
                                <div className="section-item-stripe-top"></div>
                                <div className="section-item-mask"></div>
                            </div>
                        </Link>
                    </div>
                    <div className="section-row">
                        <Link to={`/btmf/product/btmf-metal-table-top-stand`}>
                            <div className="section-row-item section-row-item-bg04">
                                <div className="section-item-label-top">{accessoriessection06}</div>
                                <div className="section-item-stripe-top"></div>
                                <div className="section-item-mask"></div>
                            </div>
                        </Link>
                        <Link to={`/btmf/product/btmf-acrylic-floor-stand`}>
                            <div className="section-row-item section-row-item-bg05">
                                <div className="section-item-label-top">{accessoriessection07}</div>
                                <div className="section-item-stripe-top"></div>
                                <div className="section-item-mask"></div>
                            </div>
                        </Link>
                        <Link to={`/btmf/product/btmf-wall-mount`}>
                            <div className="section-row-item section-row-item-bg06">
                                <div className="section-item-label-top">{accessoriessection07b}</div>
                                <div className="section-item-stripe-top"></div>
                                <div className="section-item-mask"></div>
                            </div>
                        </Link>
                    </div>
                    <div className="section-row">
                        <Link to={`/btmf/products`}>
                            <div className="section-row-item-see-all">
                                <div className="section-item-label-see-all">{accessoriessection02}</div>
                            </div>
                        </Link>
                    </div>
                    <h4>{installmentavailable}</h4>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Accessories = injectIntl(Accessories);
export default Accessories;
