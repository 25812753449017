import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import Root from './root';
import zhCN from './language/zh';
import zhTW from './language/tw';
import zhHK from './language/hk';
import enUS from './language/en';
import { IntlProvider, addLocaleData } from 'react-intl';
import createHistory from 'history/createBrowserHistory';
import config from './config';

import { apiRequest } from 'stktwlib'

import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
addLocaleData([...en, ...zh]);

const history = createHistory();

class App extends Component {
  componentWillMount() {
    function appHeight() {
      const doc = document.documentElement
      doc.style.setProperty('--vh', (window.innerHeight*.01) + 'px');
    }
  
    window.addEventListener('resize', appHeight);
    appHeight();
    let self = this;
    localStorage.setItem('lang','tw')
    self.setState({
      locale: 'zh',
      messages: zhTW
    });
    apiRequest.setBaseUrl(config.apiBaseUrl);
    apiRequest.setR2D2Url("/");
    apiRequest.setPaymentUrl(config.payurl);
    apiRequest.setMerchantID(config.merchantID);
    apiRequest.setWebBaseURL("/btmf");
  }
  render() {
    return (
      <IntlProvider locale={this.state.locale} messages={this.state.messages}>
        <Router history={history}>
          <Root />
        </Router>
      </IntlProvider>
    );
  }
}
export default App;
