import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import * as request from '../apiClient/request';
var webWidthScale = document.documentElement.clientWidth / 1440;
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mcemail:""
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.resize);
  }
  resize() {
    webWidthScale = document.documentElement.clientWidth / 1440;
  }
  componentDidMount() {}
  tc() {
    if (this.props.history.location.pathname !== '/btmf/terms-and-conditions') this.props.history.push('/btmf/terms-and-conditions');
    localStorage.setItem('tc', true);
  }
  about() {
    if (this.props.history.location.pathname !== '/btmf/about') this.props.history.push('/btmf/about');
    localStorage.setItem('about', true);
  }
  privacypolicy() {
    if (this.props.history.location.pathname !== '/btmf/privacy-policy') this.props.history.push('/btmf/privacy-policy');
    localStorage.setItem('privacypolicy', true);
  }
  useofwebsite() {
    if (this.props.history.location.pathname !== '/btmf/use-of-website') this.props.history.push('/btmf/use-of-website');
    localStorage.setItem('useofwebsite', true);
  }
  render() {
    const { intl } = this.props;

    let footernewslettersloganA = intl.formatMessage({ id: 'footernewslettersloganA' });
    let footernewslettersloganB = intl.formatMessage({ id: 'footernewslettersloganB' });
    let footernewslettersloganm = intl.formatMessage({ id: 'footernewslettersloganm' });
    let footernewslettersloganm1 = intl.formatMessage({ id: 'footernewslettersloganm1' });
    let footernewsletterslogan2 = intl.formatMessage({ id: 'footernewsletterslogan2' });
    let footernewsletteremail = intl.formatMessage({ id: 'footernewsletteremail' });
    let footernewsletterbutton = intl.formatMessage({ id: 'footernewsletterbutton' });
    let footernewsletterfineprint = intl.formatMessage({ id: 'footernewsletterfineprint' });
    let footerquestions = intl.formatMessage({ id: 'footerquestions' });
    let footercontactphone = intl.formatMessage({ id: 'footercontactphone' });
    let footercontactphone2 = intl.formatMessage({ id: 'footercontactphone2' });
    let footercontactemail = intl.formatMessage({ id: 'footercontactemail' });
    let footercontactwhatsapp = intl.formatMessage({ id: 'footercontactwhatsapp' });
    let footercontactonline = intl.formatMessage({ id: 'footercontactonline' });
    let downloadapp = intl.formatMessage({ id: 'downloadapp' });
    let shopping2018 = intl.formatMessage({ id: 'shopping2018' });
    let shoppingterms = intl.formatMessage({ id: 'shoppingterms' });
    let privacyPolicy = intl.formatMessage({ id: 'privacyPolicy' });
    let useOfWebsite = intl.formatMessage({ id: 'useOfWebsite' });
    let aboutustitle = intl.formatMessage({ id: 'aboutustitle' });
    let emf1 = intl.formatMessage({ id: 'emf1' });
    let emf2 = intl.formatMessage({ id: 'emf2' });
    let emf3 = intl.formatMessage({ id: 'emf3' });
    let emf4 = intl.formatMessage({ id: 'emf4' });

    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile-ma-shopping">
        <div className="mobile-shopping mobile-footer" id="mobileFooter">
          <div className="footer-questions">
            <div className="footer-questions-block">
              <p className="footer-questions-heading">{footerquestions}</p>
              <p className="footer-questions-content">{footercontactphone}</p>
              <p className="footer-questions-content">{footercontactphone2}</p>
              <p className="footer-questions-content">{footercontactemail}</p>
              <p className="footer-questions-content">{footercontactwhatsapp}</p>
              <p style={{color:'#fff',fontSize:'14px',lineHeight:'18px', marginTop:'10px'}}>
                {emf1}<br />
                {emf2}<br />
                {emf3}<br />
                {emf4}<br />
              </p>
              <p className="footer-questions-content" style={{marginTop:'15px'}}>{downloadapp}</p>
              <a
              href="https://apps.apple.com/tw/app/btmf-control/id1514326227"
              target="_blank"
              rel="noopener noreferrer"
              >
              <img style={{height: '40px'}} src={require('../image/brand/appstore.png')} alt="BTMF App" />
              </a>
              <a
              href="https://play.google.com/store/apps/details?id=com.mllennium.eagle"
              target="_blank"
              rel="noopener noreferrer"
              >
              <img style={{height: '40px'}} src={require('../image/brand/googleplay.png')} alt="BTMF App" />
              </a>
            </div>
            <div className="footer-questions-block">
              <p className="footer-questions-heading">{footercontactonline}</p>
              <div className="footer-social">
                <a
                  href="https://www.facebook.com/stkworkshoptw/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require('../image/brand/fb@3x.png')} alt="STK Workshop Facebook" />
                </a>
                <a href="https://www.instagram.com/stkworkshop_taiwan/" target="_blank" rel="noopener noreferrer">
                  <img src={require('../image/brand/ig@3x.png')} alt="STK Workshop Instagram" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UClwt44Lndl-mgx8ZwoWSdhQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require('../image/brand/youtube@3x.png')} alt="STK Workshop YouTube" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-newsletter-sign-up">
            <p className="footer-newsletter-heading">
              {footernewslettersloganm}
              <br />
              {footernewslettersloganm1}
            </p>
            <p className="footer-newsletter-heading2">{footernewsletterslogan2}</p>
            <div className="footer-newsletter-sign-up-box">
              <form action="https://stk-workshop.us19.list-manage.com/subscribe/post" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                <input type="hidden" name="u" value="dbb97ed597bf7f400423d3a6e"/>
                <input type="hidden" name="id" value="809bc77220"/>

                <input type="email" placeholder={footernewsletteremail} value={this.state.mcemail} onChange={(e)=>{this.setState({mcemail:e.target.value});}} name="EMAIL" className="required email" id="mce-EMAIL" />
                <input type="submit" value={footernewsletterbutton} name="subscribe" id="mc-embedded-subscribe" />
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_dbb97ed597bf7f400423d3a6e_809bc77220" tabIndex="-1" value="" /></div>
              </form>
            </div>
            <p className="footer-newsletter-fineprint">{footernewsletterfineprint}</p>
          </div>
          <div className="mobile-stk-copyright">
            <p>{shopping2018}</p>
            <p>
              <a onClick={() => this.about()}>{aboutustitle}</a>
              <span>|</span>
              <a onClick={() => this.tc()}>{shoppingterms}</a>
            </p>
            <p>
              <a onClick={() => this.privacypolicy()}>{privacyPolicy}</a>
              <span>|</span>
              <a onClick={() => this.useofwebsite()}>{useOfWebsite}</a>
            </p>
          </div>
        </div>
      </div>
    ) : (
      <div className="component first-component">
        <div className="gallery footer-bg" id="webFooter">
          <div className="null" style={{ minHeight: 104, height: 139 * webWidthScale, maxHeight: 150 }} />
          <div className="shopping-content footer-content">
            <div className="forter-info">
              <div className="footer-newsletter-sign-up">
                <p className="footer-newsletter-heading">
                  {footernewslettersloganA}
                  <br />
                  {footernewslettersloganB}
                </p>
                <p className="footer-newsletter-heading2">{footernewsletterslogan2}</p>
                <div className="footer-newsletter-sign-up-box">
                  <form action="https://stk-workshop.us19.list-manage.com/subscribe/post" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                    <input type="hidden" name="u" value="dbb97ed597bf7f400423d3a6e"/>
                    <input type="hidden" name="id" value="809bc77220"/>

                    <input type="email" placeholder={footernewsletteremail} value={this.state.mcemail} onChange={(e)=>{this.setState({mcemail:e.target.value});}} name="EMAIL" className="required email" id="mce-EMAIL" />
                    <input type="submit" value={footernewsletterbutton} name="subscribe" id="mc-embedded-subscribe" />
                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_dbb97ed597bf7f400423d3a6e_809bc77220" tabIndex="-1" value="" /></div>
                  </form>
                </div>
                <p className="footer-newsletter-fineprint">{footernewsletterfineprint}</p>
              </div>
              <div className="stk-copyright emf">
                <p style={{color:'#fff', width:'310px'}}>
                    {emf1} {emf2}<br />
                    {emf3}<br />
                </p>
                <p>{shopping2018}</p>
                <p>
                  <a onClick={() => this.about()}>{aboutustitle}</a>
                  <span>|</span>
                  <a onClick={() => this.tc()}>{shoppingterms}</a>
                  <span>|</span>
                  <a onClick={() => this.privacypolicy()}>{privacyPolicy}</a>
                  <span>|</span>
                  <a onClick={() => this.useofwebsite()}>{useOfWebsite}</a>
                </p>
              </div>
              <div className="footer-questions">
                <div className="footer-questions-block">
                  <p className="footer-questions-heading">{footerquestions}</p>
                  <p className="footer-questions-content">{footercontactphone}</p>
                  <p className="footer-questions-content">{footercontactphone2}</p>
                  <p className="footer-questions-content">{footercontactemail}</p>
                  <p className="footer-questions-content">{footercontactwhatsapp}</p>
                  <p className="footer-questions-content" style={{marginTop:'15px'}}>{downloadapp}</p>
                  <a
                  href="https://apps.apple.com/tw/app/btmf-control/id1514326227"
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  <img style={{height: '40px'}} src={require('../image/brand/appstore.png')} alt="BTMF App" />
                  </a>
                  <a
                  href="https://play.google.com/store/apps/details?id=com.mllennium.eagle"
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  <img style={{height: '40px'}} src={require('../image/brand/googleplay.png')} alt="BTMF App" />
                  </a>
                </div>
                <div className="footer-questions-block">
                  <p className="footer-questions-heading">{footercontactonline}</p>
                  <div className="footer-social">
                    <a
                      href="https://www.facebook.com/stkworkshoptw/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={require('../image/brand/fb@3x.png')} alt="STK Workshop Facebook" />
                    </a>
                    <a href="https://www.instagram.com/stkworkshop_taiwan/" target="_blank" rel="noopener noreferrer">
                      <img src={require('../image/brand/ig@3x.png')} alt="STK Workshop Instagram" />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UClwt44Lndl-mgx8ZwoWSdhQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={require('../image/brand/youtube@3x.png')} alt="STK Workshop YouTube" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Footer = withRouter(injectIntl(Footer));
export default Footer;
