import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import '../css/mobileBox.css';
import '../css/mobileShopping.css';
import '../css/homeOffers.css';
import '../css/homeOffers2.css';
import * as request from '../apiClient/request';
var time_end = new Date('2018/12/26 00:00:00').getTime();
var time_now = new Date().getTime();
var shoppingSlide1On = true;
const offer1regprice = 4480;
//const offer1price = 3980;
const offer1price = '4,480';
const offer1saveprice = 500;

const offer2regprice = 6380;
//const offer2price = 5680;
const offer2price = '6,380';
const offer2saveprice = 700;
const offer3reginitprice = 4480;
const offer3initprice = 4480;
const offer3regrecurprice = 4480;
const offer3recurprice = 3990;
const offer3savecost = 4410;
const offer4regprice = 53480;
const offer4price = 53480;
//const trialpackprice = 680;
const trialpackprice = 680;
const trialpackregprice = 880;
class Shopping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: document.documentElement.clientWidth,
      time_distance: time_end - time_now,
      int_day: 0,
      int_hour: 0,
      int_minute: 0,
      int_second: 0,
      onSale: false,
      freeGiftDialog: false
    };
  }
  componentDidMount() {
    this.show_time();
  }
  show_time() {
    time_now = new Date().getTime();
    this.setState({
      time_distance: time_end - time_now
    });
    if (this.state.time_distance > 0) {
      this.setState({
        int_day: Math.floor(this.state.time_distance / 1000 / 60 / 60 / 24),
        int_hour: Math.floor((this.state.time_distance / 1000 / 60 / 60) % 24),
        int_minute: Math.floor((this.state.time_distance / 1000 / 60) % 60),
        int_second: Math.floor((this.state.time_distance / 1000) % 60)
      });
      setTimeout(() => this.show_time(), 1000);
    }
  }
  addCart(key) {
    const { intl } = this.props;
    let subscriptionmorethanone1 = intl.formatMessage({ id: 'subscriptionmorethanone1' });
    let subscriptionmorethanone2 = intl.formatMessage({ id: 'subscriptionmorethanone2' });
    let subscriptionmorethanone3 = intl.formatMessage({ id: 'subscriptionmorethanone3' });
    let alert3 = intl.formatMessage({ id: 'alert3' });
    let alert4 = intl.formatMessage({ id: 'alert4' });
    let alert5 = intl.formatMessage({ id: 'alert5' });
    let alert6 = intl.formatMessage({ id: 'alert6' });
    let orderconflictnote = intl.formatMessage({ id: 'orderconflictnote' });
    let orderconflictnote2 = intl.formatMessage({ id: 'orderconflictnote2' });
    let alert7 = intl.formatMessage({ id: 'alert7' });
    let data = {
      shopping_cart_key: localStorage.cartKey,
      product_key: key
    };
    request
      .post('api/v1/shopping_carts/add_product_to_shopping_cart', data, 1, 22)
      .then(res => {
        //console.log(res);
        localStorage.setItem('cartItem', JSON.stringify(res.data.response.shopping_cart_items));
        // alert(alert7);
        if (this.props.history.location.pathname !== '/btmf/cart1') this.props.history.push('/btmf/cart1');
        var cartItem = JSON.parse(localStorage.cartItem);
        this.props.cartNumber(cartItem.length);
      })
      .catch(err => {
        console.log('err');
        console.log(err);
        let str = JSON.stringify(err);
        console.log('err2');

        console.log(err);
        console.log(err.response);

        if (err.response && err.response.status === 403) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop(alert3, alert4, orderconflictnote, orderconflictnote2);
          } else {
            this.props.showpop(alert5, alert6, orderconflictnote, orderconflictnote2);
          }
          // alert('111')
        }
        if (err.response && err.response.status === 400) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop(
              subscriptionmorethanone1,
              subscriptionmorethanone2,
              subscriptionmorethanone3,
              orderconflictnote2
            );
          }
          // alert('111')
        }
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          this.push('/btmf/');
          // alert('111')
        }
      });
  }
  addCart1(key) {
    const { intl } = this.props;
    let subscriptionmorethanone1 = intl.formatMessage({ id: 'subscriptionmorethanone1' });
    let subscriptionmorethanone2 = intl.formatMessage({ id: 'subscriptionmorethanone2' });
    let subscriptionmorethanone3 = intl.formatMessage({ id: 'subscriptionmorethanone3' });
    let alert3 = intl.formatMessage({ id: 'alert3' });
    let alert4 = intl.formatMessage({ id: 'alert4' });
    let alert5 = intl.formatMessage({ id: 'alert5' });
    let alert6 = intl.formatMessage({ id: 'alert6' });
    let orderconflictnote = intl.formatMessage({ id: 'orderconflictnote' });
    let orderconflictnote2 = intl.formatMessage({ id: 'orderconflictnote2' });
    let alert7 = intl.formatMessage({ id: 'alert7' });
    let data = {
      shopping_cart_key: localStorage.cartKey,
      product_key: key
    };
    request
      .post('api/v1/shopping_carts/add_product_to_shopping_cart', data, 1, 22)
      .then(res => {
        //console.log(res);
        localStorage.setItem('cartItem', JSON.stringify(res.data.response.shopping_cart_items));
        // alert(alert7)
        if (this.props.history.location.pathname !== '/btmf/cart1') this.props.history.push('/btmf/cart1');
        var cartItem = JSON.parse(localStorage.cartItem);
        this.props.cartNumber(cartItem.length);
      })
      .catch(err => {
        console.log('err');
        console.log(err);
        let str = JSON.stringify(err);
        console.log('err2');

        console.log(err);
        console.log(err.response);

        if (err.response && err.response.status === 403) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop1(alert3, alert4, orderconflictnote, orderconflictnote2);
          } else {
            this.props.showpop1(alert5, alert6, orderconflictnote, orderconflictnote2);
          }
          // alert('111')
        }
        if (err.response && err.response.status === 400) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop(
              subscriptionmorethanone1,
              subscriptionmorethanone2,
              subscriptionmorethanone3,
              orderconflictnote2
            );
          }
          // alert('111')
        }
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          this.push('/btmf/');
          // alert('111')
        }
      });
  }
  changeShoppingSlide() {
    if (shoppingSlide1On) {
      document.getElementById('shopping-slide1').style.display = 'none';
      document.getElementById('shopping-slide2').style.display = 'block';
      shoppingSlide1On = false;
    } else {
      document.getElementById('shopping-slide2').style.display = 'none';
      document.getElementById('shopping-slide1').style.display = 'block';
      shoppingSlide1On = true;
    }
  }
  toggleFreeGiftDialog() {
    if (this.state.freeGiftDialog) {
      this.setState({
        freeGiftDialog: false
      });
    } else {
      this.setState({
        freeGiftDialog: true
      });
    }
  }
  closeFreeGift() {
    this.setState({
      freeGiftDialog: false
    });
  }
  render() {
    const { intl } = this.props;
    let boxmobileday = intl.formatMessage({ id: 'boxmobileday' });
    let boxmobilehour = intl.formatMessage({ id: 'boxmobilehour' });
    let boxmobileminute = intl.formatMessage({ id: 'boxmobileminute' });
    let boxmobilesecond = intl.formatMessage({ id: 'boxmobilesecond' });
    let shoppingspecial = intl.formatMessage({ id: 'shoppingspecial' });
    let modelsixpack1 = intl.formatMessage({ id: 'modelsixpack1' });
    let modelsixpack1a = intl.formatMessage({ id: 'modelsixpack1a' });
    let modelsixpack1b = intl.formatMessage({ id: 'modelsixpack1b' });
    let shoppingstart = intl.formatMessage({ id: 'shoppingstart' });
    let shoppingbuild = intl.formatMessage({ id: 'shoppingbuild' });
    let shoppingregular = intl.formatMessage({ id: 'shoppingregular' });
    let shoppingadd = intl.formatMessage({ id: 'shoppingadd' });
    let shoppingtheM1 = intl.formatMessage({ id: 'shoppingtheM1' });
    let shoppingtheM2 = intl.formatMessage({ id: 'shoppingtheM2' });
    let shoppingthe = intl.formatMessage({ id: 'shoppingthe' });
    let shoppingsubscribe = intl.formatMessage({ id: 'shoppingsubscribe' });
    let shoppingpack1head = intl.formatMessage({ id: 'shoppingpack1head' });
    let shoppingpack1head2 = intl.formatMessage({ id: 'shoppingpack1head2' });
    let shoppingpack1 = intl.formatMessage({ id: 'shoppingpack1' });
    let shoppingpack1a = intl.formatMessage({ id: 'shoppingpack1a' });
    let shopping10 = intl.formatMessage({ id: 'shopping10' });
    let shopping10M = intl.formatMessage({ id: 'shopping10M' });
    let shopping10a = intl.formatMessage({ id: 'shopping10a' });
    let shopping10a2 = intl.formatMessage({ id: 'shopping10a2' });
    let shopping10a2b = intl.formatMessage({ id: 'shopping10a2b' });
    let shopping10a2c = intl.formatMessage({ id: 'shopping10a2c' });
    let shopping10a2d = intl.formatMessage({ id: 'shopping10a2d' });
    let shopping10b = intl.formatMessage({ id: 'shopping10b' });
    let shopping10c = intl.formatMessage({ id: 'shopping10c' });
    let shoppingmonth = intl.formatMessage({ id: 'shoppingmonth' });
    let shoppingCompleteKitRegPrice = intl.formatMessage({ id: 'shoppingCompleteKitRegPrice' });
    let shoppingCompleteKitRegPrice2 = intl.formatMessage({ id: 'shoppingCompleteKitRegPrice2' });
    let shoppingnow = intl.formatMessage({ id: 'shoppingnow' });
    let shoppingcraftsmen = intl.formatMessage({ id: 'shoppingcraftsmen' });
    let shoppingcraftsmen1 = intl.formatMessage({ id: 'shoppingcraftsmen1' });
    let shoppingcraftsmen2 = intl.formatMessage({ id: 'shoppingcraftsmen2' });
    let shoppingcraftsmen3 = intl.formatMessage({ id: 'shoppingcraftsmen3' });
    let trialpack01 = intl.formatMessage({ id: 'trialpack01' });
    let trialpack02 = intl.formatMessage({ id: 'trialpack02' });
    let trialpack03 = intl.formatMessage({ id: 'trialpack03' });
    let trialpack04 = intl.formatMessage({ id: 'trialpack04' });
    let shoppingCompleteServiceDesc = intl.formatMessage({ id: 'shoppingCompleteServiceDesc' });
    let shoppingCompleteServiceDesc2 = intl.formatMessage({ id: 'shoppingCompleteServiceDesc2' });
    let shoppingCompleteServiceDesc3 = intl.formatMessage({ id: 'shoppingCompleteServiceDesc3' });
    let shoppingCompleteServiceDesc4 = intl.formatMessage({ id: 'shoppingCompleteServiceDesc4' });
    let shoppingPackOneKitDesc = intl.formatMessage({ id: 'shoppingPackOneKitDesc' });
    let shoppingPackOneKitDesc2 = intl.formatMessage({ id: 'shoppingPackOneKitDesc2' });
    let shoppingPackOneKitDesc3 = intl.formatMessage({ id: 'shoppingPackOneKitDesc3' });
    let shoppingall1 = intl.formatMessage({ id: 'shoppingall1' });
    let shoppingall2 = intl.formatMessage({ id: 'shoppingall2' });
    let shoppingPackOneServiceDesc = intl.formatMessage({ id: 'shoppingPackOneServiceDesc' });
    let shoppingPackOneServiceDesc2 = intl.formatMessage({ id: 'shoppingPackOneServiceDesc2' });
    let shoppingPackOneServiceDesc3 = intl.formatMessage({ id: 'shoppingPackOneServiceDesc3' });
    let shoppingthe1a = intl.formatMessage({ id: 'shoppingthe1a' });
    let shoppingthe1b = intl.formatMessage({ id: 'shoppingthe1b' });
    let shoppingfree1 = intl.formatMessage({ id: 'shoppingfree1' });
    let shoppingfree2 = intl.formatMessage({ id: 'shoppingfree2' });
    let shoppingfree3 = intl.formatMessage({ id: 'shoppingfree3' });
    let shoppingfree4 = intl.formatMessage({ id: 'shoppingfree4' });
    let shoppingfree5 = intl.formatMessage({ id: 'shoppingfree5' });
    let shoppingswapanchor = intl.formatMessage({ id: 'shoppingswapanchor' });
    let shoppingswap = intl.formatMessage({ id: 'shoppingswap' });
    let shoppingswapanchor2 = intl.formatMessage({ id: 'shoppingswapanchor2' });
    let shoppingswap2 = intl.formatMessage({ id: 'shoppingswap2' });
    let freegifttitle = intl.formatMessage({ id: 'freegifttitle' });
    let freegiftp1 = intl.formatMessage({ id: 'freegiftp1' });
    let freegiftdisclaimer1 = intl.formatMessage({ id: 'freegiftdisclaimer1' });
    let freegiftdisclaimer2 = intl.formatMessage({ id: 'freegiftdisclaimer2' });
    let freegiftdisclaimer3 = intl.formatMessage({ id: 'freegiftdisclaimer3' });
    let homeScroll = intl.formatMessage({ id: 'homeScroll' });
    let installmentavailable = intl.formatMessage({ id: 'installmentavailable' });
    let moredetail = intl.formatMessage({ id: 'moredetail' });

    let twCurrency = intl.formatMessage({ id: 'twCurrency' });
    let savePrice = intl.formatMessage({ id: 'savePrice' });
    let regularPrice = intl.formatMessage({ id: 'regularPrice' });

    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile-ma">
        {this.state.freeGiftDialog ? (
          <div className="concept-store-popup">
            <div className="concept-store-inner">
              <div className="close-btn" onClick={() => this.closeFreeGift()}>
                x
              </div>
              <h3>{freegifttitle}</h3>
              <div className="free-gift-image-wrapper">
                <img src={require('../image/home/free-gift-item.jpeg')} alt="Free Gift" />
              </div>
              <div className="free-gift-text-wrapper">
                <div className="free-gift-top-text">{freegiftp1}</div>
                <div className="free-gift-disclaimer">
                  {freegiftdisclaimer1}
                  <br />
                  {freegiftdisclaimer2}
                  <br />
                  {freegiftdisclaimer3}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="mobile-shopping" id="mobileShopping">
          <div className="mobile-shopping-item" style={{ marginBottom: 0 }}>
            <div className="mobile-shopping-item-title">
              <h1>{shoppingpack1head}</h1>
              <h4>{shoppingpack1head2}</h4>
            </div>
            <div className="mobile-shopping-item-pic">
              <img src={require('../video/shopping-pic1.png')} alt="Pack One - The Cockpit and Hold" />
            </div>
            <div className="mobile-shopping-item-details">
              <div className="mobile-shopping-kit">
                <h2>
                  {shoppingpack1}
                  <br />
                  {shoppingpack1a}
                </h2>
                <p className="desc1">
                  {shoppingall1}
                  <br />
                  {shoppingall2}
                </p>
                {this.state.onSale ? (
                  <React.Fragment>
                    <span
                      style={{
                        display: 'block',
                        marginBottom: 2,
                        color: 'white',
                        fontWeight: 'bold',
                        textAlign: 'center'
                      }}
                    >
                      {regularPrice}
                      <span
                        style={{ textDecorationLine: 'line-through' }}
                      >{`${twCurrency} ${offer1regprice}`}</span>{' '}
                    </span>
                    <span
                      style={{
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize: 15,
                        display: 'block',
                        marginBottom: 15,
                        textAlign: 'center'
                      }}
                    >
                      {savePrice}
                      {offer1saveprice}
                    </span>
                  </React.Fragment>
                ) : null}
                <div className="installment-available">{installmentavailable}</div>
                <button
                  onClick={() => this.addCart1('btmf_build_yourself_pack_1_reg')}
                >{`${twCurrency} ${offer1price}`}</button>
              </div>
              <div className="mobile-shopping-stk mobile-shopping-kit">
                <div className="mobile-shopping-stk-icon">
                  <img
                    src={require('../image/home/stk_logo_build_service_button@3x@3x.png')}
                    alt="Build Service Logo"
                  />
                </div>
                <p className="desc1">
                  {shoppingthe1a}
                  <br />
                  {shoppingthe1b}
                </p>
                {this.state.onSale ? (
                  <React.Fragment>
                    <span
                      style={{
                        display: 'block',
                        marginBottom: 2,
                        color: 'white',
                        fontWeight: 'bold',
                        textAlign: 'center'
                      }}
                    >
                      {regularPrice}
                      <span style={{ textDecorationLine: 'line-through' }}>
                        {`${twCurrency} ${offer2regprice}`}
                      </span>{' '}
                    </span>
                    <span
                      style={{
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize: 15,
                        display: 'block',
                        marginBottom: 15,
                        textAlign: 'center'
                      }}
                    >
                      {savePrice}
                      {offer2saveprice}
                    </span>
                  </React.Fragment>
                ) : null}
                <div className="installment-available">{installmentavailable}</div>
                <button
                  onClick={() => this.addCart1('btmf_build_service_pack_1_reg')}
                >{`${twCurrency} ${offer2price}`}</button>
              </div>
            </div>
          </div>
          <div className="mobileFree" style={{ float: 'initial' }}>
            {/*shoppingfree5*/}
          </div>

          <div className="mobile-shopping-item" style={{ marginBottom: 0 }}>
            <div className="mobile-shopping-item-pic">
              <img src={require('../image/home/trial-pack.png')} alt="Pack One - The Cockpit and Hold" />
            </div>
            <div className="mobile-shopping-item-details">
              <div className="mobile-shopping-kit">
                <h2>{trialpack01}</h2>
                <p className="desc1">
                  {trialpack02}
                  <br />
                  {trialpack03}
                  <br />
                  {this.state.onSale && (
                    <React.Fragment>
                      <span
                        className="very-strong bold"
                        style={{ color: 'red', margin: 0, fontSize: 22, lineHeight: '24px', marginTop: 15 }}
                      >{`${twCurrency} ${trialpackprice}`}</span>
                      <span style={{ display: 'block', marginBottom: 2, color: 'white', fontWeight: 'bold' }}>
                        {regularPrice}
                        <span style={{ textDecorationLine: 'line-through' }}>
                          {`${twCurrency} ${trialpackregprice}`}
                        </span>{' '}
                      </span>
                    </React.Fragment>
                  )}
                </p>
                <div className="installment-available">{installmentavailable}</div>
                {/* <Link to={`/btmf/product/btmf-pack-1a-starter-build-yourself`}> */}
                {/*<button onClick={() => this.addCart1('btmf_pack_1a_starter_build_yourself')}>{shoppingadd}{trialpackprice}</button>*/}
                <button>{`${twCurrency} ${trialpackregprice}`}</button>
                {/* </Link> */}
              </div>
            </div>
          </div>
          <div className="mobileFree" style={{ float: 'initial' }}>
            <span className="strengthen">{shoppingfree1}</span>
            {shoppingfree2}
            <span className="strengthen">{shoppingfree3}</span>
            {shoppingfree4}
            {/*shoppingfree5*/}
          </div>
        </div>
      </div>
    ) : (
      <div className="component first-component">
        <div className="shopping shopping2" id="webShopping">
          <div className="null" />
          <div className="shopping-content shopping-content-holder">
            <div className="shopping-content-item-header">
              <div className="title">
                <h2>{shoppingpack1head}</h2>
                <h5>{shoppingpack1head2}</h5>
              </div>
            </div>
            {/* Pack One Shopping */}
            <div className="shopping-content-item shopping-content-item1">
              <div className="goods-details">
                <div className="goods-pic" style={{ float: 'initial' }}>
                  <img src={require('../video/shopping-pic1.png')} alt="Pack One - The Cockpit and Hold" />
                </div>
                <div className="kit-price-info1 kit-info">
                  <h3>
                    {shoppingpack1}
                    <br />
                    {shoppingpack1a}
                  </h3>
                  <p>
                    {shoppingPackOneKitDesc}
                    <br />
                    {shoppingPackOneKitDesc2}
                    <br />
                    {shoppingPackOneKitDesc3}
                  </p>
                  <div className="price">
                    {this.state.onSale ? (
                      <React.Fragment>
                        <span style={{ display: 'block', marginBottom: 2, color: 'white', fontWeight: 'bold' }}>
                          {regularPrice}
                          <span
                            style={{ textDecorationLine: 'line-through' }}
                          >{`${twCurrency} ${offer1regprice}`}</span>{' '}
                        </span>
                        <span
                          style={{ color: 'red', fontWeight: 'bold', fontSize: 15, display: 'block', marginBottom: 15 }}
                        >
                          {savePrice}
                          {offer1saveprice}
                        </span>
                      </React.Fragment>
                    ) : null}
                    <div className="installment-available">{installmentavailable}</div>
                    <button className="discount" onClick={() => this.addCart('btmf_build_yourself_pack_1_reg')}>
                      {`${twCurrency} ${offer1price}`}
                    </button>
                  </div>
                </div>
                <div className="stk-price-info1 stk-info">
                  <img
                    src={require('../image/home/stk_logo_build_service_button@3x@3x.png')}
                    alt="Build Service Logo"
                  />
                  <p>
                    {shoppingPackOneServiceDesc}
                    <br />
                    {shoppingPackOneServiceDesc2}
                    <br />
                    {shoppingPackOneServiceDesc3}
                  </p>
                  <div className="price">
                    {this.state.onSale ? (
                      <React.Fragment>
                        <span style={{ display: 'block', marginBottom: 2, color: 'white', fontWeight: 'bold' }}>
                          {regularPrice}
                          <span style={{ textDecorationLine: 'line-through' }}>
                            {`${twCurrency} ${offer2regprice}`}
                          </span>{' '}
                        </span>
                        <span
                          style={{ color: 'red', fontWeight: 'bold', fontSize: 15, display: 'block', marginBottom: 15 }}
                        >
                          {savePrice}
                          {offer2saveprice}
                        </span>
                      </React.Fragment>
                    ) : null}
                    <div className="installment-available">{installmentavailable}</div>
                    <button className="discount" onClick={() => this.addCart('btmf_build_service_pack_1_reg')}>
                      {`${twCurrency} ${offer2price}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Complete Falcon Shopping */}
            <div className="shopping-content-item shopping-content-item2">
              <div className="goods-details">
                <div className="goods-pic" style={{ float: 'initial' }}>
                  <img src={require('../image/home/trial-pack.png')} alt="The Complete Millennium Falcon" />
                </div>
                <div className="kit-price-info2 kit-info trial-kit-box">
                  <h3>{trialpack01}</h3>
                  <p>
                    <span>
                      {trialpack02}
                      <br />
                      {trialpack03}
                    </span>
                    {this.state.onSale && (
                      <React.Fragment>
                        <span
                          className="very-large bold"
                          style={{ color: 'red', margin: 0, fontSize: 22, lineHeight: '24px', marginTop: 15 }}
                        >{`${twCurrency} ${trialpackprice}`}</span>
                        <span style={{ display: 'block', marginBottom: 2, color: 'white', fontWeight: 'bold' }}>
                          {regularPrice}
                          <span style={{ textDecorationLine: 'line-through' }}>
                            {`${twCurrency} ${trialpackregprice}`}
                          </span>{' '}
                        </span>
                      </React.Fragment>
                    )}
                  </p>
                  <div className="price">
                    {/*this.state.onSale ? (<span className="original">
                      {shoppingCompleteKitRegPrice}
                      <br />
                      {shoppingCompleteKitRegPrice2}
                    </span>) : null*/}
                    <div className="installment-available">{installmentavailable}</div>
                    {/* <Link to={`/btmf/product/btmf-pack-1a-starter-build-yourself`}> */}
                    {/*<button className="discount" onClick={() => this.addCart('btmf_pack_1a_starter_build_yourself')}>
                        {shoppingadd}{trialpackprice}
                      </button>*/}
                    <button
                      className="discount"
                      onClick={() => this.addCart('btmf_build_yourself_pack_1a_starter_reg')}
                    >{`${twCurrency} ${trialpackregprice}`}</button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
            {/* Pack One and Complete iPad slider module - this appears and above modules hidden when iPad resolutions */}
            <div className="shopping-slider">
              <div onClick={() => this.changeShoppingSlide()} id="shopping-show-left" className="shopping-show-arrow">
                <img
                  src={require('../image/common/gallery-left.png')}
                  alt="Scroll Left"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
              <div onClick={() => this.changeShoppingSlide()} id="shopping-show-right" className="shopping-show-arrow">
                <img
                  src={require('../image/common/gallery-right.png')}
                  alt="Scroll Right"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
              {/* Pack One Shopping */}
              <div id="shopping-slide1" className="shopping-content-item shopping-slide shopping-slide1">
                <div className="goods-details">
                  <div className="goods-pic" style={{ float: 'initial' }}>
                    <img src={require('../video/shopping-pic1.png')} alt="Pack One - The Cockpit and Hold" />
                  </div>
                  <div className="kit-price-info1 kit-info">
                    <h3>
                      {shoppingpack1}
                      <br />
                      {shoppingpack1a}
                    </h3>
                    <p>
                      {shoppingPackOneKitDesc}
                      <br />
                      {shoppingPackOneKitDesc2}
                      <br />
                      {shoppingPackOneKitDesc3}
                    </p>
                    <div className="price">
                      {this.state.onSale ? (
                        <React.Fragment>
                          <span style={{ display: 'block', marginBottom: 2, color: 'white', fontWeight: 'bold' }}>
                            {regularPrice}
                            <span
                              style={{ textDecorationLine: 'line-through' }}
                            >{`${twCurrency} ${offer1regprice}`}</span>{' '}
                          </span>
                          <span
                            style={{
                              color: 'red',
                              fontWeight: 'bold',
                              fontSize: 15,
                              display: 'block',
                              marginBottom: 15
                            }}
                          >
                            {savePrice}
                            {offer1saveprice}
                          </span>
                        </React.Fragment>
                      ) : null}
                      <div className="installment-available">{installmentavailable}</div>
                      <button className="discount" onClick={() => this.addCart('btmf_build_yourself_pack_1_reg')}>
                        {`${twCurrency} ${offer1price}`}
                      </button>
                    </div>
                  </div>
                  <div className="stk-price-info1 stk-info">
                    <img
                      src={require('../image/home/stk_logo_build_service_button@3x@3x.png')}
                      alt="Build Service Logo"
                    />
                    <p>
                      {shoppingPackOneServiceDesc}
                      <br />
                      {shoppingPackOneServiceDesc2}
                      <br />
                      {shoppingPackOneServiceDesc3}
                    </p>
                    <div className="price">
                      {this.state.onSale ? (
                        <React.Fragment>
                          <span style={{ display: 'block', marginBottom: 2, color: 'white', fontWeight: 'bold' }}>
                            {regularPrice}
                            <span style={{ textDecorationLine: 'line-through' }}>
                              {`${twCurrency} ${offer2regprice}`}
                            </span>{' '}
                          </span>
                          <span
                            style={{
                              color: 'red',
                              fontWeight: 'bold',
                              fontSize: 15,
                              display: 'block',
                              marginBottom: 15
                            }}
                          >
                            {savePrice}
                            {offer2saveprice}
                          </span>
                        </React.Fragment>
                      ) : null}
                      <div className="installment-available">{installmentavailable}</div>
                      <button className="discount" onClick={() => this.addCart('btmf_build_service_pack_1_reg')}>
                        {`${twCurrency} ${offer2price}`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Complete Falcon Shopping */}
              <div id="shopping-slide2" className="shopping-content-item shopping-slide shopping-slide2">
                <div className="goods-details">
                  <div className="goods-pic" style={{ float: 'initial' }}>
                    <img src={require('../image/home/trial-pack.png')} alt="The Complete Millennium Falcon" />
                  </div>
                  <div className="kit-price-info2 kit-info trial-kit-box">
                    <h3>{trialpack01}</h3>
                    <p>
                      <span>
                        {trialpack02}
                        <br />
                        {trialpack03}
                      </span>
                      {this.state.onSale && (
                        <React.Fragment>
                          <span
                            className="very-large bold"
                            style={{ color: 'red', margin: 0, fontSize: 22, lineHeight: '24px', marginTop: 15 }}
                          >{`${twCurrency} ${trialpackprice}`}</span>
                          <span style={{ display: 'block', marginBottom: 2, color: 'white', fontWeight: 'bold' }}>
                            {regularPrice}
                            <span style={{ textDecorationLine: 'line-through' }}>
                              {`${twCurrency} ${trialpackregprice}`}
                            </span>{' '}
                          </span>
                        </React.Fragment>
                      )}
                    </p>
                    <div className="price">
                      {/*this.state.onSale ? (<span className="original">
                        {shoppingCompleteKitRegPrice}
                        <br />
                        {shoppingCompleteKitRegPrice2}
                      </span>) : null*/}
                      <div className="installment-available">{installmentavailable}</div>
                      {/* <Link to={`/btmf/product/btmf-pack-1a-starter-build-yourself`}> */}
                      {/*<button className="discount" onClick={() => this.addCart('btmf_pack_1a_starter_build_yourself')}>
                          {shoppingadd}{trialpackprice}
                        </button>*/}
                      <button
                        className="discount"
                        onClick={() => this.addCart('btmf_build_yourself_pack_1a_starter_reg')}
                      >{`${twCurrency} ${trialpackregprice}`}</button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ clear: 'both' }} />
            <div className="free">
              <span className="strengthen">{shoppingfree1}</span>
              {shoppingfree2}
              <span className="strengthen">{shoppingfree3}</span>
              {shoppingfree4}
              {/*shoppingfree5*/}
            </div>
          </div>
          <div className="scroll-down">
            <p>{homeScroll}</p>
            <span>
              <i />
            </span>
          </div>
        </div>
      </div>
    );
  }
}
Shopping = withRouter(injectIntl(Shopping));
export default Shopping;
