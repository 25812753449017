import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import '../css/mobileBox.css';
import '../css/mobileShopping.css';
import '../css/homeOffers.css';
import * as request from '../apiClient/request';
var time_end = new Date('2018/12/26 00:00:00').getTime();
var time_now = new Date().getTime();
var shoppingSlide1On = true;
var shoppingSlide3On = true;
const offer1regprice = 4480;
const offer1price = 3980;
const offer2regprice = 6380;
const offer2price = 5680;
const offer3reginitprice = 4480;
//const offer3initprice = 3980;
const offer3initprice = '4,480';
//const offer3regrecurprice = 3990;
const offer3regrecurprice = '3,990';
//const offer3recurprice = 3490;
const offer3recurprice = '3,990';
//const offer3savecost = 5000;
const offer3savecost = 7600;
const offer4regprice = 53480;
//const `NT$ ${offer4price}` = 48480;
const offer4price = '53,480';
const offer4savePrice = 3500;
const fullkitregprice = 40390;
//const `$NT$ {fullkitprice}` = 35390;
const fullkitprice = '39,990';
const fullkitsavecost = 8400;
class Shopping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: document.documentElement.clientWidth,
      time_distance: time_end - time_now,
      int_day: 0,
      int_hour: 0,
      int_minute: 0,
      int_second: 0,
      onSale: false,
      freeGiftDialog: false
    };
  }
  componentDidMount() {
    this.show_time();
  }
  show_time() {
    time_now = new Date().getTime();
    this.setState({
      time_distance: time_end - time_now
    });
    if (this.state.time_distance > 0) {
      this.setState({
        int_day: Math.floor(this.state.time_distance / 1000 / 60 / 60 / 24),
        int_hour: Math.floor((this.state.time_distance / 1000 / 60 / 60) % 24),
        int_minute: Math.floor((this.state.time_distance / 1000 / 60) % 60),
        int_second: Math.floor((this.state.time_distance / 1000) % 60)
      });
      setTimeout(() => this.show_time(), 1000);
    }
  }
  addCart(key) {
    const { intl } = this.props;
    let subscriptionmorethanone1 = intl.formatMessage({ id: 'subscriptionmorethanone1' });
    let subscriptionmorethanone2 = intl.formatMessage({ id: 'subscriptionmorethanone2' });
    let subscriptionmorethanone3 = intl.formatMessage({ id: 'subscriptionmorethanone3' });
    let alert3 = intl.formatMessage({ id: 'alert3' });
    let alert4 = intl.formatMessage({ id: 'alert4' });
    let alert5 = intl.formatMessage({ id: 'alert5' });
    let alert6 = intl.formatMessage({ id: 'alert6' });
    let orderconflictnote = intl.formatMessage({ id: 'orderconflictnote' });
    let orderconflictnote2 = intl.formatMessage({ id: 'orderconflictnote2' });
    let alert7 = intl.formatMessage({ id: 'alert7' });
    let data = {
      shopping_cart_key: localStorage.cartKey,
      product_key: key
    };
    request
      .post('api/v1/shopping_carts/add_product_to_shopping_cart', data, 1, 22)
      .then(res => {
        //console.log(res);
        localStorage.setItem('cartItem', JSON.stringify(res.data.response.shopping_cart_items));
        // alert(alert7);
        if (this.props.history.location.pathname !== '/btmf/cart1') this.props.history.push('/btmf/cart1');
        var cartItem = JSON.parse(localStorage.cartItem);
        this.props.cartNumber(cartItem.length);
      })
      .catch(err => {
        console.log('err');
        console.log(err);
        let str = JSON.stringify(err);
        console.log('err2');

        console.log(err);
        console.log(err.response);

        if (err.response && err.response.status === 403) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop(alert3, alert4, orderconflictnote, orderconflictnote2);
          } else {
            this.props.showpop(alert5, alert6, orderconflictnote, orderconflictnote2);
          }
          // alert('111')
        }
        if (err.response && err.response.status === 400) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop(
              subscriptionmorethanone1,
              subscriptionmorethanone2,
              subscriptionmorethanone3,
              orderconflictnote2
            );
          }
          // alert('111')
        }
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          this.push('/btmf/');
          // alert('111')
        }
      });
  }
  addCart1(key) {
    const { intl } = this.props;
    let subscriptionmorethanone1 = intl.formatMessage({ id: 'subscriptionmorethanone1' });
    let subscriptionmorethanone2 = intl.formatMessage({ id: 'subscriptionmorethanone2' });
    let subscriptionmorethanone3 = intl.formatMessage({ id: 'subscriptionmorethanone3' });
    let alert3 = intl.formatMessage({ id: 'alert3' });
    let alert4 = intl.formatMessage({ id: 'alert4' });
    let alert5 = intl.formatMessage({ id: 'alert5' });
    let alert6 = intl.formatMessage({ id: 'alert6' });
    let orderconflictnote = intl.formatMessage({ id: 'orderconflictnote' });
    let orderconflictnote2 = intl.formatMessage({ id: 'orderconflictnote2' });
    let alert7 = intl.formatMessage({ id: 'alert7' });
    let data = {
      shopping_cart_key: localStorage.cartKey,
      product_key: key
    };
    request
      .post('api/v1/shopping_carts/add_product_to_shopping_cart', data, 1, 22)
      .then(res => {
        //console.log(res);
        localStorage.setItem('cartItem', JSON.stringify(res.data.response.shopping_cart_items));
        // alert(alert7)
        if (this.props.history.location.pathname !== '/btmf/cart1') this.props.history.push('/btmf/cart1');
        var cartItem = JSON.parse(localStorage.cartItem);
        this.props.cartNumber(cartItem.length);
      })
      .catch(err => {
        console.log('err');
        console.log(err);
        let str = JSON.stringify(err);
        console.log('err2');

        console.log(err);
        console.log(err.response);

        if (err.response && err.response.status === 403) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop1(alert3, alert4, orderconflictnote, orderconflictnote2);
          } else {
            this.props.showpop1(alert5, alert6, orderconflictnote, orderconflictnote2);
          }
          // alert('111')
        }
        if (err.response && err.response.status === 400) {
          if (err.response.data.has_installment_production_in_shopping_cart) {
            this.props.showpop(
              subscriptionmorethanone1,
              subscriptionmorethanone2,
              subscriptionmorethanone3,
              orderconflictnote2
            );
          }
          // alert('111')
        }
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          this.push('/btmf/');
          // alert('111')
        }
      });
  }
  changeShoppingSlide() {
    if (shoppingSlide1On) {
      document.getElementById('shopping-slide1').style.display = 'none';
      document.getElementById('shopping-slide2').style.display = 'block';
      shoppingSlide1On = false;
    } else {
      document.getElementById('shopping-slide2').style.display = 'none';
      document.getElementById('shopping-slide1').style.display = 'block';
      shoppingSlide1On = true;
    }
  }
  changeShoppingSlide1() {
    if (shoppingSlide3On) {
      document.getElementById('shopping-slide3').style.display = 'none';
      document.getElementById('shopping-slide4').style.display = 'block';
      shoppingSlide3On = false;
    } else {
      document.getElementById('shopping-slide4').style.display = 'none';
      document.getElementById('shopping-slide3').style.display = 'block';
      shoppingSlide3On = true;
    }
  }
  toggleFreeGiftDialog() {
    if (this.state.freeGiftDialog) {
      this.setState({
        freeGiftDialog: false
      });
    } else {
      this.setState({
        freeGiftDialog: true
      });
    }
  }
  closeFreeGift() {
    this.setState({
      freeGiftDialog: false
    });
  }
  render() {
    const { intl } = this.props;
    let boxmobileday = intl.formatMessage({ id: 'boxmobileday' });
    let boxmobilehour = intl.formatMessage({ id: 'boxmobilehour' });
    let boxmobileminute = intl.formatMessage({ id: 'boxmobileminute' });
    let boxmobilesecond = intl.formatMessage({ id: 'boxmobilesecond' });
    let shoppingspecial = intl.formatMessage({ id: 'shoppingspecial' });
    let modelsixpack1 = intl.formatMessage({ id: 'modelsixpack1' });
    let modelsixpack1a = intl.formatMessage({ id: 'modelsixpack1a' });
    let modelsixpack1b = intl.formatMessage({ id: 'modelsixpack1b' });
    let shoppingstart = intl.formatMessage({ id: 'shoppingstart' });
    let shoppingbuild = intl.formatMessage({ id: 'shoppingbuild' });
    let shoppingregular = intl.formatMessage({ id: 'shoppingregular' });
    let shoppingadd = intl.formatMessage({ id: 'shoppingadd' });
    let shoppingtheM1 = intl.formatMessage({ id: 'shoppingtheM1' });
    let shoppingtheM2 = intl.formatMessage({ id: 'shoppingtheM2' });
    let shoppingthe = intl.formatMessage({ id: 'shoppingthe' });
    let shoppingsubscribe = intl.formatMessage({ id: 'shoppingsubscribe' });
    let shopping10 = intl.formatMessage({ id: 'shopping10' });
    let shopping10M = intl.formatMessage({ id: 'shopping10M' });
    let shopping10a = intl.formatMessage({ id: 'shopping10a' });
    let shopping10a2 = intl.formatMessage({ id: 'shopping10a2' });
    let shopping10a2b = intl.formatMessage({ id: 'shopping10a2b' });
    let shopping10a2c = intl.formatMessage({ id: 'shopping10a2c' });
    let shopping10a2d = intl.formatMessage({ id: 'shopping10a2d' });
    let shopping10b = intl.formatMessage({ id: 'shopping10b' });
    let shopping10c = intl.formatMessage({ id: 'shopping10c' });
    let shoppingmonth = intl.formatMessage({ id: 'shoppingmonth' });
    let shoppingCompleteKitRegPrice = intl.formatMessage({ id: 'shoppingCompleteKitRegPrice' });
    let shoppingCompleteKitRegPrice2 = intl.formatMessage({ id: 'shoppingCompleteKitRegPrice2' });
    let shoppingnow = intl.formatMessage({ id: 'shoppingnow' });
    let shoppingcraftsmen = intl.formatMessage({ id: 'shoppingcraftsmen' });
    let shoppingcraftsmen1 = intl.formatMessage({ id: 'shoppingcraftsmen1' });
    let shoppingcraftsmen2 = intl.formatMessage({ id: 'shoppingcraftsmen2' });
    let shoppingcraftsmen3 = intl.formatMessage({ id: 'shoppingcraftsmen3' });
    let fullkit01 = intl.formatMessage({ id: 'fullkit01' });
    let fullkit02 = intl.formatMessage({ id: 'fullkit02' });
    let fullkit03 = intl.formatMessage({ id: 'fullkit03' });
    let fullkit04 = intl.formatMessage({ id: 'fullkit04' });
    let fullkit05 = intl.formatMessage({ id: 'fullkit05' });
    let fullkit06 = intl.formatMessage({ id: 'fullkit06' });
    let fullkit07 = intl.formatMessage({ id: 'fullkit07' });
    let shoppingCompleteServiceDesc = intl.formatMessage({ id: 'shoppingCompleteServiceDesc' });
    let shoppingCompleteServiceDesc2 = intl.formatMessage({ id: 'shoppingCompleteServiceDesc2' });
    let shoppingCompleteServiceDesc3 = intl.formatMessage({ id: 'shoppingCompleteServiceDesc3' });
    let shoppingCompleteServiceDesc4 = intl.formatMessage({ id: 'shoppingCompleteServiceDesc4' });
    let shoppingPackOneKitDesc = intl.formatMessage({ id: 'shoppingPackOneKitDesc' });
    let shoppingPackOneKitDesc2 = intl.formatMessage({ id: 'shoppingPackOneKitDesc2' });
    let shoppingPackOneKitDesc3 = intl.formatMessage({ id: 'shoppingPackOneKitDesc3' });
    let shoppingall1 = intl.formatMessage({ id: 'shoppingall1' });
    let shoppingall2 = intl.formatMessage({ id: 'shoppingall2' });
    let shoppingPackOneServiceDesc = intl.formatMessage({ id: 'shoppingPackOneServiceDesc' });
    let shoppingPackOneServiceDesc2 = intl.formatMessage({ id: 'shoppingPackOneServiceDesc2' });
    let shoppingPackOneServiceDesc3 = intl.formatMessage({ id: 'shoppingPackOneServiceDesc3' });
    let shoppingthe1a = intl.formatMessage({ id: 'shoppingthe1a' });
    let shoppingthe1b = intl.formatMessage({ id: 'shoppingthe1b' });
    let shoppingfree1 = intl.formatMessage({ id: 'shoppingfree1' });
    let shoppingfree2 = intl.formatMessage({ id: 'shoppingfree2' });
    let shoppingfree3 = intl.formatMessage({ id: 'shoppingfree3' });
    let shoppingfree4 = intl.formatMessage({ id: 'shoppingfree4' });
    let shoppingfree5 = intl.formatMessage({ id: 'shoppingfree5' });
    let shoppingswapanchor = intl.formatMessage({ id: 'shoppingswapanchor' });
    let shoppingswap = intl.formatMessage({ id: 'shoppingswap' });
    let shoppingswapanchor2 = intl.formatMessage({ id: 'shoppingswapanchor2' });
    let shoppingswap2 = intl.formatMessage({ id: 'shoppingswap2' });
    let freegifttitle = intl.formatMessage({ id: 'freegifttitle' });
    let freegiftp1 = intl.formatMessage({ id: 'freegiftp1' });
    let freegiftdisclaimer1 = intl.formatMessage({ id: 'freegiftdisclaimer1' });
    let freegiftdisclaimer2 = intl.formatMessage({ id: 'freegiftdisclaimer2' });
    let freegiftdisclaimer3 = intl.formatMessage({ id: 'freegiftdisclaimer3' });
    let homeScroll = intl.formatMessage({ id: 'homeScroll' });
    let installmentavailable = intl.formatMessage({ id: 'installmentavailable' });

    let saveOverPrice = intl.formatMessage({ id: 'saveOverPrice' });
    let savePrice = intl.formatMessage({ id: 'savePrice' });
    let twCurrency = intl.formatMessage({ id: 'twCurrency' });
    let shoppingOriginalEach = intl.formatMessage({ id: 'shoppingOriginalEach' });

    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile-ma">
        {this.state.freeGiftDialog ? (
          <div className="concept-store-popup">
            <div className="concept-store-inner">
              <div className="close-btn" onClick={() => this.closeFreeGift()}>
                x
              </div>
              <h3>{freegifttitle}</h3>
              <div className="free-gift-image-wrapper">
                <img src={require('../image/home/free-gift-item.jpeg')} alt="Free Gift" />
              </div>
              <div className="free-gift-text-wrapper">
                <div className="free-gift-top-text">{freegiftp1}</div>
                <div className="free-gift-disclaimer">
                  {freegiftdisclaimer1}
                  <br />
                  {freegiftdisclaimer2}
                  <br />
                  {freegiftdisclaimer3}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="mobile-shopping" id="mobileShopping">
          <div className="mobile-shopping-item">
            <div className="mobile-shopping-item-title">
              <h1>
                {shoppingtheM1}
                <br />
                {shoppingtheM2}
              </h1>
              <h4>{shoppingsubscribe}</h4>
            </div>
            <div className="mobile-shopping-item-pic">
              <img src={require('../video/shopping-pic2.jpg')} alt="The Complete Millennium Falcon" />
              <div className="free-gift-wrapper" onClick={() => this.toggleFreeGiftDialog()}>
                {localStorage.lang === 'en' ? (
                  <img src={require('../image/home/stk-free-gift-en.png')} alt="free gift" />
                ) : (
                  <img src={require('../image/home/stk-free-gift-ch.png')} alt="free gift" />
                )}
              </div>
            </div>
            <div className="mobile-shopping-item-details">
              <div className="mobile-shopping-kit">
                <h2>{typeof shoppingbuild === 'string' ? shoppingbuild.replace('\n', '') : shoppingbuild}</h2>
                <p className="desc1a">
                  <span>
                    <span
                      style={{
                        fontSize: 23,
                        lineHeight: '25px',
                        fontWeight: 'bold',
                        color: this.state.onSale ? 'red' : '#F8E71C'
                      }}
                    >
                      {shopping10}
                      <br />
                      {shopping10a2c}
                      {offer3initprice}
                      <br />
                    </span>
                    <span style={{ wordBreak: 'break-all', fontSize: 13 }}>
                      {shopping10a}

                      {this.state.onSale ? (
                        <span style={{ textDecorationLine: 'line-through', display: 'inline-block' }}>
                          {twCurrency}
                          {offer3regrecurprice}{' '}
                        </span>
                      ) : null}
                      <span className="bold" style={{ display: 'inline-block', marginLeft: 2 }}>
                        {` ${twCurrency}${offer3recurprice}`}
                      </span>
                      <br />
                    </span>
                    {shopping10a2d}
                  </span>
                </p>
                {/*this.state.onSale ? (<p className="desc2a strike">{shoppingmonth}</p>) : <React.Fragment><br /><br /><br /></React.Fragment>*/}
                {this.state.onSale ? (
                  <span
                    className="red bold"
                    style={{ fontSize: 15, display: 'block', marginBottom: 2, color: 'red', textAlign: 'center' }}
                  >
                    {saveOverPrice}
                    {offer3savecost}*
                  </span>
                ) : null}
                <span style={{ display: 'block', marginBottom: 14, color: 'white', textAlign: 'center' }}>
                  {shopping10c}
                </span>
                <div className="installment-available">{installmentavailable}</div>

                <button onClick={() => this.addCart1('btmf_build_yourself_sub_reg')}>{shoppingnow}</button>
              </div>
              <div className="mobile-shopping-kit">
                <h2>{fullkit01}</h2>
                <p className="desc1a" style={{ whiteSpace: 'pre-line' }}>
                  {fullkit02}
                  <br />
                  {fullkit03}
                  <br />
                  {fullkit04}
                  <br />
                  {fullkit05}
                  <br />
                  {this.state.onSale ? (
                    <span className="bold" style={{ color: 'red' }}>
                      {saveOverPrice}
                      {fullkitsavecost}*
                    </span>
                  ) : null}
                  <br />
                </p>
                <div className="installment-available">{installmentavailable}</div>
                {/* {this.state.onSale ? (<p className="desc2a strike">{shoppingregular}{fullkitregprice}</p>) : <React.Fragment><br /></React.Fragment>} */}
                <button
                  onClick={() => this.addCart1('btmf_build_yourself_full_kit_reg')}
                >{`NT$ ${fullkitprice}`}</button>
              </div>
              <span
                style={{
                  color: 'rgb(153,153,153)',
                  display: this.state.onSale ? 'block' : 'none',
                  margin: 5,
                  marginBottom: 10
                }}
              >
                {shoppingOriginalEach}
                {offer3regrecurprice}
              </span>
              <div className="mobile-shopping-stk mobile-shopping-kit">
                <div className="mobile-shopping-stk-icon">
                  <img
                    src={require('../image/home/stk_logo_build_service_button@3x@3x.png')}
                    alt="Build Service Logo"
                  />
                </div>
                <p className="desc1a">
                  {shoppingcraftsmen}
                  <br />
                  {shoppingcraftsmen1}
                  <br />
                  {shoppingcraftsmen2}
                  <br />
                  {shoppingcraftsmen3}
                </p>
                {this.state.onSale ? (
                  <p className="bold" style={{ color: 'red' }}>
                    {savePrice}
                    {offer4savePrice}
                  </p>
                ) : (
                  <React.Fragment>
                    <br />
                  </React.Fragment>
                )}
                <div className="installment-available">{installmentavailable}</div>
                <button
                  onClick={() => this.addCart1('btmf_build_service_complete_deposit_reg')}
                >{`NT$ ${offer4price}`}</button>
              </div>
            </div>
          </div>
          <div className="mobileFree" style={{ float: 'initial' }}>
            <span className="strengthen">{shoppingfree1}</span>
            {shoppingfree2}
            <span className="strengthen">{shoppingfree3}</span>
            {shoppingfree4}
            {/*shoppingfree5*/}
          </div>
        </div>
      </div>
    ) : (
      <div className="component first-component">
        {this.state.freeGiftDialog ? (
          <div className="concept-store-popup">
            <div className="concept-store-inner">
              <div className="close-btn" onClick={() => this.closeFreeGift()}>
                x
              </div>
              <h3>{freegifttitle}</h3>
              <div className="free-gift-image-wrapper">
                <img src={require('../image/home/free-gift-item.jpeg')} alt="Free Gift" />
              </div>
              <div className="free-gift-text-wrapper">
                <div className="free-gift-top-text">{freegiftp1}</div>
                <div className="free-gift-disclaimer">
                  {freegiftdisclaimer1}
                  <br />
                  {freegiftdisclaimer2}
                  <br />
                  {freegiftdisclaimer3}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="shopping" id="webShopping">
          <div className="null" />
          <div className="offer-full">
            {/* Unused countdown module */}
            <div className="header-countdown">
              <p>{shoppingspecial}</p>
              <ul>
                <li>
                  <span>{this.state.int_day < 10 ? '0' + this.state.int_day : this.state.int_day}</span>
                  <i>{boxmobileday}</i>
                </li>
                <li>
                  <span>{this.state.int_hour < 10 ? '0' + this.state.int_hour : this.state.int_hour}</span>
                  <i>{boxmobilehour}</i>
                </li>
                <li>
                  <span>{this.state.int_minute < 10 ? '0' + this.state.int_minute : this.state.int_minute}</span>
                  <i>{boxmobileminute}</i>
                </li>
                <li>
                  <span>{this.state.int_second < 10 ? '0' + this.state.int_second : this.state.int_second}</span>
                  <i>{boxmobilesecond}</i>
                </li>
              </ul>
            </div>
            <div className="shopping-content-item-header">
              <div className="title">
                <h2>{shoppingthe}</h2>
                <h5>{shoppingsubscribe}</h5>
              </div>
            </div>
            {/* Complete Offer Left Image */}
            <div className="offer-col-full-left">
              <div className="">
                <div className="offer-full-image-container" style={{ float: 'initial' }}>
                  <img src={require('../video/shopping-pic2.jpg')} alt="The Complete Millennium Falcon" />
                  <div className="free-gift-wrapper" onClick={() => this.toggleFreeGiftDialog()}>
                    {localStorage.lang === 'en' ? (
                      <img src={require('../image/home/stk-free-gift-en.png')} alt="free gift" />
                    ) : (
                      <img src={require('../image/home/stk-free-gift-ch.png')} alt="free gift" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Complete Offers x3 */}
            <div className="offer-col-full-right">
              <div className="">
                {/* Subscription offer */}
                <div className="offer-box">
                  <h3>{shoppingbuild}</h3>
                  <p>
                    <span>
                      <span
                        style={{
                          fontSize: 23,
                          lineHeight: '25px',
                          fontWeight: 'bold',
                          color: this.state.onSale ? 'red' : '#F8E71C'
                        }}
                      >
                        {shopping10}
                        <br />
                        {shopping10a2c}
                        {offer3initprice}
                        <br />
                      </span>
                      <span style={{ wordBreak: 'break-all', fontSize: 13 }}>
                        {shopping10a}

                        {this.state.onSale ? (
                          <span style={{ textDecorationLine: 'line-through', display: 'inline-block' }}>
                            {twCurrency}
                            {offer3regrecurprice}{' '}
                          </span>
                        ) : null}
                        <span className="bold" style={{ display: 'inline-block' }}>
                          {twCurrency}
                          {offer3recurprice}
                        </span>
                      </span>
                      {shopping10a2d}
                    </span>
                  </p>
                  <div className="price">
                    {/*this.state.onSale ? (<span className="original">
                      {shoppingCompleteKitRegPrice}
                      <br />
                      {shoppingCompleteKitRegPrice2}
                    </span>) : null*/}
                    {this.state.onSale ? (
                      <span className="red bold" style={{ fontSize: 15, display: 'block', marginBottom: 2 }}>
                        {saveOverPrice}
                        {offer3savecost}*
                      </span>
                    ) : null}
                    <span style={{ display: 'block', marginBottom: 14 }}>{shopping10c}</span>
                    <div className="installment-available">{installmentavailable}</div>
                    <button className="discount" onClick={() => this.addCart('btmf_build_yourself_sub_reg')}>
                      {shoppingnow}
                    </button>
                  </div>
                </div>
                {/* Full Kit offer */}
                <div className="offer-box">
                  <h3>{fullkit01}</h3>
                  <p>
                    <span style={{ whiteSpace: 'pre-line' }}>
                      {fullkit02}
                      <br />
                      {fullkit03}
                      <br />
                      {fullkit04}
                      <br />
                      {fullkit05}
                    </span>
                    <br />
                  </p>
                  <div className="price">
                    {/*this.state.onSale ? (<span className="original">
                      {shoppingCompleteKitRegPrice}
                      <br />
                      {shoppingCompleteKitRegPrice2}
                    </span>) : null*/}
                    {this.state.onSale ? (
                      <span className="red bold" style={{ fontSize: 15, display: 'block', marginBottom: 30 }}>
                        {saveOverPrice}
                        {fullkitsavecost}*
                      </span>
                    ) : null}
                    <div className="installment-available">{installmentavailable}</div>
                    {/* {this.state.onSale ? (<span className="original">{shoppingregular}{fullkitregprice}</span>) : null} */}
                    <button className="discount" onClick={() => this.addCart('btmf_build_yourself_full_kit_reg')}>
                      {`NT$ ${fullkitprice}`}
                    </button>
                  </div>
                </div>
                <div className="offer-box offer-box-build-service">
                  <img
                    src={require('../image/home/stk_logo_build_service_button@3x@3x.png')}
                    alt="Build Service Logo"
                  />
                  <p>
                    {shoppingCompleteServiceDesc}
                    <br />
                    {shoppingCompleteServiceDesc2}
                    <br />
                    {shoppingCompleteServiceDesc3}
                    <br />
                    {shoppingCompleteServiceDesc4}
                  </p>
                  <div className="price">
                    {this.state.onSale ? (
                      <span className="red bold" style={{ fontSize: 15, display: 'block', marginBottom: 30 }}>
                        {savePrice}
                        {offer4savePrice}
                      </span>
                    ) : null}
                    <div className="installment-available">{installmentavailable}</div>
                    <button
                      className="discount"
                      onClick={() => this.addCart('btmf_build_service_complete_deposit_reg')}
                    >
                      {`NT$ ${offer4price}`}
                    </button>
                  </div>
                </div>
              </div>
              <span style={{ color: 'rgb(153,153,153)', display: this.state.onSale ? 'block' : 'none', margin: 5 }}>
                {shoppingOriginalEach}
                {offer3regrecurprice}
              </span>
            </div>
            {/* iPad slider module - this appears and above modules hidden when iPad resolutions */}
            <div className="shopping-slider">
              <div onClick={() => this.changeShoppingSlide1()} id="shopping-show-left" className="shopping-show-arrow">
                <img
                  src={require('../image/common/gallery-left.png')}
                  alt="Scroll Left"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
              <div onClick={() => this.changeShoppingSlide1()} id="shopping-show-right" className="shopping-show-arrow">
                <img
                  src={require('../image/common/gallery-right.png')}
                  alt="Scroll Right"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
              {/* Page 1: Complete subscription and build service offers */}
              <div id="shopping-slide3" className="shopping-content-item shopping-slide shopping-slide1">
                <div className="goods-details">
                  <div className="goods-pic goods-pic-left" style={{ float: 'initial' }}>
                    <img src={require('../video/shopping-pic2.jpg')} alt="The Complete Millennium Falcon" />
                  </div>
                  {/* iPad Page 1 Subscription */}
                  <div className="offer-box">
                    <h3>{shoppingbuild}</h3>
                    <p>
                      <span>
                        <span
                          style={{
                            fontSize: 23,
                            lineHeight: '25px',
                            fontWeight: 'bold',
                            color: this.state.onSale ? 'red' : '#F8E71C'
                          }}
                        >
                          {shopping10}
                          <br />
                          {shopping10a2c}
                          {offer3initprice}
                          <br />
                        </span>
                        <span style={{ wordBreak: 'break-all', fontSize: 13 }}>
                          {shopping10a}

                          {this.state.onSale ? (
                            <span style={{ textDecorationLine: 'line-through', display: 'inline-block' }}>
                              {twCurrency}
                              {offer3regrecurprice}{' '}
                            </span>
                          ) : null}
                          <span className="bold" style={{ display: 'inline-block' }}>
                            {twCurrency}
                            {offer3recurprice}
                          </span>
                        </span>
                        {shopping10a2d}
                      </span>
                    </p>
                    <div className="price">
                      {this.state.onSale ? (
                        <span className="red bold" style={{ fontSize: 15, display: 'block', marginBottom: 2 }}>
                          {saveOverPrice}
                          {offer3savecost}*
                        </span>
                      ) : null}
                      <span style={{ display: 'block', marginBottom: 14 }}>{shopping10c}</span>
                      <div className="installment-available">{installmentavailable}</div>
                      <button className="discount" onClick={() => this.addCart('btmf_build_yourself_sub_reg')}>
                        {shoppingnow}
                      </button>
                    </div>
                  </div>
                  {/* iPad Page 1 Complete Build Service */}
                  <div className="offer-box offer-box-build-service">
                    <img
                      src={require('../image/home/stk_logo_build_service_button@3x@3x.png')}
                      alt="Build Service Logo"
                    />
                    <p>
                      {shoppingCompleteServiceDesc}
                      <br />
                      {shoppingCompleteServiceDesc2}
                      <br />
                      {shoppingCompleteServiceDesc3}
                      <br />
                      {shoppingCompleteServiceDesc4}
                    </p>
                    <div className="price">
                      {this.state.onSale ? (
                        <span className="red bold" style={{ fontSize: 15, display: 'block', marginBottom: 30 }}>
                          {savePrice}
                          {offer4savePrice}
                        </span>
                      ) : null}
                      <div className="installment-available">{installmentavailable}</div>
                      <button
                        className="discount"
                        onClick={() => this.addCart('btmf_build_service_complete_deposit_reg')}
                      >
                        {`NT$ ${offer4price}`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Page 2: Full kit offer */}
              <div id="shopping-slide4" className="shopping-content-item shopping-slide shopping-slide2">
                <div className="goods-details">
                  <div className="goods-pic goods-pic-left" style={{ float: 'initial' }}>
                    <img src={require('../video/shopping-pic2.jpg')} alt="The Complete Millennium Falcon" />
                    <div className="free-gift-wrapper" onClick={() => this.toggleFreeGiftDialog()}>
                      {localStorage.lang === 'hk' ? (
                        <img src={require('../image/home/stk-free-gift-ch.png')} alt="free gift" />
                      ) : (
                        <img src={require('../image/home/stk-free-gift-en.png')} alt="free gift" />
                      )}
                    </div>
                  </div>
                  <div className="offer-box">
                    <h3>{fullkit01}</h3>
                    <p>
                      <span style={{ whiteSpace: 'pre-line' }}>
                        {fullkit02}
                        <br />
                        {fullkit03}
                        <br />
                        {fullkit04}
                        <br />
                        {fullkit05}
                      </span>
                      <br />
                    </p>
                    <div className="price">
                      {this.state.onSale ? (
                        <span className="red bold" style={{ fontSize: 15, display: 'block', marginBottom: 30 }}>
                          {saveOverPrice}
                          {fullkitsavecost}*
                        </span>
                      ) : null}
                      <div className="installment-available">{installmentavailable}</div>
                      <button className="discount" onClick={() => this.addCart('btmf_build_yourself_full_kit_reg')}>
                        {`NT$ ${fullkitprice}`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="shopping-slider"
                style={{
                  color: 'rgb(153,153,153)',
                  paddingTop: 8,
                  textAlign: 'center',
                  display: this.state.onSale ? 'block' : 'none'
                }}
              >
                {shoppingOriginalEach}
                {offer3regrecurprice}
              </div>
            </div>
            <div style={{ clear: 'both' }} />
            <div className="free">
              <span className="strengthen">{shoppingfree1}</span>
              {shoppingfree2}
              <span className="strengthen">{shoppingfree3}</span>
              {shoppingfree4}
              {/*shoppingfree5*/}
            </div>
          </div>
          <div className="scroll-down">
            <p>{homeScroll}</p>
            <span>
              <i />
            </span>
          </div>
        </div>
      </div>
    );
  }
}
Shopping = withRouter(injectIntl(Shopping));
export default Shopping;
