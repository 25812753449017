import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import HomePage from './HomePage/HomePage';
import Instructions from './HomePage/Instructions';
import Faq from './HomePage/Faq';
import Tc from './HomePage/Tc';
import About from './HomePage/About';
import PrivacyPolicy from './HomePage/PrivacyPolicy';
import UseOfWebsite from './HomePage/UseOfWebsite';
import ProductDetails from './HomePage/ProductDetails';
import ProductList from './HomePage/ProductList';
// import Cart1 from './pages/web/Cart1';
// import Cart2 from './pages/web/Cart2';
// import Payment from './pages/web/Payment';
// import Cart3 from './pages/web/Cart3';
// import Cart4 from './pages/web/Cart4';
// import Cart5 from './pages/web/Cart5';
// import Forgot from './pages/web/Forgot';
// import Login from './pages/web/Login';
// import User from './pages/web/User';

// import MobileCart1 from './pages/mobile/Cart1';
// import MobileCart2 from './pages/mobile/Cart2';
// import MobileCart3 from './pages/mobile/Cart3';
// import MobileCart4 from './pages/mobile/Cart4';
// import MobileForgot from './pages/mobile/Forgot';
// import MobileLogin from './pages/mobile/Login';
// import MobileUser from './pages/mobile/User';

import {   
  LoginPage,
  ForgotPage,
  UserPage,
  Cart1Page,
  Cart2Page,
  Cart3Page,
  Cart4Page,
  Cart5Page,
  PaymentPage, } from 'stktwlib'
import 'stktwlib/src/css/stktwlib.css'

import { withRouter } from 'react-router-dom';
class App extends React.Component {
  render() {
    let root = (
      <React.Fragment>
          <Switch>
            <Route exact path="/btmf" component={HomePage} />
            <Route exact path="/btmf/instructions" component={Instructions} />
            <Route exact path="/btmf/faq" component={Faq} />
            <Route exact path="/btmf/terms-and-conditions" component={Tc} />
            <Route exact path="/btmf/about" component={About} />
            <Route exact path="/btmf/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/btmf/use-of-website" component={UseOfWebsite} />
            <Route exact path="/btmf/product/:name" component={ProductDetails} />
            <Route exact path="/btmf/products/" component={ProductList} />
            <Route exact path="/btmf/cart1" component={()=><Cart1Page continuePath={'/zh-tw/r2d2/#buy'}/>} />
            <Route exact path="/btmf/cart2" component={Cart2Page} />
            <Route exact path="/btmf/payment" component={PaymentPage} />
            <Route exact path="/btmf/cart3" component={Cart3Page} />
            <Route exact path="/btmf/cart4" component={Cart4Page} />
            <Route exact path="/btmf/cart5" component={Cart5Page} />
            <Route exact path="/btmf/login" component={LoginPage} />
            <Route exact path="/btmf/forgot" component={ForgotPage} />
            <Route exact path="/btmf/user" component={UserPage} />
            <Redirect to="/btmf/" />
          </Switch>
      </React.Fragment>
    );
    return <React.Fragment>{root}</React.Fragment>;
  }
}
// const mapStatetoProps = state => {
//   return state.auth;
// };
// const actionCreators = { login, logout, initAuth };
// App = withRouter(connect(mapStatetoProps, actionCreators)(App));
export default withRouter(App);
