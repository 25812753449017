import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import '../css/mobilehome.css';

import { Carousel } from 'react-responsive-carousel';

class Lead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: document.documentElement.clientHeight,
      width: document.documentElement.clientWidth,
      widht: 'auto',
      width1: document.documentElement.clientWidth,
      showStore: false
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.resize);
  }
  resize = () => {
    this.setState({
      width1: document.documentElement.clientWidth
    });
  };
  componentDidMount() {
    this.setState({
      showStore: true
    });
  }

  storeClose() {
    this.setState({
      showStore: false
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.engaged !== this.props.engaged && this.video) {
      if (this.props.engaged) {
        this.video.play()
      } else {
        //this.video.currenTime = 0;
      }
    }
  }

  render() {
    const { intl } = this.props;
    let homeOfficial = intl.formatMessage({ id: 'homeOfficial' });
    let homebuynow = intl.formatMessage({ id: 'homebuynow' });
    let homelearn = intl.formatMessage({ id: 'homelearn' });
    let homeScroll = intl.formatMessage({ id: 'homeScroll' });
    let conceptstoretitle = intl.formatMessage({ id: 'conceptstoretitle' });
    let conceptstoredesc1 = intl.formatMessage({ id: 'conceptstoredesc1' });
    let conceptstoredesc2 = intl.formatMessage({ id: 'conceptstoredesc2' });
    let conceptstoredesc3 = intl.formatMessage({ id: 'conceptstoredesc3' });
    let conceptstoredesc4 = intl.formatMessage({ id: 'conceptstoredesc4' });
    let conceptstoredesc5 = intl.formatMessage({ id: 'conceptstoredesc5' });
    let conceptstoredesc6 = intl.formatMessage({ id: 'conceptstoredesc6' });
    let conceptstoredesc7 = intl.formatMessage({ id: 'conceptstoredesc7' });
    let conceptstoredesc8 = intl.formatMessage({ id: 'conceptstoredesc8' });
    let conceptstoredesc9 = intl.formatMessage({ id: 'conceptstoredesc9' });
    let conceptstoredesc10 = intl.formatMessage({ id: 'conceptstoredesc10' });
    let conceptstoredesc11 = intl.formatMessage({ id: 'conceptstoredesc11' });
    let { showStore } = this.state;
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? (
      <div className="mobile">
        {false ? (
        <div className="concept-store-popup">
          <div className="popup-close" onClick={() => this.storeClose()}></div>
          <Carousel showArrows={false} showStatus={false} showThumbs={false} infiniteLoop={true} autoPlay={true} stopOnHover={true} interval={3500}>
            {/*<div className="lead-popup-item">
              {localStorage.lang === 'en' && this.state.width1 > 725 ? (
                <img src={require('../image/home/tw-40ann-desk-en-end.png')} alt="40th anniversary sale" />
              ) : localStorage.lang === 'en' && this.state.width1 <= 725 ? (
                <img src={require('../image/home/tw-40ann-mobile-en-end.png')} alt="40th anniversary sale" />
              ) : localStorage.lang !== 'en' && this.state.width1 > 725 ? (
                <img src={require('../image/home/tw-40ann-desk-tc-end.jpg')} alt="40th anniversary sale" />
              ) : (
                <img src={require('../image/home/tw-40ann-mobile-tc-end.jpg')} alt="40th anniversary sale" />
              )}
            </div>*/}
            <div className="lead-popup-item">
              <Link to={`/btmf/products`}>
              {localStorage.lang === 'en' && this.state.width1 > 725 ? (
                <img src={require('../image/home/tw-plaque-desk-en.jpg')} alt="new plaques available" />
              ) : localStorage.lang === 'en' && this.state.width1 <= 725 ? (
                <img src={require('../image/home/tw-plaque-mobile-en.jpg')} alt="new plaques available" />
              ) : localStorage.lang !== 'en' && this.state.width1 > 725 ? (
                <img src={require('../image/home/tw-plaque-desk-tc.jpg')} alt="new plaques available" />
              ) : (
                <img src={require('../image/home/tw-plaque-mobile-tc.jpg')} alt="new plaques available" />
              )}
              </Link>
            </div>
            {/*<div className="lead-popup-item">
              {localStorage.lang === 'en' && this.state.width1 > 725 ? (
                <img src={require('../image/home/tw-store-desk-en.png')} alt="concept store" />
              ) : localStorage.lang === 'en' && this.state.width1 <= 725 ? (
                <img src={require('../image/home/tw-store-mobile-en.png')} alt="concept store" />
              ) : localStorage.lang !== 'en' && this.state.width1 > 725 ? (
                <img src={require('../image/home/tw-store-desk-tc.png')} alt="concept store" />
              ) : (
                <img src={require('../image/home/tw-store-mobile-tc.png')} alt="concept store" />
              )}
            </div>*/}
          </Carousel>
          {/*<div className="concept-store-inner">
            <div className="close-btn" onClick={() => this.storeClose()}>x</div>
            <h3>{conceptstoretitle}</h3>
            <img className="storepic1" src={require('../image/home/store01.jpg')} alt="STK Workshop Concept Store" />
            <img className="storepic1" src={require('../image/home/store03.jpg')} alt="STK Workshop Concept Store" />
            <p className="store-desc">{conceptstoredesc1}<span className="bold">{conceptstoredesc2}</span>{conceptstoredesc3}<br /><a href="http://bit.ly/2PDK7z6" target="_blank" rel="noopener noreferrer">{conceptstoredesc4}</a><span className="italic">{conceptstoredesc5}</span><br />{conceptstoredesc6}</p>
            <p>{conceptstoredesc7}</p>
            <p>{conceptstoredesc8}</p>
            <p>{conceptstoredesc9}</p>
            <p>{conceptstoredesc10}</p>
            <p>{conceptstoredesc11}</p>
            <div className="disney-logo"><img src={require('../image/home/disney-lucasfilm-logo-blk.jpg')} alt="Copyright and Trademark 2020 Lucasfilm Limited" /></div>
          </div>*/}
        </div>) : null }
        <div className="home" id="mobileHome" style={{ paddingBottom: 37 }}>
          <div className="home-content">
            <div className="home-content-icon">
              <img src={require('../image/home/BTMF_logo_white@3x.png')} alt="Build the Millennium Falcon Logo" />
              <img src={require('../image/home/deagostini@3x.png')} alt="Deagostini Logo" />
            </div>
            <p>{homeOfficial}</p>
            <div className="home-content-button">
              <a onClick={() => this.props.goToSection(17)}>{homebuynow}</a>
              <a className="learn-more-btn" onClick={() => this.props.goToSection(2)}>
                {homelearn}
              </a>
              <img src={require('../image/home/Disney_lucasfilm_logo_white@3x.png')} alt="Disney and Lucasfilm Logo" />
              <div className="home-lucasfilm-mobile">&copy; &amp; TM 2020 LUCASFILM LTD</div>
            </div>
          </div>
        </div>
        <div className="mobile-home-video">
          <video
            playsInline="true"
            muted="true"
            autoPlay="true"
            loop="true"
            id="mobileHomeVideo"
            style={{ width: 'auto', height: '100%' }}
            src={require('../video/homem.mp4')}
            type="video/mp4"
          />
        </div>
        <div className="home-bottom">
          <h3>{homeScroll}</h3>
          <div className="home-bottom-arrow">
            <span />
            <i />
          </div>
        </div>
      </div>
    ) : (
      <div className="component first-component">
        {false ? (
        <div className="concept-store-popup">
          <div className="popup-close" onClick={() => this.storeClose()}></div>
          <Carousel showArrows={false} showStatus={false} showThumbs={false} infiniteLoop={true} autoPlay={true} stopOnHover={true} interval={3500}>
            {/*<div className="lead-popup-item">
              {localStorage.lang === 'en' && this.state.width1 > 725 ? (
                <img src={require('../image/home/tw-40ann-desk-en-end.png')} alt="40th anniversary sale" />
              ) : localStorage.lang === 'en' && this.state.width1 <= 725 ? (
                <img src={require('../image/home/tw-40ann-mobile-en-end.png')} alt="40th anniversary sale" />
              ) : localStorage.lang !== 'en' && this.state.width1 > 725 ? (
                <img src={require('../image/home/tw-40ann-desk-tc-end.jpg')} alt="40th anniversary sale" />
              ) : (
                <img src={require('../image/home/tw-40ann-mobile-tc-end.jpg')} alt="40th anniversary sale" />
              )}
            </div>*/}
            <div className="lead-popup-item">
              <Link to={`/btmf/products`}>
              {localStorage.lang === 'en' && this.state.width1 > 725 ? (
                <img src={require('../image/home/tw-plaque-desk-en.jpg')} alt="new plaques available" />
              ) : localStorage.lang === 'en' && this.state.width1 <= 725 ? (
                <img src={require('../image/home/tw-plaque-mobile-en.jpg')} alt="new plaques available" />
              ) : localStorage.lang !== 'en' && this.state.width1 > 725 ? (
                <img src={require('../image/home/tw-plaque-desk-tc.jpg')} alt="new plaques available" />
              ) : (
                <img src={require('../image/home/tw-plaque-mobile-tc.jpg')} alt="new plaques available" />
              )}
              </Link>
            </div>
            {/*<div className="lead-popup-item">
              {localStorage.lang === 'en' && this.state.width1 > 725 ? (
                <img src={require('../image/home/tw-store-desk-en.png')} alt="concept store" />
              ) : localStorage.lang === 'en' && this.state.width1 <= 725 ? (
                <img src={require('../image/home/tw-store-mobile-en.png')} alt="concept store" />
              ) : localStorage.lang !== 'en' && this.state.width1 > 725 ? (
                <img src={require('../image/home/tw-store-desk-tc.png')} alt="concept store" />
              ) : (
                <img src={require('../image/home/tw-store-mobile-tc.png')} alt="concept store" />
              )}
            </div>*/}
          </Carousel>
          {/*<div className="concept-store-inner">
            <div className="close-btn" onClick={() => this.storeClose()}>x</div>
            <h3>{conceptstoretitle}</h3>
            <img className="storepic1" src={require('../image/home/store01.jpg')} alt="STK Workshop Concept Store" />
            <img className="storepic1" src={require('../image/home/store03.jpg')} alt="STK Workshop Concept Store" />
            <p className="store-desc">{conceptstoredesc1}<span className="bold">{conceptstoredesc2}</span>{conceptstoredesc3}<br /><a href="http://bit.ly/2PDK7z6" target="_blank" rel="noopener noreferrer">{conceptstoredesc4}</a><span className="italic">{conceptstoredesc5}</span><br />{conceptstoredesc6}</p>
            <p>{conceptstoredesc7}</p>
            <p>{conceptstoredesc8}</p>
            <p>{conceptstoredesc9}</p>
            <p>{conceptstoredesc10}</p>
            <p>{conceptstoredesc11}</p>
            <div className="disney-logo"><img src={require('../image/home/disney-lucasfilm-logo-blk.jpg')} alt="Copyright and Trademark 2020 Lucasfilm Limited" /></div>
          </div>*/}
        </div>) : null }
        <div className="home-video webHome" id="home-video">
          <video
            ref={ref => this.video = ref}
            muted="true"
            autoPlay="true"
            loop="true"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            src={require('../video/home.mp4')}
            type="video/mp4"
          />
        </div>
        <div
          className="content"
          style={{
            width: this.state.width1 > 1439 ? this.state.width1 - 203 : this.state.width1 > 1240 ? 1236 : 100 + '%'
          }}
        >
          <div className="content-inner">
            <div className="home-falocn">
              <img
                className="btmf-logo-icon"
                src={require('../image/home/BTMF_logo_white@3x.png')}
                alt="Build the Millennium Falcon Logo"
              />
              <img
                className="deagostini-logo-icon"
                src={require('../image/home/deagostini@3x.png')}
                alt="Deagostini Logo"
              />
            </div>
            <div className="home-des">{homeOfficial}</div>
            <div className="home-button">
              <button id="now" onClick={() => this.props.goToSection(15)}>
                {homebuynow}
              </button>
              <button id="more" onClick={() => this.props.goToSection(2)}>
                {homelearn}
              </button>
            </div>
            <div className="home-disney">
              <img
                className="disney-lucas-logo-icon"
                src={require('../image/home/Disney_lucasfilm_logo_white@3x.png')}
                alt="Disney and Lucasfilm Logo"
              />
              <div className="home-lucasfilm">&copy; &amp; TM 2020 LUCASFILM LTD</div>
            </div>
          </div>
          <div className="scroll-down">
            <p>{homeScroll}</p>
            <span>
              <i />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Lead);
